import React, { useState, useEffect } from 'react';
import { 
  Typography, 
  Button, 
  CircularProgress, 
  Alert,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Paper,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  Grid
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import NISTFrameworkMapping from './nist_framework_mapping_old';
import { handleClaudeSubmit } from './modules/nist_claude_sonnet_3.5_module';
import { handleOpenAISubmit, validateFiles } from './modules/nist_openai_4o_module';
import { styled } from '@mui/material/styles';

const aiLabels = {
  'openai': 'OpenAI',
  'claude': 'Claude'
};

const CustomFileInput = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
}));

const HiddenInput = styled('input')({
  display: 'none',
});

const FileInputButton = styled(Button)(({ theme }) => ({
  width: '100%',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  border: `1px solid ${theme.palette.primary.main}`,
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const FileNameDisplay = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1),
  color: theme.palette.text.secondary,
}));

const StatusChip = styled(Chip)(({ theme, status }) => {
  const colors = {
    success: {
      backgroundColor: theme.palette.success.light,
      color: theme.palette.success.contrastText,
    },
    error: {
      backgroundColor: theme.palette.error.light,
      color: theme.palette.error.contrastText,
    },
    pending: {
      backgroundColor: theme.palette.grey[300],
      color: theme.palette.grey[800],
    },
    inProgress: {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.contrastText,
    },
  };
  
  return {
    ...colors[status],
    margin: theme.spacing(0, 1),
  };
});

const ResultBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.grey[50],
  marginTop: theme.spacing(1),
}));

const NIST_PBCList = () => {
  const [subcategories, setSubcategories] = useState([]);
  const [subcategoryStates, setSubcategoryStates] = useState({});
  const [error, setError] = useState(null);
  const [selectedAI, setSelectedAI] = useState('openai');

  useEffect(() => {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get('jwt');
      if (!token) {
        throw new Error('No JWT provided');
      }

      const payload = JSON.parse(atob(token.split('.')[1]));
      const subcategoryList = payload.controls || [];

      const initialStates = {};
      subcategoryList.forEach(subcategoryId => {
        initialStates[subcategoryId] = {
          files: [],
          isLoading: false,
          output: null,
          error: null,
          responseData: null
        };
      });

      setSubcategories(subcategoryList);
      setSubcategoryStates(initialStates);
    } catch (err) {
      setError('Failed to parse JWT or subcategory list');
    }
  }, []);

  const handleFileChange = (subcategoryId, event) => {
    const newFiles = Array.from(event.target.files);
    
    if (selectedAI === 'openai') {
      const { isValid, errors } = validateFiles(newFiles);
      if (!isValid) {
        setSubcategoryStates(prev => ({
          ...prev,
          [subcategoryId]: {
            ...prev[subcategoryId],
            error: errors.join('\n')
          }
        }));
        return;
      }
    }
    
    setSubcategoryStates(prev => ({
      ...prev,
      [subcategoryId]: {
        ...prev[subcategoryId],
        files: newFiles,
        error: null
      }
    }));
  };

  const handleAIChange = (event) => {
    const newAI = event.target.value;
    setSelectedAI(newAI);
    
    if (newAI === 'openai') {
      Object.entries(subcategoryStates).forEach(([subcategoryId, state]) => {
        if (state.files.length > 0) {
          const { isValid, errors } = validateFiles(state.files);
          if (!isValid) {
            setSubcategoryStates(prev => ({
              ...prev,
              [subcategoryId]: {
                ...prev[subcategoryId],
                files: [],
                error: errors.join('\n')
              }
            }));
          }
        }
      });
    }
  };

  const handleSubmit = async (subcategoryId) => {
    setSubcategoryStates(prev => ({
      ...prev,
      [subcategoryId]: {
        ...prev[subcategoryId],
        isLoading: true,
        error: null,
        output: null,
        responseData: null
      }
    }));

    try {
      const category = Object.keys(NISTFrameworkMapping).find(cat => 
        Object.keys(NISTFrameworkMapping[cat].subcategories).includes(subcategoryId)
      );

      const frameworkData = {
        category,
        subcategory: subcategoryId,
        details: NISTFrameworkMapping[category].subcategories[subcategoryId]
      };

      if (selectedAI === 'claude') {
        const { response, parsedData } = await handleClaudeSubmit(
          subcategoryStates[subcategoryId].files,
          subcategoryId,
          frameworkData
        );
        
        setSubcategoryStates(prev => ({
          ...prev,
          [subcategoryId]: {
            ...prev[subcategoryId],
            isLoading: false,
            output: response,
            responseData: parsedData
          }
        }));
      } else {
        const response = await handleOpenAISubmit(
          subcategoryStates[subcategoryId].files,
          subcategoryId,
          frameworkData,
          (data) => {
            setSubcategoryStates(prev => ({
              ...prev,
              [subcategoryId]: {
                ...prev[subcategoryId],
                responseData: data
              }
            }));
          }
        );
        
        setSubcategoryStates(prev => ({
          ...prev,
          [subcategoryId]: {
            ...prev[subcategoryId],
            isLoading: false,
            output: response
          }
        }));
      }
    } catch (err) {
      setSubcategoryStates(prev => ({
        ...prev,
        [subcategoryId]: {
          ...prev[subcategoryId],
          isLoading: false,
          error: err.message
        }
      }));
    }
  };

  const renderFileNames = (files) => {
    if (files.length === 0) return 'No file chosen';
    return files.map(file => file.name).join(', ');
  };

  if (error) {
    return (
      <Alert severity="error" sx={{ mt: 2 }}>
        {error}
      </Alert>
    );
  }

  const getSubcategoryStatus = (state) => {
    if (!state || !state.files.length) return 'pending';
    if (state.isLoading) return 'inProgress';
    if (state.error) return 'error';
    if (state.output) return 'success';
    return 'pending';
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case 'success':
        return <CheckCircleIcon fontSize="small" />;
      case 'error':
        return <ErrorIcon fontSize="small" />;
      case 'inProgress':
        return <CircularProgress size={16} />;
      default:
        return <HourglassEmptyIcon fontSize="small" />;
    }
  };

  const getStatusLabel = (status) => {
    switch (status) {
      case 'success':
        return 'Analysis Complete';
      case 'error':
        return 'Analysis Failed';
      case 'inProgress':
        return 'Analyzing...';
      default:
        return 'Pending Analysis';
    }
  };

  const findSubcategoryInfo = (subcategoryId) => {
    for (const category of Object.keys(NISTFrameworkMapping)) {
      if (NISTFrameworkMapping[category].subcategories[subcategoryId]) {
        return {
          title: NISTFrameworkMapping[category].subcategories[subcategoryId].title,
          examples: NISTFrameworkMapping[category].subcategories[subcategoryId].examples
        };
      }
    }
    return { title: '', examples: [] };
  };

  const renderStatusSummary = (state) => {
    if (!state?.responseData) return null;
    return (
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={6} md={3}>
          <ResultBox>
            <Typography variant="caption" color="textSecondary">Overall Assessment</Typography>
            <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
              {state.responseData.overallAssessment || 'N/A'}
            </Typography>
          </ResultBox>
        </Grid>
        <Grid item xs={6} md={3}>
          <ResultBox>
            <Typography variant="caption" color="textSecondary">Organization Identification</Typography>
            <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
              {state.responseData.organizationIdentification || 'N/A'}
            </Typography>
          </ResultBox>
        </Grid>
        <Grid item xs={6} md={3}>
          <ResultBox>
            <Typography variant="caption" color="textSecondary">System Process Identification</Typography>
            <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
              {state.responseData.systemProcessIdentification || 'N/A'}
            </Typography>
          </ResultBox>
        </Grid>
        <Grid item xs={6} md={3}>
          <ResultBox>
            <Typography variant="caption" color="textSecondary">Timestamp</Typography>
            <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
              {state.responseData.timestamp || 'N/A'}
            </Typography>
          </ResultBox>
        </Grid>
      </Grid>
    );
  };

  return (
    <Box sx={{ maxWidth: '1200px', margin: '0 auto', p: 3 }}>
      <Paper sx={{ p: 3 }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
          <img 
            src="logo.png" 
            alt="Logo"
            style={{ maxWidth: '370px', height: 'auto' }}
          />
        </Box>
        <Typography variant="h4" component="h1" gutterBottom color="primary" fontWeight="bold" textAlign="center">
          NIST CSF PBC List Review
        </Typography>
        
        <FormControl fullWidth sx={{ mb: 3 }}>
          <InputLabel id="ai-select-label">Select AI Assistant</InputLabel>
          <Select
            labelId="ai-select-label"
            id="ai-select"
            value={selectedAI}
            label="Select AI Assistant"
            onChange={handleAIChange}
          >
            <MenuItem value="claude">{aiLabels['claude']}</MenuItem>
            <MenuItem value="openai">{aiLabels['openai']}</MenuItem>
          </Select>
        </FormControl>

        {subcategories.map((subcategoryId) => {
          const { title, examples } = findSubcategoryInfo(subcategoryId);
          const state = subcategoryStates[subcategoryId] || {};
          const status = getSubcategoryStatus(state);
          
          return (
            <Accordion key={subcategoryId} sx={{ mt: 2 }}>
              <AccordionSummary 
                expandIcon={<ExpandMoreIcon />}
                sx={{
                  '& .MuiAccordionSummary-content': {
                    flexDirection: 'column'
                  }
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                  <Typography variant="h6">
                    {subcategoryId}: {title}
                  </Typography>
                  <StatusChip
                    status={status}
                    icon={getStatusIcon(status)}
                    label={getStatusLabel(status)}
                    size="small"
                  />
                </Box>
                {renderStatusSummary(state)}
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                  <Paper sx={{ p: 2, bgcolor: 'grey.50' }}>
                    <Typography variant="subtitle1" fontWeight="bold">Implementation Examples</Typography>
                    {examples.map((example, index) => (
                      <Typography key={index} sx={{ mt: 1 }}>• {example}</Typography>
                    ))}
                  </Paper>

                  <Box sx={{ mt: 2 }}>
                    <CustomFileInput>
                      <HiddenInput
                        type="file"
                        onChange={(e) => handleFileChange(subcategoryId, e)}
                        multiple
                        id={`file-input-${subcategoryId}`}
                      />
                      <FileInputButton
                        variant="outlined"
                        component="span"
                        onClick={() => document.getElementById(`file-input-${subcategoryId}`).click()}
                      >
                        Upload supporting evidence
                      </FileInputButton>
                      <FileNameDisplay variant="body2">
                        {renderFileNames(state.files || [])}
                      </FileNameDisplay>
                    </CustomFileInput>

                    {state.error && (
                      <Alert severity="error" sx={{ mt: 2 }}>
                        {state.error}
                      </Alert>
                    )}

                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      size="large"
                      sx={{ mt: 2, fontWeight: 'bold' }}
                      onClick={() => handleSubmit(subcategoryId)}
                      disabled={!state.files?.length || state.isLoading}
                    >
                      {state.isLoading ? (
                        <CircularProgress size={24} color="inherit" />
                      ) : ('Analyze evidence against NIST CSF'
                      )}
                    </Button>

                    {state.output && (
                      <Paper sx={{ mt: 2, p: 2, bgcolor: 'grey.50' }}>
                        <Typography variant="subtitle1" fontWeight="bold">
                          Analysis Result
                        </Typography>
                        <Typography sx={{ whiteSpace: 'pre-wrap' }}>
                          {state.output}
                        </Typography>
                      </Paper>
                    )}
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </Paper>
    </Box>
  );
};

export default NIST_PBCList;