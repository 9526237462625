import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemText,
  Box,
  LinearProgress
} from '@mui/material';
import { styled } from '@mui/material/styles';
import WarningIcon from '@mui/icons-material/Warning';

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.background.paper,
  '&:hover': {
    boxShadow: theme.shadows[6],
  },
}));

const RiskMetricsCard = ({ frameworks }) => {
  const calculateRiskMetrics = () => {
    let accept = 0;
    let warning = 0;
    let reject = 0;
    let totalAssessed = 0;
    
    frameworks.forEach(framework => {
      framework.results?.forEach(result => {
        if (result.ai_result?.overallAssessment) {
          totalAssessed++;
          switch (result.ai_result.overallAssessment) {
            case 'ACCEPT':
              accept++;
              break;
            case 'WARNING':
              warning++;
              break;
            case 'REJECT':
              reject++;
              break;
          }
        }
      });
    });
    
    return { accept, warning, reject, totalAssessed };
  };

  const metrics = calculateRiskMetrics();
  const total = metrics.totalAssessed;

  return (
    <StyledCard>
      <CardContent>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <WarningIcon sx={{ fontSize: 40, mr: 2, color: 'warning.main' }} />
          <Typography variant="h6">Assessment Results</Typography>
        </Box>
        {total > 0 ? (
          <List>
            {metrics.accept > 0 && (
              <ListItem>
                <ListItemText 
                  primary="Accepted Results" 
                  secondary={`${metrics.accept} results`}
                />
                <LinearProgress 
                  variant="determinate" 
                  value={(metrics.accept / total) * 100}
                  sx={{ width: '100px', ml: 2 }}
                  color="success"
                />
              </ListItem>
            )}
            {metrics.warning > 0 && (
              <ListItem>
                <ListItemText 
                  primary="Results with Warnings" 
                  secondary={`${metrics.warning} results`}
                />
                <LinearProgress 
                  variant="determinate" 
                  value={(metrics.warning / total) * 100}
                  sx={{ width: '100px', ml: 2 }}
                  color="warning"
                />
              </ListItem>
            )}
            {metrics.reject > 0 && (
              <ListItem>
                <ListItemText 
                  primary="Rejected Results" 
                  secondary={`${metrics.reject} results`}
                />
                <LinearProgress 
                  variant="determinate" 
                  value={(metrics.reject / total) * 100}
                  sx={{ width: '100px', ml: 2 }}
                  color="error"
                />
              </ListItem>
            )}
          </List>
        ) : (
          <Typography color="textSecondary">No assessments completed yet</Typography>
        )}
      </CardContent>
    </StyledCard>
  );
};

export default RiskMetricsCard;