const NISTFrameworkMapping = {
  "ID.AM-1": {
      "title": "The data, personnel, devices, systems, and facilities that enable the organization to achieve business purposes are identified and managed consistent with their relative importance to business objectives and the organization’s risk strategy.",
      "description": "Physical devices and systems within the organization are inventoried.",
      "testSteps": "1. Obtain a copy of physical devices and systems inventory. Review the inventory considering the following:\na. Scope of physical devices and systems is based on the organization's risk appetite (e.g., systems that contain sensitive information, allow access to the network, or are critical to business objectives)\nb. Completeness of inventory (e.g., location, asset number, owner)\nc. Inventory collection process ensures new devices are collected accurately and in a timely manner (e.g., automated software to detect and/or store the inventory)\nd. Frequency of inventory reviews",
      "frequency": "Yearly"
  },
  "ID.AM-2": {
      "title": "The data, personnel, devices, systems, and facilities that enable the organization to achieve business purposes are identified and managed consistent with their relative importance to business objectives and the organization’s risk strategy.",
      "description": "Software platforms and applications within the organization are inventoried.",
      "testSteps": "1. Obtain a copy of software inventory. Review the inventory considering the following:\na. Scope of software inventory is based on the organization's risk appetite (e.g., software that processes, stores or accesses sensitive information or is critical to business objectives)\nb. Completeness of inventory (e.g., version, system, vendor, owner)\nc. Inventory collection process ensures new software is collected accurately and in a timely manner (e.g., automated software to detect and/or store the inventory)\nd. Frequency of inventory reviews",
      "frequency": "Yearly"
  },
  "ID.AM-3": {
      "title": "The data, personnel, devices, systems, and facilities that enable the organization to achieve business purposes are identified and managed consistent with their relative importance to business objectives and the organization’s risk strategy.",
      "description": "Organizational communication and data flows are mapped.",
      "testSteps": "1. Ensure the organization maintains accurate and current copies of data flow diagram(s) (DFD), logical network diagram(s) (LND), and/or other diagrams to show organizational communication and data flow. ",
      "frequency": "Yearly"
  },
  "ID.AM-4": {
      "title": "The data, personnel, devices, systems, and facilities that enable the organization to achieve business purposes are identified and managed consistent with their relative importance to business objectives and the organization’s risk strategy.",
      "description": "External information systems are cataloged.",
      "testSteps": "1. If the organization relies on information systems hosted by third parties, obtain a copy of the external systems inventory. Review the third-party inventory considering the following:\na. Scope of external systems is based on the organization's risk appetite (e.g., systems that store, process or access sensitive information or are critical to business objectives).\nb. Completeness of inventory (e.g., location, third party, owner, etc.)\nc. Inventory collection process ensures new systems are collected accurately and in a timely manner (e.g,. automated software to detect and/or store the inventory)\nd. Frequency of inventory reviews\n",
      "frequency": "Yearly"
  },
  "ID.AM-5": {
      "title": "The data, personnel, devices, systems, and facilities that enable the organization to achieve business purposes are identified and managed consistent with their relative importance to business objectives and the organization’s risk strategy.",
      "description": "Resources (e.g., hardware, devices, data and software) are prioritized based on their classification, criticality and business value.",
      "testSteps": "1. Obtain a copy of the organization's data classification program (classification may also be identified in the risk assessment or business impact analysis).\n2. Review the program to determine if key resources (e.g., hardware, devices, data, software) are classified and prioritized based on criticality and business value.",
      "frequency": "Yearly"
  },
  "ID.AM-6": {
      "title": "The data, personnel, devices, systems, and facilities that enable the organization to achieve business purposes are identified and managed consistent with their relative importance to business objectives and the organization’s risk strategy.",
      "description": "Cybersecurity roles and responsibilities for the entire workforce and third-party stakeholders (e.g., suppliers, customers, partners) are established.",
      "testSteps": "1. Review cybersecurity policies, information security policies, job descriptions, agreements, RACI charts, service level agreements (SLAs) and/or contracts to determine if they include cybersecurity roles and responsibilities.\n",
      "frequency": "Yearly"
  },
  "ID.BE-1": {
      "title": "The organization’s mission, objectives, stakeholders, and activities are understood and prioritized; this information is used to inform cybersecurity roles, responsibilities, and risk management decisions.",
      "description": "The organization’s role in the supply chain is identified and communicated.",
      "testSteps": "1. Obtain documentation or evidence (e.g., cybersecurity strategy, business continuity plan, information system acquisition procedures, business impact analysis, acquisition/procurement process, key supplier reviews, supplier relationship management, supplier due diligence reports) to determine whether the organization has clearly defined and understands its role in the supply chain. ",
      "frequency": "Yearly"
  },
  "ID.BE-2": {
      "title": "The organization’s mission, objectives, stakeholders, and activities are understood and prioritized; this information is used to inform cybersecurity roles, responsibilities, and risk management decisions.",
      "description": "The organization’s place in critical infrastructure and its industry sector is identified and communicated.",
      "testSteps": "1. Obtain documentation or evidence (e.g., mission statement, business continuity policy, strategic plan) that the organization has clearly defined and understands its role in its industry sector and its role within national critical infrastructure, as defined by the Department of Homeland Security (https://www.dhs.gov/what-critical-infrastructure). ",
      "frequency": "Yearly"
  },
  "ID.BE-3": {
      "title": "The organization’s mission, objectives, stakeholders, and activities are understood and prioritized; this information is used to inform cybersecurity roles, responsibilities, and risk management decisions.",
      "description": "Priorities for organizational mission, objectives and activities are established and communicated.",
      "testSteps": "1. Determine if the organization has a strategic plan defining enterprise goals. Ensure enterprise goals are aligned with stakeholder interests.\n2. Determine if the organization's mission statement and objectives are clearly published in a way employees can easily see or access them.\n3. Determine if an IT strategic plan is documented, defines goals and is mapped to enterprise goals.\n4. Determine if employees are educated on the organization's mission and objectives.",
      "frequency": "Yearly"
  },
  "ID.BE-4": {
      "title": "The organization’s mission, objectives, stakeholders, and activities are understood and prioritized; this information is used to inform cybersecurity roles, responsibilities, and risk management decisions.",
      "description": "Dependencies and critical functions for delivery of critical services are established.",
      "testSteps": "1. Obtain the organization's business continuity plan, disaster recovery plan, business impact analysis and risk assessments and review for the following:\na. Information systems and software supporting critical business functions are identified and prioritized based on maximum allowable downtime.\nb. Third parties who support critical business functions and information systems/software are identified and prioritized.",
      "frequency": "Yearly"
  },
  "ID.BE-5": {
      "title": "The organization’s mission, objectives, stakeholders, and activities are understood and prioritized; this information is used to inform cybersecurity roles, responsibilities, and risk management decisions.",
      "description": "Resilience requirements to support delivery of critical services are established.",
      "testSteps": "1. Determine if the organization's business continuity and disaster recovery plans (including business impact analysis) support resilience of critical services.\n2. Determine if appropriate due diligence (e.g., business continuity plans (BCP), service level agreements (SLA), Service Organization Control (SOC) reports) information is in place and reviewed to ensure resilience requirements of the organization can be met by critical third-party services.",
      "frequency": "Yearly"
  },
  "ID.GV-1": {
      "title": "The policies, procedures, and processes to manage and monitor the organization’s regulatory, legal, risk, environmental, and operational requirements are understood and inform the management of cybersecurity risk.",
      "description": "Organizational information security policy is established.",
      "testSteps": "1. Obtain a copy of the information security policy.\n2. Determine if the policy is complete and has been approved by a governance structure within the organization.\n3. Determine if the policy is communicated to employees.",
      "frequency": "Yearly"
  },
  "ID.GV-2": {
      "title": "The policies, procedures, and processes to manage and monitor the organization’s regulatory, legal, risk, environmental, and operational requirements are understood and inform the management of cybersecurity risk.",
      "description": "Information security roles and responsibilities are coordinated and aligned with internal roles and external partners.",
      "testSteps": "1. Determine if information security roles and responsibilities are defined. Roles and responsibilities may be defined in policies, job descriptions, agreements, RACI charts, hierarchy charts and/or contracts.\n2. Determine if there is sufficient independence within the information security roles in order to provide adequate separation of duties for critical functions.\n3. Review contracts, nondisclosure agreements (NDAs) and service level agreements (SLAs) with critical vendors to determine if cybersecurity controls and incident notification are addressed appropriately.",
      "frequency": "Yearly"
  },
  "ID.GV-3": {
      "title": "The policies, procedures, and processes to manage and monitor the organization’s regulatory, legal, risk, environmental, and operational requirements are understood and inform the management of cybersecurity risk.",
      "description": "Legal and regulatory requirements regarding cybersecurity, including privacy and civil liberties obligations, are understood and managed.",
      "testSteps": "1. Obtain a list of all relevant legal and regulatory requirements for the organization.\n2. Determine if the cybersecurity program is mapped to legal and regulatory requirements.\n3. Review any recent regulatory cybersecurity exams or audits. If any exceptions were noted in audits, determine how the organization responded to exceptions. \n4. Determine if critical third-party contracts are reviewed by legal counsel prior to execution.\n5. Determine if there is a formalized process in place to monitor and review changes in cybersecurity laws and regulations.",
      "frequency": "Yearly"
  },
  "ID.GV-4": {
      "title": "The policies, procedures, and processes to manage and monitor the organization’s regulatory, legal, risk, environmental, and operational requirements are understood and inform the management of cybersecurity risk.",
      "description": "Governance and risk management processes address cybersecurity risk.",
      "testSteps": "1. Determine the adequacy of executive or board oversight and understanding of cybersecurity. Consider the following:\na. Risk Management\nb. Governance Structures\nc. Security Oversight\nd. Training\ne. Accountability\nf. Reporting",
      "frequency": "Yearly"
  },
  "ID.RA-1": {
      "title": "The organization understands the cybersecurity risk to organizational operations (including mission, functions, image, or reputation), organizational assets, and individuals.",
      "description": "Asset vulnerabilities are identified and documented.",
      "testSteps": "1. Determine if vulnerability testing is conducted and analyzed on critical organizational assets (e.g., assets important to business objectives and the organization's risk strategy).",
      "frequency": "Yearly"
  },
  "ID.RA-2": {
      "title": "The organization understands the cybersecurity risk to organizational operations (including mission, functions, image, or reputation), organizational assets, and individuals.",
      "description": "Threat and vulnerability information is received from information sharing forums and sources.",
      "testSteps": "1. Determine if the organization is a member of or subscribes to a threat and vulnerability information sharing organization (e.g,. United States Computer Emergency Readiness Team [US-CERT]).\n2. Determine if the organization has a formal process in place for disseminating threat and vulnerability information to individuals with the expertise to review the information and the authority to mitigate risk posed to the organization.",
      "frequency": "Yearly"
  },
  "ID.RA-3": {
      "title": "The organization understands the cybersecurity risk to organizational operations (including mission, functions, image, or reputation), organizational assets, and individuals.",
      "description": "Threats, both internal and external, are identified and documented.",
      "testSteps": "1. Review risk assessments to determine if internal and external threats are identified and documented.\n2. Determine if the organization has developed processes to actively monitor and report potential threats.",
      "frequency": "Yearly"
  },
  "ID.RA-4": {
      "title": "The organization understands the cybersecurity risk to organizational operations (including mission, functions, image, or reputation), organizational assets, and individuals.",
      "description": "Potential business impacts and likelihoods are identified.",
      "testSteps": "1. Review risk assessments and business impact analysis to determine if likelihood and potential impacts are identified and analyzed for threats.",
      "frequency": "Yearly"
  },
  "ID.RA-5": {
      "title": "The organization understands the cybersecurity risk to organizational operations (including mission, functions, image, or reputation), organizational assets, and individuals.",
      "description": "Threats, vulnerabilities, likelihoods and impacts are used to determine risk.",
      "testSteps": "1. Determine if the risk assessment process identifies reasonably foreseeable internal and external threats and vulnerabilities, the likelihood and potential damage of those threats, and the sufficiency of controls to mitigate the risk associated with those threats.",
      "frequency": "Yearly"
  },
  "ID.RA-6": {
      "title": "The organization understands the cybersecurity risk to organizational operations (including mission, functions, image, or reputation), organizational assets, and individuals.",
      "description": "Risk responses are identified and prioritized.",
      "testSteps": "1. Obtain the organization's risk management plan and/or other documentation showing the organization's response to risk levels identified in the risk assessment. Determine if the risk management plan is designed to accept or reduce risk level in accordance with the organization's risk appetite.\n2. Obtain copies of management responses to recent cybersecurity-related audits and assessments to determine if exceptions noted in audits or assessments are identified and prioritized.",
      "frequency": "Yearly"
  },
  "ID.RM-1": {
      "title": "The organization’s priorities, constraints, risk tolerances, and assumptions are established and used to support operational risk decisions.",
      "description": "Risk management processes are established, managed and agreed to by organizational stakeholders.",
      "testSteps": "1. Evaluate the framework or process used for risk management. Consider the following:\na. Is the process formally documented?\nb. Is the process regularly updated?\nb. Is the process repeatable and measurable?\nc. Does the process have an owner?\nd. Are stakeholders involved or informed of the process?",
      "frequency": "Yearly"
  },
  "ID.RM-2": {
      "title": "The organization’s priorities, constraints, risk tolerances, and assumptions are established and used to support operational risk decisions.",
      "description": "Organizational risk tolerance is determined and clearly expressed.",
      "testSteps": "1. Determine if the organization has defined and approved a cyberrisk appetite statement.",
      "frequency": "Yearly"
  },
  "ID.RM-3": {
      "title": "The organization’s priorities, constraints, risk tolerances, and assumptions are established and used to support operational risk decisions.",
      "description": "The organization’s determination of risk tolerance is informed by its role in critical infrastructure and sector-specific risk analysis.",
      "testSteps": "1. Obtain a copy of the organization's risk management strategy and risk appetite statement to determine if these align with its role in critical infrastructure (as defined by national infrastructure protection plan [NIPP] and sector-specific plans).\n",
      "frequency": "Yearly"
  },
  "ID.SC-1": {
      "title": "The organization’s priorities, constraints, risk tolerances, and assumptions are established and used to support risk decisions associated with managing supply chain risk. The organization has established and implemented the processes to identify, assess and manage supply chain risks.",
      "description": "Cyber supply chain risk management processes are identified, established, assessed, managed, and agreed to by organizational stakeholders",
      "testSteps": "",
      "frequency": "Yearly"
  },
  "ID.SC-2": {
      "title": "The organization’s priorities, constraints, risk tolerances, and assumptions are established and used to support risk decisions associated with managing supply chain risk. The organization has established and implemented the processes to identify, assess and manage supply chain risks.",
      "description": "Suppliers and third party partners of information systems, components, and services are identified, prioritized, and assessed using a cyber supply chain risk assessment process",
      "testSteps": "",
      "frequency": "Yearly"
  },
  "ID.SC-3": {
      "title": "The organization’s priorities, constraints, risk tolerances, and assumptions are established and used to support risk decisions associated with managing supply chain risk. The organization has established and implemented the processes to identify, assess and manage supply chain risks.",
      "description": "Contracts with suppliers and third-party partners are used to implement appropriate measures designed to meet the objectives of an organization’s cybersecurity program and Cyber Supply Chain Risk Management Plan",
      "testSteps": "",
      "frequency": "Yearly"
  },
  "ID.SC-4": {
      "title": "The organization’s priorities, constraints, risk tolerances, and assumptions are established and used to support risk decisions associated with managing supply chain risk. The organization has established and implemented the processes to identify, assess and manage supply chain risks.",
      "description": "Suppliers and third-party partners are routinely assessed using audits, test results, or other forms of evaluations to confirm they are meeting their contractual obligations",
      "testSteps": "",
      "frequency": "Yearly"
  },
  "ID.SC-5": {
      "title": "The organization’s priorities, constraints, risk tolerances, and assumptions are established and used to support risk decisions associated with managing supply chain risk. The organization has established and implemented the processes to identify, assess and manage supply chain risks.",
      "description": "Response and recovery planning and testing are conducted with suppliers and third-party providers",
      "testSteps": "",
      "frequency": "Yearly"
  },
  "PR.AC-1": {
      "title": "Access to assets and associated facilities is limited to authorized users, processes, or devices, and to authorized activities and transactions.",
      "description": "Identities and credentials are issued, managed, verified, revoked, and audited for authorized devices, users and processes",
      "testSteps": "1. Determine whether access to network devices (e.g., servers, workstations, mobile devices, firewalls) are restricted by:\na. Unique user logon IDs\nb. Complex passwords\nc. Multifactor authentication\nd. Automatic timeout if left unattended\ne. Automatic lockout after repeated failed access attempts\nd. Changing default administrative account names and passwords\n2. Determine whether password parameters comply with organization policy and/or applicable industry requirements. Consider the following:\na. Length, complexity, change requirements, history\nb. Are passwords suppressed from all output?\nc. Are password files encrypted and restricted?\n3. Review termination procedures to ensure credentials are revoked or changed when an employee leaves.\na. Spot-check accounts to ensure user access is revoked following termination and accounts are deleted according to policy.",
      "frequency": "Yearly"
  },
  "PR.AC-2": {
      "title": "Access to assets and associated facilities is limited to authorized users, processes, or devices, and to authorized activities and transactions.",
      "description": "Physical access to assets is managed and protected.",
      "testSteps": "1. Determine whether physical access to key assets (e.g., server rooms, network closets, zones) are physically restricted:\na. Locked doors\nb. Surveillance\nc. Fences or walls\nd. Logs\ne. Visitor escorts\n2. Determine whether policies and procedures allow only authorized personnel access to sensitive areas.\n3. Review termination procedures to ensure physical access is removed once an employee leaves.",
      "frequency": "Yearly"
  },
  "PR.AC-3": {
      "title": "Access to assets and associated facilities is limited to authorized users, processes, or devices, and to authorized activities and transactions.",
      "description": "Remote access is managed.",
      "testSteps": "1. Determine whether policies and procedures related to remote users' access capabilities are formalized. Consider the following:\na. Remote users (e.g., employees, contractors, third parties) with access to critical systems are approved and documented.\nb. Remote connections are only opened as required.\nc. Remote connections are logged and monitored.\nd. Remote connections are encrypted.\ne. Strong authentication is in place (e.g., multifactor, strong password parameters).\nf. The ability to wipe data remotely on mobile devices when data are missing or stolen is enabled.\ng. Institution security controls (e.g., antivirus, patch management) are required on remote devices connecting to the network.",
      "frequency": "Yearly"
  },
  "PR.AC-4": {
      "title": "Access to assets and associated facilities is limited to authorized users, processes, or devices, and to authorized activities and transactions.",
      "description": "Access permissions are managed, incorporating the principles of least privilege and separation of duties.",
      "testSteps": "1. Review access rights and permissions for the network and any critical applications.\n2. Determine if user access profiles are consistent with their job functions (based on least privilege). Compare a sample of users' access authority with their assigned duties and responsibilities.\n3. Determine if access is granted for mission critical functions and information system support functions in order to reduce the risk of malevolent activity without collusion (e.g., critical processes require two people to perform the function).\n4. Determine if users with local administrative privilege on workstations require this level of access.\n5. Review how the organization restricts and/or monitors access to sensitive data by users with elevated network privilege.\n6. Determine if role-based access controls are implemented (e.g., roles vs. users are assigned access rights).\n7. Determine if there are regular reviews of access.",
      "frequency": "Yearly"
  },
  "PR.AC-5": {
      "title": "Access to assets and associated facilities is limited to authorized users, processes, or devices, and to authorized activities and transactions.",
      "description": "Network integrity is protected, incorporating network segregation where appropriate.",
      "testSteps": "1. Review network diagrams and data flow diagrams. \n2. Determine if high-value/critical systems are separated from high-risk systems (e.g., VLAN, DMZ, hard backups, air-gapping) where possible.\n3. Determine if the organization has a formal process to approve data flows and/or connections between networks and/or systems.",
      "frequency": "Yearly"
  },
  "PR.AC-6": {
      "title": "Access to assets and associated facilities is limited to authorized users, processes, or devices, and to authorized activities and transactions.",
      "description": "Identities are proofed and bound to credentials and asserted in interactions",
      "testSteps": "",
      "frequency": "Yearly"
  },
  "PR.AC-7": {
      "title": "Access to assets and associated facilities is limited to authorized users, processes, or devices, and to authorized activities and transactions.",
      "description": "Users, devices, and other assets are authenticated (e.g., single-factor, multi-factor) commensurate with the risk of the transaction (e.g., individuals’ security and privacy risks and other organizational risks)",
      "testSteps": "",
      "frequency": "Yearly"
  },
  "PR.AT-1": {
      "title": "The organization’s personnel and partners are provided cybersecurity awareness education and are adequately trained to perform their information security-related duties and responsibilities consistent with related policies, procedures, and agreements.",
      "description": "All users are informed and trained.",
      "testSteps": "1. Review acceptable use policy and/or training materials to ensure content is adequate.\n2. Review user training reports and/or documentation to ensure users are trained in accordance with applicable policy, guidance, and/or requirement (e.g., annual cybersecurity training of all employees).\n3. Determine whether training materials are updated based on changes in cyberthreat environment.",
      "frequency": "Yearly"
  },
  "PR.AT-2": {
      "title": "The organization’s personnel and partners are provided cybersecurity awareness education and are adequately trained to perform their information security-related duties and responsibilities consistent with related policies, procedures, and agreements.",
      "description": "Privileged users understand roles and responsibilities.",
      "testSteps": "1. Determine if the organization has a process to identify privileged users.\n2. Determine if privileged users' roles are well defined and if privileged users are trained based on their responsibilities.\n3. Review training material and/or user agreements to ensure users with elevated privileges are taught security roles and responsibilities associated with elevated privileges.",
      "frequency": "Yearly"
  },
  "PR.AT-3": {
      "title": "The organization’s personnel and partners are provided cybersecurity awareness education and are adequately trained to perform their information security-related duties and responsibilities consistent with related policies, procedures, and agreements.",
      "description": "Third-party stakeholders (e.g., suppliers, customers, partners) understand roles and responsibilities.",
      "testSteps": "1. Review applicable third-party contracts, customer agreements, and partner agreements to ensure security roles and responsibilities are clearly defined.\n2. Review the organization's vendor management program to ensure third parties are complying with cybersecurity responsibilities defined in contracts and agreements.",
      "frequency": "Yearly"
  },
  "PR.AT-4": {
      "title": "The organization’s personnel and partners are provided cybersecurity awareness education and are adequately trained to perform their information security-related duties and responsibilities consistent with related policies, procedures, and agreements.",
      "description": "Senior executives understand roles and responsibilities.",
      "testSteps": "1. Review training and continuing education programs for senior executives. Consider the following:\na. Cybersecurity knowledge and skill levels needed to perform their duties are defined.\nb. Specific role-based training is assigned based on cybersecurity roles and responsibilities. \nc. A method is in place to measure senior executives' cybersecurity knowledge and understanding against organization requirements.\nd. Training and education materials are updated to reflect changes in the threat environment.",
      "frequency": "Yearly"
  },
  "PR.AT-5": {
      "title": "The organization’s personnel and partners are provided cybersecurity awareness education and are adequately trained to perform their information security-related duties and responsibilities consistent with related policies, procedures, and agreements.",
      "description": "Physical and information security personnel understand roles and responsibilities.",
      "testSteps": "1. Review training and continuing education programs for physical and information security personnel. Consider the following:\na. Knowledge and skill levels needed to perform physical and information security duties are defined.\nb. Specific role-based training is assigned based on physical and information security roles and responsibilities. \nc. A method is in place to measure physical and information security personnel's cybersecurity knowledge and understanding against organization requirements.\nd. Training and education materials are updated to reflect changes in the threat environment.\n",
      "frequency": "Yearly"
  },
  "PR.DS-1": {
      "title": "Information and records (data) are managed consistent with the organization’s risk strategy to protect the confidentiality, integrity, and availability of information.",
      "description": "Data-at-rest is protected.",
      "testSteps": "1. Determine if confidential or sensitive data is identified on the organization's network (e.g., data classification, risk assessment).\n2. Determine if confidential data is secured (e.g., strong encryption as defined by industry best practices) at rest.\n3. Determine if mobile devices (e.g., laptops, tablets, removable media) that are used to store confidential data are encrypted.\n4. Review contracts with third parties storing confidential data to ensure appropriate security controls are in place for sensitive data at rest.",
      "frequency": "Yearly"
  },
  "PR.DS-2": {
      "title": "Information and records (data) are managed consistent with the organization’s risk strategy to protect the confidentiality, integrity, and availability of information.",
      "description": "Data-in-transit is protected.",
      "testSteps": "1. Determine if sensitive information is secured (e.g., strong encryption as defined by industry best practices) when transmitted across publicly-accessible networks.\n2. Determine if adequate policies are in place regarding transmission of confidential or sensitive information via email.\n3. Review training materials and/or acceptable use policy to determine whether employees are instructed on organization policy regarding data transmission.\n4. Review contracts with third parties transmitting confidential data to ensure appropriate security controls are in place for transmission of sensitive data.",
      "frequency": "Yearly"
  },
  "PR.DS-3": {
      "title": "Information and records (data) are managed consistent with the organization’s risk strategy to protect the confidentiality, integrity, and availability of information.",
      "description": "Assets are formally managed throughout removal, transfers and disposition.",
      "testSteps": "1. Review asset inventory policies and procedures. Consider the following:\na. Formalized processes in place \nb. Accuracy of asset tracking\nc. Secure removal or destruction of confidential information from decommissioned assets",
      "frequency": "Yearly"
  },
  "PR.DS-4": {
      "title": "Information and records (data) are managed consistent with the organization’s risk strategy to protect the confidentiality, integrity, and availability of information.",
      "description": "Adequate capacity to ensure availability is maintained.",
      "testSteps": "1. Review sample of capacity management monitoring reports used to monitor critical resources such as network bandwidth, CPU, disk utilization, etc.\n2. Determine if resources have adequate capacity (e.g., disk space, CPU). \n3. Determine if the risk of distributed denial-of-service (DDoS) has been addressed and is in line with the organization's risk appetite. ",
      "frequency": "Yearly"
  },
  "PR.DS-5": {
      "title": "Information and records (data) are managed consistent with the organization’s risk strategy to protect the confidentiality, integrity, and availability of information.",
      "description": "Protections against data leaks are implemented.",
      "testSteps": "1. Review risk assessments, information security meeting minutes and information security strategies to determine if the risk of data loss prevention or exfiltration of confidential data is being considered.\n2. Ensure controls or tools (e.g., data loss prevention) are in place to detect or block potential unauthorized or unintentional transmission or removal of confidential data (e.g., email, FTP, USB devices, Telnet) ",
      "frequency": "Yearly"
  },
  "PR.DS-6": {
      "title": "Information and records (data) are managed consistent with the organization’s risk strategy to protect the confidentiality, integrity, and availability of information.",
      "description": "Integrity checking mechanisms are used to verify software, firmware and information integrity.",
      "testSteps": "1. Determine if the organization employs integrity verification tools (e.g., parity checks, cyclical redundancy checks, cryptographic hashes) to detect unauthorized changes to software (e.g., middleware, applications and operating systems with key internal components such as kernels, drivers), firmware (e.g., Basic Input Output System [BIOS]), and information (e.g., metadata such as security attributes associated with information). ",
      "frequency": "Yearly"
  },
  "PR.DS-7": {
      "title": "Information and records (data) are managed consistent with the organization’s risk strategy to protect the confidentiality, integrity, and availability of information.",
      "description": "The development and testing environment(s) are separate from the production environment.",
      "testSteps": "1. If the organization maintains a software development or testing environment, review network diagrams, database connections and applicable firewall/router configurations to determine sufficiency of separation between these environments and the production network.",
      "frequency": "Yearly"
  },
  "PR.DS-8": {
      "title": "Information and records (data) are managed consistent with the organization’s risk strategy to protect the confidentiality, integrity, and availability of information.",
      "description": "Integrity checking mechanisms are used to verify hardware integrity",
      "testSteps": "",
      "frequency": "Yearly"
  },
  "PR.IP-1": {
      "title": "Security policies (that address purpose, scope, roles, responsibilities, management commitment, and coordination among organizational entities), processes, and procedures are maintained and used to manage protection of information systems and assets.",
      "description": "A baseline configuration of information technology/industrial control systems is created and maintained.",
      "testSteps": "1. Determine if the organization has created or adopted baseline configurations (e.g., Center for Internet Security [CIS] benchmarks, Security Technical Implementation Guides [STIG]) for systems (e.g., servers, desktops, routers).\n2. Sample systems against the organization's baseline configurations to ensure standards are followed and enforced.",
      "frequency": "Yearly"
  },
  "PR.IP-2": {
      "title": "Security policies (that address purpose, scope, roles, responsibilities, management commitment, and coordination among organizational entities), processes, and procedures are maintained and used to manage protection of information systems and assets.",
      "description": "A system development life cycle (SDLC) to manage systems is implemented.",
      "testSteps": "1. Obtain and review a copy of the organization's system development life cycle.\n2. Obtain samples of rollout documentation and rollout schedule to ensure compliance with policy.",
      "frequency": "Yearly"
  },
  "PR.IP-3": {
      "title": "Security policies (that address purpose, scope, roles, responsibilities, management commitment, and coordination among organizational entities), processes, and procedures are maintained and used to manage protection of information systems and assets.",
      "description": "Configuration change control processes are in place.",
      "testSteps": "1. Determine if configuration change control processes for information systems are in place. Consider the following:\na. Proposed changes are documented and approved.\nb. Changes are prohibited until designated approvals are received.\nc. Changes are tested and validated before implementation.\nd. Changes are documented and reported upon completion.\n",
      "frequency": "Yearly"
  },
  "PR.IP-4": {
      "title": "Security policies (that address purpose, scope, roles, responsibilities, management commitment, and coordination among organizational entities), processes, and procedures are maintained and used to manage protection of information systems and assets.",
      "description": "Backups of information are conducted, maintained and tested periodically.",
      "testSteps": "1. Determine if a formal backup and recovery plan exists.\n2. Review backup procedures. Ensure periodic backup testing is performed to verify data are accessible and readable.",
      "frequency": "Yearly"
  },
  "PR.IP-5": {
      "title": "Security policies (that address purpose, scope, roles, responsibilities, management commitment, and coordination among organizational entities), processes, and procedures are maintained and used to manage protection of information systems and assets.",
      "description": "Policy and regulations regarding the physical operating environment for organizational assets are met.",
      "testSteps": "1. Review physical security operating environment policies, procedures and plans. Ensure the following are addressed:\na. Emergency shutoff\nb. Emergency lighting\nc. Emergency power\nd. Fire protection\ne. Temperature and humidity control\nf. Water damage protection\ng. Location of information system components (to minimize damage)",
      "frequency": "Yearly"
  },
  "PR.IP-6": {
      "title": "Security policies (that address purpose, scope, roles, responsibilities, management commitment, and coordination among organizational entities), processes, and procedures are maintained and used to manage protection of information systems and assets.",
      "description": "Data is destroyed according to policy.",
      "testSteps": "1. Review media sanitization (data destruction) policies.\n2. Ensure sanitization techniques and procedures are commensurate with the security category or classification of the information or asset and in accordance with applicable federal and organizational standards and policies.\n3. Spot-check trash cans, dumpsters, shred bin and/or shredders to ensure compliance with policy.\n4. Obtain proof (e.g., destruction certificates) that media sanitization is occurring according to policy.",
      "frequency": "Yearly"
  },
  "PR.IP-7": {
      "title": "Security policies (that address purpose, scope, roles, responsibilities, management commitment, and coordination among organizational entities), processes, and procedures are maintained and used to manage protection of information systems and assets.",
      "description": "Protection processes are continuously improved.",
      "testSteps": "1. Review the organization's policies and procedures related to continually improving protection processes. Consider the following:\na. Ongoing audits, assessments and vulnerability scanning are conducted, reviewed and responded to.\nb. Plans, processes and policies are updated based on lessons learned from tests (e.g., business continuity, disaster recovery, incident response).\nc. Designated position and/or committee responsible for continuous evaluation of the organization's information security needs and posture\nd. Threat information gathering and responses to changes in the threat environment",
      "frequency": "Yearly"
  },
  "PR.IP-8": {
      "title": "Security policies (that address purpose, scope, roles, responsibilities, management commitment, and coordination among organizational entities), processes, and procedures are maintained and used to manage protection of information systems and assets.",
      "description": "Effectiveness of protection technologies is shared with appropriate parties.",
      "testSteps": "1. Determine if the organization participates in information sharing and analysis groups.\n2. Determine if the organization facilitates information sharing by enabling authorized users to share authorized information to sharing partners.",
      "frequency": "Yearly"
  },
  "PR.IP-9": {
      "title": "Security policies (that address purpose, scope, roles, responsibilities, management commitment, and coordination among organizational entities), processes, and procedures are maintained and used to manage protection of information systems and assets.",
      "description": "Response plans (incident response and business continuity) and recovery plans (incident recovery and disaster recovery) are in place and managed.",
      "testSteps": "1. Review incident response and business continuity plans to determine if the institution has documented how it will respond to a cyberincident.\n2. Evaluate plans to determine how frequently they are updated and approved.",
      "frequency": "Yearly"
  },
  "PR.IP-10": {
      "title": "Security policies (that address purpose, scope, roles, responsibilities, management commitment, and coordination among organizational entities), processes, and procedures are maintained and used to manage protection of information systems and assets.",
      "description": "Response and recovery plans are tested.",
      "testSteps": "1. Determine whether business continuity and incident response tests are performed according to policy and any applicable guidance.",
      "frequency": "Yearly"
  },
  "PR.IP-11": {
      "title": "Security policies (that address purpose, scope, roles, responsibilities, management commitment, and coordination among organizational entities), processes, and procedures are maintained and used to manage protection of information systems and assets.",
      "description": "Cybersecurity is included in human resources practices. (e.g., deprovisioning, personnel screening)",
      "testSteps": "1. Review hiring procedures to determine whether background checks/screenings are performed for all employees.\n2. Review hiring procedures for positions with access to sensitive information to determine if they are commensurate with a higher level of risk.\n3. Review termination procedures to determine whether accounts/access are disabled in a timely manner.",
      "frequency": "Yearly"
  },
  "PR.IP-12": {
      "title": "Security policies (that address purpose, scope, roles, responsibilities, management commitment, and coordination among organizational entities), processes, and procedures are maintained and used to manage protection of information systems and assets.",
      "description": "A vulnerability management plan is developed and implemented.",
      "testSteps": "1. Obtain the organization's vulnerability management plan and ensure it includes the following:\na. Frequency of vulnerability scanning\nb. Method for measuring the impact of vulnerabilities identified (e.g., Common Vulnerability Scoring System [CVSS])\nc. Incorporation of vulnerabilities identified in other security control assessments (e.g., external audits, penetration tests)\nd. Procedures for developing remediation of identified vulnerabilities\n2. Obtain a copy of the organization's risk assessment to ensure vulnerabilities identified during the vulnerability management process are included.",
      "frequency": "Yearly"
  },
  "PR.MA-1": {
      "title": "Maintenance and repairs of industrial control and information system components is performed consistent with policies and procedures.",
      "description": "Maintenance and repair of organizational assets is performed and logged in a timely manner, with approved and controlled tools.",
      "testSteps": "1. Review controlled maintenance processes. Consider the following:\na. Maintenance activities are approved, scheduled and documented (e.g., date and time, name of individual(s) performing maintenance, description of maintenance performed, systems removed/replaced)\nb. Maintenance staff or vendors are approved, authorized and supervised (if required).\nc. Maintenance tools and media are approved and inspected for improper or unauthorized modifications prior to use.",
      "frequency": "Yearly"
  },
  "PR.MA-2": {
      "title": "Maintenance and repairs of industrial control and information system components is performed consistent with policies and procedures.",
      "description": "Remote maintenance of organizational assets is approved, logged and performed in a manner that prevents unauthorized access.",
      "testSteps": "1. Determine whether remote maintenance on servers, workstations and other systems is performed. Consider the following:\na. Who is allowed to connect to systems (e.g. internal employees, third parties)\nb. What software/version or service is used to connect\nc. Whether end users have to take some action prior to allowing remote control of their workstation and/or whether access is logged and monitored\nd. Adequacy of authentication requirements (e.g., multifactor authentication)",
      "frequency": "Yearly"
  },
  "PR.PT-1": {
      "title": "Technical security solutions are managed to ensure the security and resilience of systems and assets, consistent with related policies, procedures, and agreements.",
      "description": "Audit/log records are determined, documented, implemented and reviewed in accordance with policy.",
      "testSteps": "1. Determine if audit logs (e.g., security, activity) are maintained and reviewed in a timely manner. Verify the adequacy of the logs to monitor and evaluate IT activities and security events. Consider the following:\na. Audit records contain appropriate content (e.g., type of event, when the event occurred, where the event occurred, source of the event, outcome of the event, identity of any individuals or subjects associated with the event).\nb. Log files are sized such that logs are not deleted prior to review and/or being backed up.\nc. Audit logs and tools are protected from unauthorized access, modification and deletion.\n2. Determine if logs for the following parts of the network are monitored and reviewed:\na. Network perimeter (e.g., intrusion dectection systems [IDS], firewalls)\nb. Microsoft systems (e.g., Windows event logs)\nc. Non-Microsoft systems (e.g., syslog files for Unix/Linux servers, routers, switches)",
      "frequency": "Yearly"
  },
  "PR.PT-2": {
      "title": "Technical security solutions are managed to ensure the security and resilience of systems and assets, consistent with related policies, procedures, and agreements.",
      "description": "Removable media is protected and its use restricted according to policy.",
      "testSteps": "1. Obtain a copy of the removable media policy. Review controls defined in the policy. Controls may include:\na. User training\nb. Encryption of removable media\nc. Restricted access to removable media (e.g., USB restrictions)\nd. Sanitization procedures for decommissioned media\n2. Perform spot-checks on systems with removable media restrictions to ensure restrictions are working as expected and comply with the organization's policy.",
      "frequency": "Yearly"
  },
  "PR.PT-3": {
      "title": "Technical security solutions are managed to ensure the security and resilience of systems and assets, consistent with related policies, procedures, and agreements.",
      "description": "Access to systems and assets is controlled, incorporating the principle of least functionality.",
      "testSteps": "1. Review information systems to determine if unnecessary and/or non-secure functions, ports, protocols and services are disabled.\n2. Where feasible, the organization limits component functionality to a single function per device (e.g., dedicated email server).\n3. Determine if the organization reviews functions and services provided by information systems or individual components of information systems to determine which functions and services are candidates for elimination.",
      "frequency": "Yearly"
  },
  "PR.PT-4": {
      "title": "Technical security solutions are managed to ensure the security and resilience of systems and assets, consistent with related policies, procedures, and agreements.",
      "description": "Communications and control networks are protected.",
      "testSteps": "1. Evaluate controls related to communications to ensure the network is secure. Consider:\na. Network perimeter defenses are in place (e.g., border router, firewall).\nb. Physical security controls are used to prevent unauthorized access to telecommunication systems, etc.\nc. Logical network access controls (e.g., VLAN) and technical controls (e.g., encrypting traffic) are in place to protect and/or segregate communications networks (e.g., wireless, WAN, LAN, VoIP).",
      "frequency": "Yearly"
  },
  "PR.PT-5": {
      "title": "Technical security solutions are managed to ensure the security and resilience of systems and assets, consistent with related policies, procedures, and agreements.",
      "description": "Mechanisms (e.g., failsafe, load balancing, hot swap) are implemented to achieve resilience requirements in normal and adverse situations",
      "testSteps": "",
      "frequency": "Yearly"
  },
  "DE.AE-1": {
      "title": "Anomalous activity is detected in a timely manner and the potential impact of events is understood.",
      "description": "A baseline of network operations and expected data flows for users and systems is established and managed.",
      "testSteps": "1. Obtain a copy of the organization's logical network diagram (LND), data flow diagrams, and other network and communications diagrams.\n2. Review the diagrams for the following:\na. Frequency of updates to diagrams\nb. Accuracy and completeness of diagrams\nc. Scope of diagrams is adequate to identify both domains of different risk and control levels (i.e., high-risk, publicly-accessible portions of a network vs. high-value, restricted access portions of the network) and the control points (e.g., firewalls, routers, intrusion detection/prevention systems) between them.\n2. Determine if tools (e.g., security event and information management systems [SIEMs]) are used to establish typical (baseline) traffic so abnormal traffic can be detected.",
      "frequency": "Yearly"
  },
  "DE.AE-2": {
      "title": "Anomalous activity is detected in a timely manner and the potential impact of events is understood.",
      "description": "Detected events are analyzed to understand attack targets and methods.",
      "testSteps": "1. Obtain a copy of policies and procedures regarding system and network monitoring.\na. Determine if policies and procedures require monitoring for anomalous activity at identified control points.\n2. Obtain a copy of detected events (e.g., alerts from IDS) and the organization's response to them. Review the events and responses to ensure thorough analysis of detected events is performed.",
      "frequency": "Yearly"
  },
  "DE.AE-3": {
      "title": "Anomalous activity is detected in a timely manner and the potential impact of events is understood.",
      "description": "Event data are aggregated and correlated from multiple sources and sensors.",
      "testSteps": "1. Obtain a listing of event aggregation and monitoring systems in use at the organization (e.g., SIEMs, event log correlation systems).\n2. Obtain a list of sources that provide data to each event aggregation and monitoring system (e.g., firewalls, routers, servers).\n3. Compare the sources to identified control points between domains of different risk and control levels and determine if they provide adequate monitoring coverage of the organization's environment.",
      "frequency": "Yearly"
  },
  "DE.AE-4": {
      "title": "Anomalous activity is detected in a timely manner and the potential impact of events is understood.",
      "description": "Impact of events is determined.",
      "testSteps": "1. Obtain a copy of detected events and the organization's responses to them.\n2. Review the events, tickets and responses in order to ensure the organization is documenting the impact of anomalous activity using metrics that are applicable to the organization (e.g., compliance impact, operational impact, accurate reporting impact). ",
      "frequency": "Yearly"
  },
  "DE.AE-5": {
      "title": "Anomalous activity is detected in a timely manner and the potential impact of events is understood.",
      "description": "Incident alert thresholds are established.",
      "testSteps": "1. Obtain a copy of alert messages, meeting minutes, reports and other documentation where detected events were escalated.\n2. Review the documentation and determine the following:\na. Detected events are reported in a timely manner to someone with the knowledge and expertise to resolve or escalate the event.\nb. Escalated events are reported to individuals or groups with the appropriate authority to make decisions about the organization's response.\nc. Thresholds are defined such that an event triggers the appropriate response (e.g., business continuity response, disaster recovery response, incident response, legal response).",
      "frequency": "Yearly"
  },
  "DE.CM-1": {
      "title": "The information system and assets are monitored at discrete intervals to identify cybersecurity events and verify the effectiveness of protective measures.",
      "description": "The network is monitored to detect potential cybersecurity events.",
      "testSteps": "1. Obtain a list of the monitoring control implemented by the organization at the following levels:\na. Network (e.g., firewall, router, switch)\nb. Operating system (e.g., server platforms, workstation platforms, appliances)\nc. Application (e.g., account management, file and database access).\n2. Determine if monitoring at each level includes detection of cybersecurity events (e.g., denial-of-service [DoS] attacks, unauthorized account access, unauthorized file/system access, privilege escalation attacks, SQL injection attacks).",
      "frequency": "Yearly"
  },
  "DE.CM-2": {
      "title": "The information system and assets are monitored at discrete intervals to identify cybersecurity events and verify the effectiveness of protective measures.",
      "description": "The physical environment is monitored to detect potential cybersecurity events.",
      "testSteps": "1. Obtain an inventory of critical facilities (e.g., data centers, network closets, operations centers, critical control centers).\n2. Determine if physical security monitoring controls are implemented and appropriate to detect potential cybersecurity events (e.g., sign in/out logs, motion detectors, security cameras, security lighting, security guards, door/window locks, automatic system lock when idle, restricted physical access to servers, workstations, network devices, network ports).",
      "frequency": "Yearly"
  },
  "DE.CM-3": {
      "title": "The information system and assets are monitored at discrete intervals to identify cybersecurity events and verify the effectiveness of protective measures.",
      "description": "Personnel activity is monitored to detect potential cybersecurity events.",
      "testSteps": "1. Obtain a list of the monitoring controls implemented by the organization at the application/user account level (e.g., account management, user access roles, user activity monitoring, file and database access).\n2. Determine if monitoring includes detection and alerting of cybersecurity events (e.g., unauthorized account access, unauthorized file/system access, access out of hours, access to sensitive data, unusual access, unauthorized physical access, privilege escalation attacks).",
      "frequency": "Yearly"
  },
  "DE.CM-4": {
      "title": "The information system and assets are monitored at discrete intervals to identify cybersecurity events and verify the effectiveness of protective measures.",
      "description": "Malicious code is detected.",
      "testSteps": "1. Obtain a copy of processes and procedures used to detect malicious code on the network and servers/workstations (e.g., anti-malware software on servers and workstations, phishing filters on email systems, intrusion prevention/detection systems on the network [IDS/IPS], endpoint security products on workstations and/or servers).\n2. Determine if malicious code controls are:\na. Installed on all applicable systems and network control points\nb. Updated on a regular basis\nc. Configured to perform real-time scanning or periodic scans at regular intervals\n3. Spot-check workstations and other user endpoint devices to verify the following:\na. Malicious code controls are installed.\nb. Malicious code controls are updated.\nc. Malicious code controls are capable of detecting test code (e.g., the EICAR test virus).",
      "frequency": "Yearly"
  },
  "DE.CM-5": {
      "title": "The information system and assets are monitored at discrete intervals to identify cybersecurity events and verify the effectiveness of protective measures.",
      "description": "Unauthorized mobile code is detected.",
      "testSteps": "1. Obtain documented processes and procedures used to detect unauthorized mobile code (e.g., Java, JavaScript, ActiveX, Flash, VBScript) that is run on the organization's servers, workstations and devices.\n2. Determine if detective mobile code controls block unauthorized mobile code when detected (e.g., quarantine, execution blocking, download blocking). \n*Examples of mobile code controls include:\na. Detecting and blocking mobile code attachments in emails (e.g., .exe files, .js files)\nb. Detecting and blocking mobile code portions of websites\nc. Removing the ability to run mobile code on systems that do not require this functionality (e.g., uninstalling Java from workstations without a need for it)\nd. Configuring systems to generate alerts and block execution when mobile code that is not signed with an approved code-signing certificate attempts to execute",
      "frequency": "Yearly"
  },
  "DE.CM-6": {
      "title": "The information system and assets are monitored at discrete intervals to identify cybersecurity events and verify the effectiveness of protective measures.",
      "description": "External service provider activity is monitored to detect potential cybersecurity events.",
      "testSteps": "1. Obtain and review contracts executed with external service providers.\n2. Determine if external service provider contracts require the service providers to:\na. Notify the organization as soon as possible of any known or suspected cybersecurity event.\nb. Notify the organization as soon as possible of termination of any employee who possesses credentials to access the organization's systems or facilities.\nc. Implement security controls equivalent to or exceeding the level of security required of the organization.\n3. Obtain a copy of the organization's logical network diagram (LND) to determine how external service provider networks are connected to the organization's network to determine if monitoring controls (e.g.. firewalls, routers, intrusion detection/prevention systems) are implemented at these connection points.\n4. Obtain and analyze a copy of system configurations for monitoring controls used to detect cybersecurity events originating on external service providers' networks.",
      "frequency": "Yearly"
  },
  "DE.CM-7": {
      "title": "The information system and assets are monitored at discrete intervals to identify cybersecurity events and verify the effectiveness of protective measures.",
      "description": "Monitoring for unauthorized personnel, connections, devices and software is performed.",
      "testSteps": "1. Obtain a copy of processes and procedures designed to detect unauthorized access to the organization's facilities and systems (e.g., sign-in/out logs, video surveillance, break-in alarms, network port blocking, USB device restrictions on workstations and user devices, monitoring of excessive failed logins indicating a password-guessing attack).\n2. Spot-check unauthorized access controls by accessing facilities and systems with permission to test, but not standard authorization. Request the organization provide the alert notifications generated by the simulated unauthorized access.",
      "frequency": "Yearly"
  },
  "DE.CM-8": {
      "title": "The information system and assets are monitored at discrete intervals to identify cybersecurity events and verify the effectiveness of protective measures.",
      "description": "Vulnerability scans are performed.",
      "testSteps": "1. Obtain a copy of the organization's schedule for performing internal and external vulnerability scans and the results of the most recent internal and external vulnerability scans.\n2. Review the schedule and results for the following:\na. Frequency\nb. Successful completion\nc. Documented resolution or mitigation of identified vulnerabilities\nd. Scope of testing includes all critical systems\n3. Determine whether vulnerability scan results were reported to individuals or teams with appropriate authority to ensure resolution.",
      "frequency": "Yearly"
  },
  "DE.DP-1": {
      "title": "Detection processes and procedures are maintained and tested to ensure timely and adequate awareness of anomalous events.",
      "description": "Roles and responsibilities for detection are well defined to ensure accountability.",
      "testSteps": "1. Obtain a copy of processes and procedures for monitoring physical and electronic anomalous events.\n2. Determine if the organization's processes and procedures assign key responsibilities to specific individuals or positions. ",
      "frequency": "Yearly"
  },
  "DE.DP-2": {
      "title": "Detection processes and procedures are maintained and tested to ensure timely and adequate awareness of anomalous events.",
      "description": "Detection activities comply with all applicable requirements.",
      "testSteps": "1. Obtain a copy of laws and regulations (e.g., federal, state, local), industry standards, internal security requirements and risk appetite applicable to the organization.\n2. Determine if the organization is performing audits/testing to ensure their detection activities comply with these requirements.",
      "frequency": "Yearly"
  },
  "DE.DP-3": {
      "title": "Detection processes and procedures are maintained and tested to ensure timely and adequate awareness of anomalous events.",
      "description": "Detection processes are tested.",
      "testSteps": "1. Obtain a copy of the organization's schedule of incident response tests, the results of recent incident response tests, and documented processes and procedures requiring tests of anomalous activity controls (e.g., periodic tests of intrusion detection/prevention systems, endpoint anti-malware software).\n2. Review the documentation for the following:\na. Completeness in testing implemented anomalous activity detection controls\nb. Frequency of testing\nc. Documented resolution or mitigation of negative testing results",
      "frequency": "Yearly"
  },
  "DE.DP-4": {
      "title": "Detection processes and procedures are maintained and tested to ensure timely and adequate awareness of anomalous events.",
      "description": "Event detection information is communicated to appropriate parties.",
      "testSteps": "1. Obtain a copy of meeting minutes where physical and electronic anomalous activity is reported (e.g., information security committee meetings, board/management meetings, risk management meetings).\n2. Obtain a copy of documented responses to recent physical and electronic anomalous activity incidents.\n3. Compare meeting minutes to documented incidents and determine if detected events are consistently reported and appropriately handled.",
      "frequency": "Yearly"
  },
  "DE.DP-5": {
      "title": "Detection processes and procedures are maintained and tested to ensure timely and adequate awareness of anomalous events.",
      "description": "Detection processes are continuously improved.",
      "testSteps": "1. Obtain a copy of documented responses to recent physical and electronic anomalous activity incidents. Determine if responses include the following:\na. Lessons learned and analysis of failed or missing controls\nb. Action items to detect/prevent similar incidents in the future",
      "frequency": "Yearly"
  },
  "RS.RP-1": {
      "title": "Response processes and procedures are executed and maintained, to ensure timely response to detected cybersecurity events.",
      "description": "Response plan is executed during or after an event.",
      "testSteps": "1. Determine if the organization has approved incident response and business continuity plans.\n2. Obtain copies of reports from recent incidents to validate the plans are executed.",
      "frequency": "Yearly"
  },
  "RS.CO-1": {
      "title": "Response activities are coordinated with internal and external stakeholders, as appropriate, to include external support from law enforcement agencies.",
      "description": "Personnel know their roles and order of operations when a response is needed.",
      "testSteps": "1. Review the incident response plan to determine if roles and responsibilities are defined for employees.\n2. Interview employees to determine if employees know their roles and responsibilities as defined by the plan.\n3. Review any incident response tests or training provided to employees to determine if they support educating employees on their roles and responsibilities.",
      "frequency": "Yearly"
  },
  "RS.CO-2": {
      "title": "Response activities are coordinated with internal and external stakeholders, as appropriate, to include external support from law enforcement agencies.",
      "description": "Events are reported consistent with established criteria.",
      "testSteps": "1. Review the incident response plan to determine if reporting structure and communication channels are clearly defined.\n2. Determine if employees are trained to report suspected security incidents.\n3. Obtain copies of reports from recent incidents to validate reporting is consistent and follows the plan.",
      "frequency": "Yearly"
  },
  "RS.CO-3": {
      "title": "Response activities are coordinated with internal and external stakeholders, as appropriate, to include external support from law enforcement agencies.",
      "description": "Information is shared consistent with response plans.",
      "testSteps": "1. Review the incident response plan to determine if information sharing is clearly defined as it relates to the following (if applicable):\na. Customers\nb. Law enforcement\nc. Regulators\nd. Media\ne. Information sharing organizations\n2. Obtain copies of reports from recent incidents to validate sharing is consistent and follows the plan.",
      "frequency": "Yearly"
  },
  "RS.CO-4": {
      "title": "Response activities are coordinated with internal and external stakeholders, as appropriate, to include external support from law enforcement agencies.",
      "description": "Coordination with stakeholders occurs consistent with response plans.",
      "testSteps": "1. Review the incident response plan to determine if a process is in place to communicate with internal and external stakeholders during and/or following an incident.\n2. Obtain copies of reports from recent incidents to validate reporting is consistent and follows the plan.",
      "frequency": "Yearly"
  },
  "RS.CO-5": {
      "title": "Response activities are coordinated with internal and external stakeholders, as appropriate, to include external support from law enforcement agencies.",
      "description": "Voluntary information sharing occurs with external stakeholders to achieve broader cybersecurity situational awareness.",
      "testSteps": "1. Review the incident response plan to determine if a process is in place to communicate with external stakeholders (e.g., end users, suppliers, third parties, customers) following an incident.",
      "frequency": "Yearly"
  },
  "RS.AN-1": {
      "title": "Analysis is conducted to ensure adequate response and support recovery activities.",
      "description": "Notifications from detection systems are investigated.",
      "testSteps": "1. Obtain evidence of event notifications (e.g., detection alerts, reports) from information systems (e.g., account usage, remote access, wireless connectivity, mobile device connection, configuration settings, system component inventory, use of maintenance tools, physical access, temperature and humidity, anomalous activity, use of mobile code).\n2. Determine who receives alerts or reports from detection systems and what actions are taken once reports are received.\n3. Review the incident response plan to determine if actions taken follow the plan.",
      "frequency": "Yearly"
  },
  "RS.AN-2": {
      "title": "Analysis is conducted to ensure adequate response and support recovery activities.",
      "description": "The impact of the incident is understood.",
      "testSteps": "1. Review the incident response plan to determine if there is a process to formally analyze and classify incidents based on their potential impact.\n2. Review resume and education of incident response team members responsible for determining incident impact to determine if they have the knowledge and experience to adequately understand potential impact.",
      "frequency": "Yearly"
  },
  "RS.AN-3": {
      "title": "Analysis is conducted to ensure adequate response and support recovery activities.",
      "description": "Forensics are performed.",
      "testSteps": "1. Review the incident response plan as it relates to forensics. Consider the following:\na. There is a process in place to ensure forensics will be performed when needed.\nb. Determine if security investigations and forensic analysis are performed by qualified staff or third parties.\nc. Review forensics procedures to ensure they include controls, such as chain of custody, to support potential legal action. ",
      "frequency": "Yearly"
  },
  "RS.AN-4": {
      "title": "Analysis is conducted to ensure adequate response and support recovery activities.",
      "description": "Incidents are categorized consistent with response plans.",
      "testSteps": "1. Review the incident response plan to determine if it is designed to prioritize incidents, enabling a rapid response for significant incidents or vulnerabilities.\n2. Obtain copies of reports from recent incidents to validate reporting is consistent and follows the plan.",
      "frequency": "Yearly"
  },
  "RS.MI-1": {
      "title": "Activities are performed to prevent expansion of an event, mitigate its effects and eradicate the incident.",
      "description": "Incidents are contained.",
      "testSteps": "1. Review the incident response plan to determine if appropriate steps are in place to contain an incident. Consider the following:\na. Steps to contain and control the incident to prevent further harm\nb. Procedures to notify potentially impacted third parties \nc. Strategies to control different types of incidents (e.g., distributed denial-of-service [DDoS], malware, etc.)",
      "frequency": "Yearly"
  },
  "RS.MI-2": {
      "title": "Activities are performed to prevent expansion of an event, mitigate its effects and eradicate the incident.",
      "description": "Incidents are mitigated.",
      "testSteps": "1. Review the incident response plan to determine if appropriate steps are in place to mitigate the impact of an incident. Consider the following:\na. Steps to mitigate the incident to prevent further harm\nb. Procedures to notify potentially impacted third parties\nc. Strategies to mitigate impact different types of incidents (e.g., distributed denial-of-service [DDoS], malware, etc.)\n2. Review any documented incidents to determine whether mitigation efforts were implemented and effective.",
      "frequency": "Yearly"
  },
  "RS.MI-3": {
      "title": "Activities are performed to prevent expansion of an event, mitigate its effects and eradicate the incident.",
      "description": "Newly identified vulnerabilities are mitigated or documented as accepted risk.",
      "testSteps": "1. Determine if the organization's continuous monitoring programs (e.g., risk assessments, vulnerability scanning) facilitate ongoing awareness of threats, vulnerabilities and information security to support organizational risk management decisions. Consider the following:\na. Is the process continuous (at a frequency sufficient to support organizational risk-based decisions)\nb. Results generate appropriate risk response (e.g., mitigation strategy, acceptance) based on the organization's risk appetite",
      "frequency": "Yearly"
  },
  "RS.IM-1": {
      "title": "Organizational response activities are improved by incorporating lessons learned from current and previous detection/response activities.",
      "description": "Response plans incorporate lessons learned.",
      "testSteps": "1. Review the organization's incident handling reports and incident testing documentation for action items and lessons learned.\n2. Evaluate the incident response plan to determine if results (e.g., action items, lessons learned) from real-world incidents and incident testing have been used to update incident response procedures, training and testing.",
      "frequency": "Yearly"
  },
  "RS.IM-2": {
      "title": "Organizational response activities are improved by incorporating lessons learned from current and previous detection/response activities.",
      "description": "Response strategies are updated.",
      "testSteps": "1. Review the organization's incident response and business continuity strategies and plans. Consider the following:\na. There is a mechanism in place to regularly review, improve, approve and communicate the plans.\nb. The organization's response capability is informed by actual incidents, tests and current threats.",
      "frequency": "Yearly"
  },
  "RC.RP-1": {
      "title": "Recovery processes and procedures are executed and maintained to ensure timely restoration of systems or assets affected by cybersecurity events.",
      "description": "Recovery plan is executed during or after an event.",
      "testSteps": "1. Obtain a copy of the organization's recovery plans and procedures (e.g., business continuity plan, incident response plan, disaster recovery plan, cybersecurity incident plan) and the documented results of recent cybersecurity events or event tests.\n2. Evaluate documentation for the following:\na. Frequency of testing\nb. Coverage of critical pieces of the organization's recovery plans and procedures\nc. Documentation of incidents (e.g. power outages, communication failures, system outages, attempted and successful malicious or careless unauthorized access or disruption).",
      "frequency": "Yearly"
  },
  "RC.IM-1": {
      "title": "Recovery planning and processes are improved by incorporating lessons learned into future activities.",
      "description": "Recovery plans incorporate lessons learned.",
      "testSteps": "1. Obtain a copy of results of recent cybersecurity events or event tests.\n2. Evaluate documentation for the following:\na. Documented lessons learned and analysis of failed or missing controls\nb. Action items designed to improve recovery plans and procedures based on the lessons learned and analysis",
      "frequency": "Yearly"
  },
  "RC.IM-2": {
      "title": "Recovery planning and processes are improved by incorporating lessons learned into future activities.",
      "description": "Recovery strategies are updated.",
      "testSteps": "1. Obtain a copy of the organization's recovery plans and procedures (e.g., business continuity plan, incident response plan, disaster recovery plan, cybersecurity incident plan) and the documented results of recent cybersecurity events or event tests.\n2. Determine if recovery plans and procedures are reviewed, updated and approved on a regular basis or as changes are made to systems and controls.\n3. Review recovery plans and procedures to determine if action items resulting from lessons learned during cybersecurity events and event tests have been implemented.",
      "frequency": "Yearly"
  },
  "RC.CO-1": {
      "title": "Restoration activities are coordinated with internal and external parties, such as coordinating centers, Internet Service Providers, owners of attacking systems, victims, other CSIRTs and vendors.",
      "description": "Public relations are managed.",
      "testSteps": "1. Obtain a copy of the organization's recovery plans and procedures (e.g., business continuity plan, incident response plan, disaster recovery plan, cybersecurity incident plan).\n2. Determine if the plans and procedures include the following:\na. Designation of points of contact within the organization to communicate with customers, partners, media, regulators and law enforcement\nb. Training for employees regarding where to refer questions about cybersecurity incidents\nc. Order of succession of key positions responsible for managing the organization's reputation risk during cybersecurity incidents\nd. Timely and responsible notification of customers, partners, regulators and law enforcement of a cybersecurity incident",
      "frequency": "Yearly"
  },
  "RC.CO-2": {
      "title": "Restoration activities are coordinated with internal and external parties, such as coordinating centers, Internet Service Providers, owners of attacking systems, victims, other CSIRTs and vendors.",
      "description": "Reputation after an event is repaired.",
      "testSteps": "1. Obtain documented results of recent cybersecurity events. Determine whether the following are included:\na. Informing customers, partners, media, regulators and law enforcement, as applicable, of ongoing efforts to correct identified issues and final resolution\nb. Specific efforts or plans to address reputation repair ",
      "frequency": "Yearly"
  },
  "RC.CO-3": {
      "title": "Restoration activities are coordinated with internal and external parties, such as coordinating centers, Internet Service Providers, owners of attacking systems, victims, other CSIRTs and vendors.",
      "description": "Recovery activities are communicated to internal stakeholders and executive and management teams.",
      "testSteps": "1. Obtain a copy of meeting minutes where cybersecurity events are reported (e.g. Information Security Committee meetings, Board/management meetings, risk management meetings, Compliance Committee meetings).\n2. Obtain a copy of documented results of recent cybersecurity events.\n3. Compare meeting minutes to documented cybersecurity events and determine if recovery activities notified applicable stakeholders and management members (e.g. Board members, stockholders, C-level executives, risk management managers, affected department managers).",
      "frequency": "Yearly"
  }
};
  
export default NISTFrameworkMapping;