import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  Chip
} from '@mui/material';
import { styled } from '@mui/material/styles';
import TimelineIcon from '@mui/icons-material/Timeline';
import AssessmentIcon from '@mui/icons-material/Assessment';

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.background.paper,
  '&:hover': {
    boxShadow: theme.shadows[6],
  },
}));

const getChipColor = (status) => {
  switch (status) {
    case 'COMPLETE':
      return 'success';
    case 'ACCEPT':
      return 'success';
    case 'IN_PROGRESS':
      return 'warning';
    case 'WARNING':
      return 'warning';
    case 'BLOCKED':
      return 'error';
    case 'REJECT':
      return 'error';
    default:
      return 'default';
  }
};

const ComplianceTimelineCard = ({ frameworks }) => {
  const getTimelineItems = () => {
    const items = [];
    frameworks.forEach(framework => {
      // Add framework creation
      items.push({
        date: new Date(framework.created_at),
        framework: framework.title,
        type: framework.type,
        action: 'Framework Created',
        status: framework.testingStatus
      });

      // Add assessment results
      framework.results?.forEach(result => {
        items.push({
          date: new Date(result.date),
          framework: framework.title,
          type: framework.type,
          action: `Control ${result.control} Assessed`,
          status: result.ai_result?.overallAssessment || 'REJECT'
        });
      });
    });
    
    // Sort by date descending and take latest 5
    return items.sort((a, b) => b.date - a.date).slice(0, 10);
  };

  return (
    <StyledCard>
      <CardContent>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <TimelineIcon sx={{ fontSize: 40, mr: 2, color: 'primary.main' }} />
          <Typography variant="h6">Recent Activity</Typography>
        </Box>
        <List>
          {getTimelineItems().map((item, index) => (
            <ListItem key={index}>
              <ListItemIcon>
                <AssessmentIcon color="primary" />
              </ListItemIcon>
              <ListItemText 
                primary={`${item.framework} (${item.type})`}
                secondary={`${item.action} - ${item.date.toLocaleDateString()}`}
              />
              <Chip 
                size="small"
                label={item.status}
                color={getChipColor(item.status)}
              />
            </ListItem>
          ))}
        </List>
      </CardContent>
    </StyledCard>
  );
};

export default ComplianceTimelineCard;