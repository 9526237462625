import React, { useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  Avatar,
  List,
  ListItem,
  ListItemText,
  Divider,
  LinearProgress,
  Chip,
  Collapse
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ControlsThisWeek from './ContolsThisWeek';

// Icons
import PersonIcon from '@mui/icons-material/Person';
import WarningIcon from '@mui/icons-material/Warning';

// Styled Components
const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.background.paper,
  '&:hover': {
    boxShadow: theme.shadows[6],
    transition: 'box-shadow 0.3s ease-in-out',
  },
  cursor: 'pointer'
}));

const StyledAvatar = styled(Avatar)(({ theme, color = 'primary' }) => ({
  backgroundColor: theme.palette[color].main,
  color: theme.palette[color].contrastText,
  width: 56,
  height: 56
}));

const StatusCards = ({ dashboardData, isAdmin }) => {
  const [expandedCard, setExpandedCard] = useState(null);

  const toggleCard = (cardId) => {
    setExpandedCard(expandedCard === cardId ? null : cardId);
  };

  const getUserStats = () => {
    const activeUsers = dashboardData.users.filter(u => u.is_active).length;
    const totalUsers = dashboardData.users.length;
    const inactiveUsers = totalUsers - activeUsers;

    return {
      total: totalUsers,
      inactiveUsers: inactiveUsers,
      activeUsers: activeUsers
    };
  };

  const getControlStats = () => {
    const today = new Date();
    const endOfWeek = new Date(today);
    endOfWeek.setDate(today.getDate() + 7);
    
    const controlsByDay = {};
    for (let i = 0; i < 7; i++) {
      const date = new Date(today);
      date.setDate(date.getDate() + i);
      controlsByDay[date.toISOString().split('T')[0]] = [];
    }

    dashboardData.frameworks.forEach(framework => {
      Object.entries(framework.dueDates || {}).forEach(([controlId, dueDate]) => {
        if (dueDate) {
          const dueDateObj = new Date(dueDate);
          const dateKey = dueDateObj.toISOString().split('T')[0];
          if (dueDateObj >= today && dueDateObj <= endOfWeek) {
            controlsByDay[dateKey]?.push({
              controlId,
              frameworkTitle: framework.title,
              dueDate: dueDateObj
            });
          }
        }
      });
    });
    return controlsByDay;
  };

  const getOverdueStats = () => {
    const today = new Date();
    const overdue = {
      critical: [], // overdue by more than 14 days
      high: [],    // overdue by 7-14 days
      medium: [],  // overdue by 1-7 days
      total: 0
    };

    dashboardData.frameworks.forEach(framework => {
      Object.entries(framework.dueDates || {}).forEach(([controlId, dueDate]) => {
        if (dueDate) {
          const dueDateTime = new Date(dueDate);
          if (dueDateTime < today && framework.reviewStatus !== 'COMPLETE' && framework.testingStatus !== 'COMPLETE' && !isControlAccepted(framework, controlId)) {
            const daysOverdue = Math.floor((today - dueDateTime) / (1000 * 60 * 60 * 24));
            const overdueItem = {
              controlId,
              frameworkTitle: framework.title,
              dueDate: dueDateTime,
              daysOverdue
            };

            if (daysOverdue > 14) {
              overdue.critical.push(overdueItem);
            } else if (daysOverdue > 7) {
              overdue.high.push(overdueItem);
            } else {
              overdue.medium.push(overdueItem);
            }
            overdue.total++;
          }
        }
      });
    });
    return overdue;
  };

  const isControlAccepted = (framework, controlId) => {
    const result = framework.results?.find(r => r.control === controlId)?.ai_result;
    return result?.overallAssessment === 'ACCEPT';
  };

  const formatDate = (gmtString) => {
    const gmtDate = new Date(gmtString);
    const offsetMinutes = new Date().getTimezoneOffset();
    const localDate = new Date(gmtDate.getTime() - (offsetMinutes * 60000));
    return localDate.toLocaleDateString('default', {
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    });
  };

  const userStats = getUserStats();
  const controlStats = getControlStats();
  const overdueStats = getOverdueStats();

  return (
    <>
      {isAdmin && (
        <Grid item xs={12} md={4}>
          <StyledCard onClick={() => toggleCard('users')}>
            <CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Box>
                    <Typography color="textSecondary" gutterBottom>
                      Total Users
                    </Typography>
                    <Typography variant="h4">{userStats.total}</Typography>
                  </Box>
                  <StyledAvatar color="primary">
                    <PersonIcon />
                  </StyledAvatar>
                </Box>
              <Collapse in={expandedCard === 'users'}>
                <Box sx={{ mt: 2 }}>
                  <Divider sx={{ my: 1 }} />
                  <List dense>
                    <ListItem>
                      <ListItemText 
                        primary="Active Users"
                        secondary={userStats.activeUsers}
                      />
                      <LinearProgress 
                        variant="determinate" 
                        value={(userStats.activeUsers / userStats.total) * 100}
                        color="success"
                        sx={{ width: 60, ml: 1 }}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText 
                        primary="Inactive Users"
                        secondary={userStats.inactiveUsers}
                      />
                      <LinearProgress 
                        variant="determinate" 
                        value={(userStats.inactiveUsers / userStats.total) * 100}
                        color="warning"
                        sx={{ width: 60, ml: 1 }}
                      />
                    </ListItem>
                  </List>
                </Box>
              </Collapse>
            </CardContent>
          </StyledCard>
        </Grid>
      )}

      <Grid item xs={12} md={isAdmin ? 4 : 6}>
        <ControlsThisWeek controlsDueThisWeek={dashboardData.controlsDueThisWeek} controlStats={controlStats}/>
      </Grid>

      <Grid item xs={12} md={ isAdmin ? 4 : 6 }>
        <StyledCard onClick={() => toggleCard('overdue')}>
          <CardContent>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Box>
                <Typography color="textSecondary" gutterBottom>
                  Overdue Controls
                </Typography>
                <Typography variant="h4">{dashboardData.overdueControls.length}</Typography>
                {dashboardData.overdueControls.length > 0 && (
                  <Typography variant="caption" color="error">
                    Action Required
                  </Typography>
                )}
              </Box>
              <StyledAvatar color="error">
                <WarningIcon />
              </StyledAvatar>
            </Box>
            <Collapse in={expandedCard === 'overdue'}>
              <Box sx={{ mt: 2 }}>
                <Divider sx={{ my: 1 }} />
                <List dense>
                  {overdueStats.critical.length > 0 && (
                    <>
                      <ListItem>
                        <ListItemText 
                          primary="Critical (>14 days)"
                          secondary={`${overdueStats.critical.length} controls`}
                        />
                        <Chip 
                          size="small"
                          label={overdueStats.critical.length}
                          color="error"
                        />
                      </ListItem>
                      {/* Show critical items */}
                      {overdueStats.critical.map((item, index) => (
                        <ListItem key={`critical-${index}`} sx={{ pl: 4 }}>
                          <ListItemText
                            primary={`${item.controlId} (${item.frameworkTitle})`}
                            secondary={`${item.daysOverdue} days overdue`}
                          />
                        </ListItem>
                      ))}
                    </>
                  )}
                  {overdueStats.high.length > 0 && (
                    <>
                      <ListItem>
                        <ListItemText 
                          primary="High (7-14 days)"
                          secondary={`${overdueStats.high.length} controls`}
                        />
                        <Chip 
                          size="small"
                          label={overdueStats.high.length}
                          color="warning"
                        />
                      </ListItem>
                      {/* Show high priority items */}
                      {overdueStats.high.map((item, index) => (
                        <ListItem key={`high-${index}`} sx={{ pl: 4 }}>
                          <ListItemText
                            primary={`${item.controlId} (${item.frameworkTitle})`}
                            secondary={`${item.daysOverdue} days overdue`}
                          />
                        </ListItem>
                      ))}
                    </>
                  )}
                  {overdueStats.medium.length > 0 && (
                    <>
                      <ListItem>
                        <ListItemText 
                          primary="Medium (1-7 days)"
                          secondary={`${overdueStats.medium.length} controls`}
                        />
                        <Chip 
                          size="small"
                          label={overdueStats.medium.length}
                          color="info"
                        />
                      </ListItem>
                      {/* Show medium priority items */}
                      {overdueStats.medium.map((item, index) => (
                        <ListItem key={`medium-${index}`} sx={{ pl: 4 }}>
                          <ListItemText
                            primary={`${item.controlId} (${item.frameworkTitle})`}
                            secondary={`${item.daysOverdue} days overdue`}
                          />
                        </ListItem>
                      ))}
                    </>
                  )}
                  {overdueStats.total === 0 && (
                    <ListItem>
                      <ListItemText 
                        primary="No overdue controls"
                        secondary="All controls are on schedule"
                      />
                    </ListItem>
                  )}
                </List>
              </Box>
            </Collapse>
          </CardContent>
        </StyledCard>
      </Grid>
    </>
  );
};

export default StatusCards;