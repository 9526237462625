import React, { useState, useMemo } from 'react';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Collapse,
  Box,
  Chip,
  Divider,
  Button,
  Tooltip,
  Paper,
  LinearProgress
} from '@mui/material';
import { styled } from '@mui/material/styles';

// Icons
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SecurityIcon from '@mui/icons-material/Security';

import ISO4To10FrameworkMapping from '../assistant-forms/ISO_4_10_framework_mapping';
import ISOAnnexAFrameworkMapping from '../assistant-forms/ISO_annex_a_framework_mapping';
import CustomFrameworkMapping from '../assistant-forms/custom_audit_checklist';
import NISTFrameworkMapping from '../assistant-forms/nist_framework_mapping';
import HeinekenFrameworkMapping from '../assistant-forms/Heineken_framework_mapping';

const BarContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-end',
  gap: theme.spacing(2),
  height: '250px',
  padding: theme.spacing(2),
}));

const Bar = styled(Box)(({ theme, color = 'primary.main', value = 0, maxValue = 100 }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(1),
  height: `${(value / maxValue) * 100}%`,
  minHeight: '24px',
}));

const BarColumn = styled(Paper)(({ theme, color = 'primary.main' }) => ({
  width: '100%',
  height: '100%',
  backgroundColor: color,
  transition: 'height 0.3s ease',
  borderRadius: theme.shape.borderRadius,
}));

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.background.paper,
  '&:hover': {
    boxShadow: theme.shadows[6],
  },
}));

const ControlItem = styled(ListItem)(({ theme, overdue }) => ({
  backgroundColor: overdue 
    ? theme.palette.mode === 'dark' 
      ? 'rgba(211, 47, 47, 0.1)'
      : 'rgba(211, 47, 47, 0.05)'
    : theme.palette.mode === 'dark' 
      ? theme.palette.grey[900] 
      : theme.palette.grey[50],
  marginBottom: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  listStyle: 'none',
  '&:hover': {
    backgroundColor: theme.palette.mode === 'dark' 
      ? theme.palette.grey[800] 
      : theme.palette.grey[100],
  },
  '& .MuiListItemText-root': {
    marginLeft: 0,
  },
}));

const getFrameworkMapping = (type) => {
  switch (type) {
    case 'ISO 27001 Annex A':
      return ISOAnnexAFrameworkMapping;
    case 'ISO 27001 Clause 4 to 10':
      return ISO4To10FrameworkMapping;
    case 'Custom':
      return CustomFrameworkMapping;
    case 'NIST':
      return NISTFrameworkMapping;
    case 'Heineken':
      return HeinekenFrameworkMapping;
    default:
      return {};
  }
};

const DeadlinesCard = ({ frameworks }) => {
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [expandedControl, setExpandedControl] = useState(null);

  // Reset expanded control when changing months or collapsing month view
  const handleMonthChange = (month) => {
    setExpandedControl(null);
    setSelectedMonth(month);
  };

  const handleControlClick = (controlId) => {
    setExpandedControl(expandedControl === controlId ? null : controlId);
  };

  const getStatusDistribution = (deadlines, selectedMonth) => {
    const distribution = {
      'ACCEPT': 0,
      'WARNING': 0,
      'REJECT': 0,
      'NOT_STARTED': 0
    };

    // If a month is selected, only count controls for that month
    const relevantControls = selectedMonth 
      ? deadlines[selectedMonth] || []
      : Object.values(deadlines).flat();

    relevantControls.forEach(control => {
      distribution[control.status] = (distribution[control.status] || 0) + 1;
    });

    return Object.entries(distribution).map(([status, count]) => ({
      status: status.replace(/_/g, ' '),
      count,
      color: status === 'ACCEPT' ? '#4caf50' :
             status === 'WARNING' ? '#ff9800' :
             status === 'REJECT' ? '#f44336' :
             '#9e9e9e'
    }));
  };

  const getMonthlyDeadlines = () => {
    const deadlines = {};
    frameworks.forEach(framework => {
      const frameworkMapping = getFrameworkMapping(framework.type);
      
      Object.entries(framework.dueDates || {}).filter(([controlId, date]) => {
        return new Date(date) >= new Date();
      }).forEach(([controlId, date]) => {
        const month = new Date(date).toLocaleString('default', { month: 'long' });
        if (!deadlines[month]) {
          deadlines[month] = [];
        }

        const controlDetails = frameworkMapping[controlId] || {};
        
        deadlines[month].push({
          controlId,
          dueDate: new Date(date),
          frameworkTitle: framework.title,
          frameworkType: framework.type,
          status: framework.results?.find(r => r.control === controlId)?.ai_result?.overallAssessment || 'NOT_STARTED',
          automated: framework.automated?.[controlId] || false,
          lastTested: framework.results?.find(r => r.control === controlId)?.timestamp,
          title: controlDetails.title || '',
          description: controlDetails.description || '',
          testSteps: controlDetails.testSteps || '',
          frequency: controlDetails.frequency || 'Not specified',
          frameworkUUID: framework.uuid,
          result: framework.results?.find(r => r.control === controlId)?.ai_result || null
        });
      });
    });
    
    // Sort controls within each month by due date
    Object.keys(deadlines).forEach(month => {
      deadlines[month].sort((a, b) => a.dueDate - b.dueDate);
    });
    
    return deadlines;
  };

  const isOverdue = (date) => {
    return new Date(date) < new Date();
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'ACCEPT':
        return 'success';
      case 'WARNING':
        return 'warning';
      case 'REJECT':
        return 'error';
      case 'NOT_STARTED':
      default:
        return 'default';
    }
  };

  const formatDate = (gmtString) => {
    const gmtDate = new Date(gmtString);
    const offsetMinutes = new Date().getTimezoneOffset();
    const localDate = new Date(gmtDate.getTime() - (offsetMinutes * 60000));
    return localDate.toLocaleDateString('default', {
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    });
  };

  const deadlines = getMonthlyDeadlines();
  const statusDistribution = useMemo(
    () => getStatusDistribution(deadlines, selectedMonth),
    [deadlines, selectedMonth]
  );

  const maxCount = Math.max(...statusDistribution.map(item => item.count));

  return (
    <Grid item xs={12} md={6}>
      <StyledCard>
        <CardContent>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Typography variant="h6">
              Upcoming Deadlines
              {selectedMonth && (
                <>
                  <IconButton 
                    size="small" 
                    onClick={() => handleMonthChange(null)} 
                    sx={{ ml: 1 }}
                  >
                    <ChevronRightIcon />
                  </IconButton>
                  <Typography component="span" variant="subtitle1" sx={{ ml: 1 }}>
                    {selectedMonth}
                  </Typography>
                </>
              )}
            </Typography>
            <Chip 
              icon={<CalendarTodayIcon />} 
              label={`${selectedMonth ? deadlines[selectedMonth]?.length || 0 : Object.values(deadlines).flat().length} Controls`}
              color="primary"
              variant="outlined"
            />
          </Box>
          {!selectedMonth ? (
            <List>
              {Object.entries(deadlines)?.map(([month, controls]) => {
                const overdueCount = controls.filter(c => isOverdue(c.dueDate)).length;
                return (
                  <ListItem 
                    key={month} 
                    button 
                    onClick={() => handleMonthChange(month)}
                    sx={{ mb: 1 }}
                  >
                    <ListItemIcon>
                      <CalendarTodayIcon color={overdueCount > 0 ? "error" : "primary"} />
                    </ListItemIcon>
                    <ListItemText 
                      primary={month} 
                      secondary={`${controls.length} controls due${overdueCount > 0 ? ` (${overdueCount} overdue)` : ''}`}
                    />
                    <ListItemSecondaryAction>
                      <IconButton edge="end" onClick={() => handleMonthChange(month)}>
                        <ChevronRightIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
            </List>
          ) : (
            <Box>
              {deadlines[selectedMonth]?.map((control) => (
                <Box key={control.controlId}>
                  <ControlItem 
                    button 
                    onClick={() => handleControlClick(control.controlId)}
                    overdue={isOverdue(control.dueDate)}
                  >
                    <ListItemIcon>
                      <SecurityIcon color={isOverdue(control.dueDate) ? "error" : "primary"} />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          {control.controlId}
                          <Chip 
                            size="small"
                            label={control.status}
                            color={getStatusColor(control.status)}
                          />
                          {control.automated && (
                            <Tooltip title="Automated Testing Enabled">
                              <Chip 
                                size="small"
                                label="Automated"
                                color="info"
                                variant="outlined"
                              />
                            </Tooltip>
                          )}
                        </Box>
                      }
                      secondary={
                        <Typography variant="body2" color="textSecondary">
                          {control.frameworkTitle} ({control.frameworkType})
                        </Typography>
                      }
                    />
                    <ListItemIcon>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Tooltip title={isOverdue(control.dueDate) ? "Overdue" : "Due Date"}>
                          <Box sx={{ display: 'flex', alignItems: 'center', mr: 1 }}>
                            <AccessTimeIcon 
                              fontSize="small" 
                              color={isOverdue(control.dueDate) ? "error" : "action"}
                              sx={{ mr: 0.5 }}
                            />
                            <Typography variant="body2" color={isOverdue(control.dueDate) ? "error" : "textSecondary"}>
                              {formatDate(control.dueDate)}
                            </Typography>
                          </Box>
                        </Tooltip>
                        <IconButton edge="end" onClick={(e) => {
                          e.stopPropagation();
                          handleControlClick(control.controlId);
                        }}>
                          {expandedControl === control.controlId ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </IconButton>
                      </Box>
                    </ListItemIcon>
                  </ControlItem>
                  <Collapse 
                    in={expandedControl === control.controlId}
                    timeout="auto"
                    unmountOnExit
                  >
                    <Box sx={{ pl: 9, pr: 2, pb: 2 }}>
                      <Typography variant="subtitle2" color="primary" gutterBottom>
                        {control.title}
                      </Typography>
                      
                      <Box sx={{ mt: 2, mb: 2 }}>
                        <Typography variant="body2" color="textSecondary" gutterBottom>
                          Description
                        </Typography>
                        <Typography variant="body2" paragraph>
                          {control.description || 'No description available'}
                        </Typography>
                      </Box>

                      <Box sx={{ mt: 2, mb: 2 }}>
                        <Typography variant="body2" color="textSecondary" gutterBottom>
                          Test Steps
                        </Typography>
                        <Typography variant="body2" paragraph>
                          {control.testSteps || 'No test steps specified'}
                        </Typography>
                      </Box>

                      <Grid container spacing={2} sx={{ mt: 1, mb: 2 }}>
                        <Grid item xs={6}>
                          <Typography variant="body2" color="textSecondary">
                            Test Frequency
                          </Typography>
                          <Typography variant="body2">
                            {control.frequency}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="body2" color="textSecondary">
                            Last Tested
                          </Typography>
                          <Typography variant="body2">
                            {control.lastTested ? formatDate(control.lastTested) : 'Never'}
                          </Typography>
                        </Grid>
                      </Grid>

                      {control.result && (
                        <Box sx={{ mt: 2, mb: 2 }}>
                          <Typography variant="body2" color="textSecondary" gutterBottom>
                            Last Test Result
                          </Typography>
                          <Box sx={{ pl: 2 }}>
                            <Typography variant="body2" paragraph>
                              <strong>Assessment:</strong> {control.result.overallAssessment}
                            </Typography>
                            {control.result.findings?.map((finding, index) => (
                              <Typography key={index} variant="body2" paragraph>
                                <strong>Finding {index + 1}:</strong> {finding}
                              </Typography>
                            ))}
                            {control.result.recommendations?.map((rec, index) => (
                              <Typography key={index} variant="body2" paragraph>
                                <strong>Recommendation {index + 1}:</strong> {rec}
                              </Typography>
                            ))}
                          </Box>
                        </Box>
                      )}

                      <Box sx={{ display: 'flex', gap: 1, mt: 2 }}>
                        {control.automated && (
                          <Button
                            variant="outlined"
                            color="info"
                            size="small"
                            startIcon={<SecurityIcon />}
                          >
                            Automated
                          </Button>
                        )}
                      </Box>
                    </Box>
                  </Collapse>
                  <Divider />
                </Box>
              ))}
            </Box>
          )}

          <Box sx={{ mt: 4 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Control Status Distribution
            </Typography>
            <Paper elevation={0} variant="outlined" sx={{ p: 2 }}>
              <BarContainer>
                {statusDistribution.map((item) => ( 
                  
                  <>
                    <Bar 
                      key={item.status}
                      value={item.count} 
                      maxValue={maxCount}
                    >
                      <BarColumn color={item.color} />
                    </Bar>
                    <Typography 
                    variant="body2" 
                    fontWeight="bold"
                    sx={{ mt: -1 }}
                  >
                    {item.count}
                  </Typography>
                </>
                ))}
              </BarContainer>
            </Paper>

            {/* Status Legend */}
            <Box sx={{ display: 'flex', gap: 2, mt: 2, flexWrap: 'wrap', justifyContent: 'center' }}>
              {statusDistribution.map((item) => (
                <Box 
                  key={item.status}
                  sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    gap: 1 
                  }}
                >
                  <Box 
                    sx={{ 
                      width: 12, 
                      height: 12, 
                      borderRadius: '50%', 
                      bgcolor: item.color 
                    }} 
                  />
                  <Typography variant="body2" color="textSecondary">
                    {item.status}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </CardContent>
      </StyledCard>
    </Grid>
  );
};

export default DeadlinesCard;