import React, { useState, useEffect } from 'react';
import { Select, MenuItem, TextField, Box, Typography } from '@mui/material';
import { 
    AC, AD, AE, AF, AG, AI, AL, AM, AO, AQ, AR, AS, AT, AU, AW, AX, AZ,
    BA, BB, BD, BE, BF, BG, BH, BI, BJ, BL, BM, BN, BO, BQ, BR, BS, BT, BV, BW, BY, BZ,
    CA, CC, CD, CF, CG, CH, CI, CK, CL, CM, CN, CO, CR, CU, CV, CW, CX, CY, CZ,
    DE, DJ, DK, DM, DO, DZ, EC, EE, EG, EH, ER, ES, ET, EU, FI, FJ, FK, FM, FO, FR,
    GA, GB, GD, GE, GF, GG, GH, GI, GL, GM, GN, GP, GQ, GR, GS, GT, GU, GW, GY,
    HK, HM, HN, HR, HT, HU, IC, ID, IE, IL, IM, IN, IO, IQ, IR, IS, IT,
    JE, JM, JO, JP, KE, KG, KH, KI, KM, KN, KP, KR, KW, KY, KZ,
    LA, LB, LC, LI, LK, LR, LS, LT, LU, LV, LY, MA, MC, MD, ME, MF, MG, MH, MK,
    ML, MM, MN, MO, MP, MQ, MR, MS, MT, MU, MV, MW, MX, MY, MZ,
    NA, NC, NE, NF, NG, NI, NL, NO, NP, NR, NU, NZ, OM,
    PA, PE, PF, PG, PH, PK, PL, PM, PN, PR, PS, PT, PW, PY,
    QA, RE, RO, RS, RU, RW, SA, SB, SC, SD, SE, SG, SH, SI, SJ, SK, SL, SM, SN,
    SO, SR, SS, ST, SV, SX, SY, SZ, TA, TC, TD, TF, TG, TH, TJ, TK, TL, TM, TN,
    TO, TR, TT, TV, TW, TZ, UA, UG, UM, US, UY, UZ, VA, VC, VE, VG, VI, VN, VU,
    WF, WS, XK, YE, YT, ZA, ZM, ZW
} from 'country-flag-icons/react/3x2';

const countries = [
    { code: 'AC', name: 'Ascension Island', Flag: AC },
    { code: 'AD', name: 'Andorra', Flag: AD },
    { code: 'AE', name: 'United Arab Emirates', Flag: AE },
    { code: 'AF', name: 'Afghanistan', Flag: AF },
    { code: 'AG', name: 'Antigua and Barbuda', Flag: AG },
    { code: 'AI', name: 'Anguilla', Flag: AI },
    { code: 'AL', name: 'Albania', Flag: AL },
    { code: 'AM', name: 'Armenia', Flag: AM },
    { code: 'AO', name: 'Angola', Flag: AO },
    { code: 'AQ', name: 'Antarctica', Flag: AQ },
    { code: 'AR', name: 'Argentina', Flag: AR },
    { code: 'AS', name: 'American Samoa', Flag: AS },
    { code: 'AT', name: 'Austria', Flag: AT },
    { code: 'AU', name: 'Australia', Flag: AU },
    { code: 'AW', name: 'Aruba', Flag: AW },
    { code: 'AX', name: 'Åland Islands', Flag: AX },
    { code: 'AZ', name: 'Azerbaijan', Flag: AZ },
    { code: 'BA', name: 'Bosnia and Herzegovina', Flag: BA },
    { code: 'BB', name: 'Barbados', Flag: BB },
    { code: 'BD', name: 'Bangladesh', Flag: BD },
    { code: 'BE', name: 'Belgium', Flag: BE },
    { code: 'BF', name: 'Burkina Faso', Flag: BF },
    { code: 'BG', name: 'Bulgaria', Flag: BG },
    { code: 'BH', name: 'Bahrain', Flag: BH },
    { code: 'BI', name: 'Burundi', Flag: BI },
    { code: 'BJ', name: 'Benin', Flag: BJ },
    { code: 'BL', name: 'Saint Barthélemy', Flag: BL },
    { code: 'BM', name: 'Bermuda', Flag: BM },
    { code: 'BN', name: 'Brunei', Flag: BN },
    { code: 'BO', name: 'Bolivia', Flag: BO },
    { code: 'BQ', name: 'Caribbean Netherlands', Flag: BQ },
    { code: 'BR', name: 'Brazil', Flag: BR },
    { code: 'BS', name: 'Bahamas', Flag: BS },
    { code: 'BT', name: 'Bhutan', Flag: BT },
    { code: 'BV', name: 'Bouvet Island', Flag: BV },
    { code: 'BW', name: 'Botswana', Flag: BW },
    { code: 'BY', name: 'Belarus', Flag: BY },
    { code: 'BZ', name: 'Belize', Flag: BZ },
    { code: 'CA', name: 'Canada', Flag: CA },
    { code: 'CC', name: 'Cocos Islands', Flag: CC },
    { code: 'CD', name: 'DR Congo', Flag: CD },
    { code: 'CF', name: 'Central African Republic', Flag: CF },
    { code: 'CG', name: 'Republic of the Congo', Flag: CG },
    { code: 'CH', name: 'Switzerland', Flag: CH },
    { code: 'CI', name: 'Ivory Coast', Flag: CI },
    { code: 'CK', name: 'Cook Islands', Flag: CK },
    { code: 'CL', name: 'Chile', Flag: CL },
    { code: 'CM', name: 'Cameroon', Flag: CM },
    { code: 'CN', name: 'China', Flag: CN },
    { code: 'CO', name: 'Colombia', Flag: CO },
    { code: 'CR', name: 'Costa Rica', Flag: CR },
    { code: 'CU', name: 'Cuba', Flag: CU },
    { code: 'CV', name: 'Cape Verde', Flag: CV },
    { code: 'CW', name: 'Curaçao', Flag: CW },
    { code: 'CX', name: 'Christmas Island', Flag: CX },
    { code: 'CY', name: 'Cyprus', Flag: CY },
    { code: 'CZ', name: 'Czech Republic', Flag: CZ },
    { code: 'DE', name: 'Germany', Flag: DE },
    { code: 'DJ', name: 'Djibouti', Flag: DJ },
    { code: 'DK', name: 'Denmark', Flag: DK },
    { code: 'DM', name: 'Dominica', Flag: DM },
    { code: 'DO', name: 'Dominican Republic', Flag: DO },
    { code: 'DZ', name: 'Algeria', Flag: DZ },
    { code: 'EC', name: 'Ecuador', Flag: EC },
    { code: 'EE', name: 'Estonia', Flag: EE },
    { code: 'EG', name: 'Egypt', Flag: EG },
    { code: 'EH', name: 'Western Sahara', Flag: EH },
    { code: 'ER', name: 'Eritrea', Flag: ER },
    { code: 'ES', name: 'Spain', Flag: ES },
    { code: 'ET', name: 'Ethiopia', Flag: ET },
    { code: 'FI', name: 'Finland', Flag: FI },
    { code: 'FJ', name: 'Fiji', Flag: FJ },
    { code: 'FK', name: 'Falkland Islands', Flag: FK },
    { code: 'FM', name: 'Micronesia', Flag: FM },
    { code: 'FO', name: 'Faroe Islands', Flag: FO },
    { code: 'FR', name: 'France', Flag: FR },
    { code: 'GA', name: 'Gabon', Flag: GA },
    { code: 'GB', name: 'United Kingdom', Flag: GB },
    { code: 'GD', name: 'Grenada', Flag: GD },
    { code: 'GE', name: 'Georgia', Flag: GE },
    { code: 'GF', name: 'French Guiana', Flag: GF },
    { code: 'GG', name: 'Guernsey', Flag: GG },
    { code: 'GH', name: 'Ghana', Flag: GH },
    { code: 'GI', name: 'Gibraltar', Flag: GI },
    { code: 'GL', name: 'Greenland', Flag: GL },
    { code: 'GM', name: 'Gambia', Flag: GM },
    { code: 'GN', name: 'Guinea', Flag: GN },
    { code: 'GP', name: 'Guadeloupe', Flag: GP },
    { code: 'GQ', name: 'Equatorial Guinea', Flag: GQ },
    { code: 'GR', name: 'Greece', Flag: GR },
    { code: 'GS', name: 'South Georgia', Flag: GS },
    { code: 'GT', name: 'Guatemala', Flag: GT },
    { code: 'GU', name: 'Guam', Flag: GU },
    { code: 'GW', name: 'Guinea-Bissau', Flag: GW },
    { code: 'GY', name: 'Guyana', Flag: GY },
    { code: 'HK', name: 'Hong Kong', Flag: HK },
    { code: 'HM', name: 'Heard Island', Flag: HM },
    { code: 'HN', name: 'Honduras', Flag: HN },
    { code: 'HR', name: 'Croatia', Flag: HR },
    { code: 'HT', name: 'Haiti', Flag: HT },
    { code: 'HU', name: 'Hungary', Flag: HU },
    { code: 'ID', name: 'Indonesia', Flag: ID },
    { code: 'IE', name: 'Ireland', Flag: IE },
    { code: 'IL', name: 'Israel', Flag: IL },
    { code: 'IM', name: 'Isle of Man', Flag: IM },
    { code: 'IN', name: 'India', Flag: IN },
    { code: 'IO', name: 'British Indian Ocean Territory', Flag: IO },
    { code: 'IQ', name: 'Iraq', Flag: IQ },
    { code: 'IR', name: 'Iran', Flag: IR },
    { code: 'IS', name: 'Iceland', Flag: IS },
    { code: 'IT', name: 'Italy', Flag: IT },
    { code: 'JE', name: 'Jersey', Flag: JE },
    { code: 'JM', name: 'Jamaica', Flag: JM },
    { code: 'JO', name: 'Jordan', Flag: JO },
    { code: 'JP', name: 'Japan', Flag: JP },
    { code: 'KE', name: 'Kenya', Flag: KE },
    { code: 'KG', name: 'Kyrgyzstan', Flag: KG },
    { code: 'KH', name: 'Cambodia', Flag: KH },
    { code: 'KI', name: 'Kiribati', Flag: KI },
    { code: 'KM', name: 'Comoros', Flag: KM },
    { code: 'KN', name: 'Saint Kitts and Nevis', Flag: KN },
    { code: 'KP', name: 'North Korea', Flag: KP },
    { code: 'KR', name: 'South Korea', Flag: KR },
    { code: 'KW', name: 'Kuwait', Flag: KW },
    { code: 'KY', name: 'Cayman Islands', Flag: KY },
    { code: 'KZ', name: 'Kazakhstan', Flag: KZ },
    { code: 'LA', name: 'Laos', Flag: LA },
    { code: 'LB', name: 'Lebanon', Flag: LB },
    { code: 'LC', name: 'Saint Lucia', Flag: LC },
    { code: 'LI', name: 'Liechtenstein', Flag: LI },
    { code: 'LK', name: 'Sri Lanka', Flag: LK },
    { code: 'LR', name: 'Liberia', Flag: LR },
    { code: 'LS', name: 'Lesotho', Flag: LS },
    { code: 'LT', name: 'Lithuania', Flag: LT },
    { code: 'LU', name: 'Luxembourg', Flag: LU },
    { code: 'LV', name: 'Latvia', Flag: LV },
    { code: 'LY', name: 'Libya', Flag: LY },
    { code: 'MA', name: 'Morocco', Flag: MA },
    { code: 'MC', name: 'Monaco', Flag: MC },
    { code: 'MD', name: 'Moldova', Flag: MD },
    { code: 'ME', name: 'Montenegro', Flag: ME },
    { code: 'MF', name: 'Saint Martin', Flag: MF },
    { code: 'MG', name: 'Madagascar', Flag: MG },
    { code: 'MH', name: 'Marshall Islands', Flag: MH },
    { code: 'MK', name: 'North Macedonia', Flag: MK },
    { code: 'ML', name: 'Mali', Flag: ML },
    { code: 'MM', name: 'Myanmar', Flag: MM },
    { code: 'MN', name: 'Mongolia', Flag: MN },
    { code: 'MO', name: 'Macau', Flag: MO },
    { code: 'MP', name: 'Northern Mariana Islands', Flag: MP },
    { code: 'MQ', name: 'Martinique', Flag: MQ },
    { code: 'MR', name: 'Mauritania', Flag: MR },
    { code: 'MS', name: 'Montserrat', Flag: MS },
    { code: 'MT', name: 'Malta', Flag: MT },
    { code: 'MU', name: 'Mauritius', Flag: MU },
    { code: 'MV', name: 'Maldives', Flag: MV },
    { code: 'MW', name: 'Malawi', Flag: MW },
    { code: 'MX', name: 'Mexico', Flag: MX },
    { code: 'MY', name: 'Malaysia', Flag: MY },
    { code: 'MZ', name: 'Mozambique', Flag: MZ },
    { code: 'NA', name: 'Namibia', Flag: NA },
    { code: 'NC', name: 'New Caledonia', Flag: NC },
    { code: 'NE', name: 'Niger', Flag: NE },
    { code: 'NF', name: 'Norfolk Island', Flag: NF },
    { code: 'NG', name: 'Nigeria', Flag: NG },
    { code: 'NI', name: 'Nicaragua', Flag: NI },
    { code: 'NL', name: 'Netherlands', Flag: NL },
    { code: 'NO', name: 'Norway', Flag: NO },
    { code: 'NP', name: 'Nepal', Flag: NP },
    { code: 'NR', name: 'Nauru', Flag: NR },
    { code: 'NU', name: 'Niue', Flag: NU },
    { code: 'NZ', name: 'New Zealand', Flag: NZ },
    { code: 'OM', name: 'Oman', Flag: OM },
    { code: 'PA', name: 'Panama', Flag: PA },
    { code: 'PE', name: 'Peru', Flag: PE },
    { code: 'PF', name: 'French Polynesia', Flag: PF },
    { code: 'PG', name: 'Papua New Guinea', Flag: PG },
    { code: 'PH', name: 'Philippines', Flag: PH },
    { code: 'PK', name: 'Pakistan', Flag: PK },
    { code: 'PL', name: 'Poland', Flag: PL },
    { code: 'PM', name: 'Saint Pierre and Miquelon', Flag: PM },
    { code: 'PN', name: 'Pitcairn Islands', Flag: PN },
    { code: 'PR', name: 'Puerto Rico', Flag: PR },
    { code: 'PS', name: 'Palestine', Flag: PS },
    { code: 'PT', name: 'Portugal', Flag: PT },
    { code: 'PW', name: 'Palau', Flag: PW },
    { code: 'PY', name: 'Paraguay', Flag: PY },
    { code: 'QA', name: 'Qatar', Flag: QA },
    { code: 'RE', name: 'Réunion', Flag: RE },
    { code: 'RO', name: 'Romania', Flag: RO },
    { code: 'RS', name: 'Serbia', Flag: RS },
    { code: 'RU', name: 'Russia', Flag: RU },
    { code: 'RW', name: 'Rwanda', Flag: RW },
    { code: 'SA', name: 'Saudi Arabia', Flag: SA },
    { code: 'SB', name: 'Solomon Islands', Flag: SB },
    { code: 'SC', name: 'Seychelles', Flag: SC },
    { code: 'SD', name: 'Sudan', Flag: SD },
    { code: 'SE', name: 'Sweden', Flag: SE },
    { code: 'SG', name: 'Singapore', Flag: SG },
    { code: 'SH', name: 'Saint Helena', Flag: SH },
    { code: 'SI', name: 'Slovenia', Flag: SI },
    { code: 'SJ', name: 'Svalbard and Jan Mayen', Flag: SJ },
    { code: 'SK', name: 'Slovakia', Flag: SK },
    { code: 'SL', name: 'Sierra Leone', Flag: SL },
    { code: 'SM', name: 'San Marino', Flag: SM },
    { code: 'SN', name: 'Senegal', Flag: SN },
    { code: 'SO', name: 'Somalia', Flag: SO },
    { code: 'SR', name: 'Suriname', Flag: SR },
    { code: 'ZA', name: 'South Africa', Flag: ZA },
    { code: 'SS', name: 'South Sudan', Flag: SS },
    { code: 'ST', name: 'São Tomé and Príncipe', Flag: ST },
    { code: 'SV', name: 'El Salvador', Flag: SV },
    { code: 'SX', name: 'Sint Maarten', Flag: SX },
    { code: 'SY', name: 'Syria', Flag: SY },
    { code: 'SZ', name: 'Eswatini', Flag: SZ },
    { code: 'TA', name: 'Tristan da Cunha', Flag: TA },
    { code: 'TC', name: 'Turks and Caicos Islands', Flag: TC },
    { code: 'TD', name: 'Chad', Flag: TD },
    { code: 'TF', name: 'French Southern Territories', Flag: TF },
    { code: 'TG', name: 'Togo', Flag: TG },
    { code: 'TH', name: 'Thailand', Flag: TH },
    { code: 'TJ', name: 'Tajikistan', Flag: TJ },
    { code: 'TK', name: 'Tokelau', Flag: TK },
    { code: 'TL', name: 'Timor-Leste', Flag: TL },
    { code: 'TM', name: 'Turkmenistan', Flag: TM },
    { code: 'TN', name: 'Tunisia', Flag: TN },
    { code: 'TO', name: 'Tonga', Flag: TO },
    { code: 'TR', name: 'Turkey', Flag: TR },
    { code: 'TT', name: 'Trinidad and Tobago', Flag: TT },
    { code: 'TV', name: 'Tuvalu', Flag: TV },
    { code: 'TW', name: 'Taiwan', Flag: TW },
    { code: 'TZ', name: 'Tanzania', Flag: TZ },
    { code: 'UA', name: 'Ukraine', Flag: UA },
    { code: 'UG', name: 'Uganda', Flag: UG },
    { code: 'UM', name: 'U.S. Minor Outlying Islands', Flag: UM },
    { code: 'US', name: 'United States', Flag: US },
    { code: 'UY', name: 'Uruguay', Flag: UY },
    { code: 'UZ', name: 'Uzbekistan', Flag: UZ },
    { code: 'VA', name: 'Vatican City', Flag: VA },
    { code: 'VC', name: 'Saint Vincent and the Grenadines', Flag: VC },
    { code: 'VE', name: 'Venezuela', Flag: VE },
    { code: 'VG', name: 'British Virgin Islands', Flag: VG },
    { code: 'VI', name: 'U.S. Virgin Islands', Flag: VI },
    { code: 'VN', name: 'Vietnam', Flag: VN },
    { code: 'VU', name: 'Vanuatu', Flag: VU },
    { code: 'WF', name: 'Wallis and Futuna', Flag: WF },
    { code: 'WS', name: 'Samoa', Flag: WS },
    { code: 'XK', name: 'Kosovo', Flag: XK },
    { code: 'YE', name: 'Yemen', Flag: YE },
    { code: 'YT', name: 'Mayotte', Flag: YT },
    { code: 'ZM', name: 'Zambia', Flag: ZM },
    { code: 'ZW', name: 'Zimbabwe', Flag: ZW }
]

const countriesWithDialCodes = countries.map(country => ({
  ...country,
  dialCode: getDialCode(country.code)
}));

function getDialCode(code) {
  const codes = {
    'AC': '+247', 'AD': '+376', 'AE': '+971', 'AF': '+93', 'AG': '+1268', 
    'AI': '+1264', 'AL': '+355', 'AM': '+374', 'AO': '+244', 'AR': '+54',
    'AS': '+1684', 'AT': '+43', 'AU': '+61', 'AW': '+297', 'AZ': '+994',
    'BA': '+387', 'BB': '+1246', 'BD': '+880', 'BE': '+32', 'BF': '+226',
    'BG': '+359', 'BH': '+973', 'BI': '+257', 'BJ': '+229', 'BM': '+1441',
    'BN': '+673', 'BO': '+591', 'BR': '+55', 'BS': '+1242', 'BT': '+975',
    'BW': '+267', 'BY': '+375', 'BZ': '+501', 'CA': '+1', 'CD': '+243',
    'CF': '+236', 'CG': '+242', 'CH': '+41', 'CI': '+225', 'CK': '+682',
    'CL': '+56', 'CM': '+237', 'CN': '+86', 'CO': '+57', 'CR': '+506',
    'CU': '+53', 'CV': '+238', 'CW': '+599', 'CY': '+357', 'CZ': '+420',
    'DE': '+49', 'DJ': '+253', 'DK': '+45', 'DM': '+1767', 'DO': '+1809',
    'DZ': '+213', 'EC': '+593', 'EE': '+372', 'EG': '+20', 'ER': '+291',
    'ES': '+34', 'ET': '+251', 'FI': '+358', 'FJ': '+679', 'FK': '+500',
    'FM': '+691', 'FO': '+298', 'FR': '+33', 'GA': '+241', 'GB': '+44',
    'GD': '+1473', 'GE': '+995', 'GH': '+233', 'GI': '+350', 'GL': '+299',
    'GM': '+220', 'GN': '+224', 'GQ': '+240', 'GR': '+30', 'GT': '+502',
    'GU': '+1671', 'GW': '+245', 'GY': '+592', 'HK': '+852', 'HN': '+504',
    'HR': '+385', 'HT': '+509', 'HU': '+36', 'ID': '+62', 'IE': '+353',
    'IL': '+972', 'IN': '+91', 'IQ': '+964', 'IR': '+98', 'IS': '+354',
    'IT': '+39', 'JM': '+1876', 'JO': '+962', 'JP': '+81', 'KE': '+254',
    'KG': '+996', 'KH': '+855', 'KI': '+686', 'KM': '+269', 'KN': '+1869',
    'KP': '+850', 'KR': '+82', 'KW': '+965', 'KY': '+1345', 'KZ': '+7',
    'LA': '+856', 'LB': '+961', 'LC': '+1758', 'LI': '+423', 'LK': '+94',
    'LR': '+231', 'LS': '+266', 'LT': '+370', 'LU': '+352', 'LV': '+371',
    'LY': '+218', 'MA': '+212', 'MC': '+377', 'MD': '+373', 'ME': '+382',
    'MG': '+261', 'MH': '+692', 'MK': '+389', 'ML': '+223', 'MM': '+95',
    'MN': '+976', 'MO': '+853', 'MP': '+1670', 'MQ': '+596', 'MR': '+222',
    'MS': '+1664', 'MT': '+356', 'MU': '+230', 'MV': '+960', 'MW': '+265',
    'MX': '+52', 'MY': '+60', 'MZ': '+258', 'NA': '+264', 'NC': '+687',
    'NE': '+227', 'NG': '+234', 'NI': '+505', 'NL': '+31', 'NO': '+47',
    'NP': '+977', 'NR': '+674', 'NU': '+683', 'NZ': '+64', 'OM': '+968',
    'PA': '+507', 'PE': '+51', 'PF': '+689', 'PG': '+675', 'PH': '+63',
    'PK': '+92', 'PL': '+48', 'PM': '+508', 'PR': '+1', 'PS': '+970',
    'PT': '+351', 'PW': '+680', 'PY': '+595', 'QA': '+974', 'RE': '+262',
    'RO': '+40', 'RS': '+381', 'RU': '+7', 'RW': '+250', 'SA': '+966',
    'SB': '+677', 'SC': '+248', 'SD': '+249', 'SE': '+46', 'SG': '+65',
    'SI': '+386', 'SK': '+421', 'SL': '+232', 'SM': '+378', 'SN': '+221',
    'SO': '+252', 'SR': '+597', 'SS': '+211', 'SV': '+503', 'SY': '+963',
    'SZ': '+268', 'TC': '+1649', 'TD': '+235', 'TG': '+228', 'TH': '+66',
    'TJ': '+992', 'TK': '+690', 'TL': '+670', 'TM': '+993', 'TN': '+216',
    'TO': '+676', 'TR': '+90', 'TT': '+1868', 'TV': '+688', 'TW': '+886',
    'TZ': '+255', 'UA': '+380', 'UG': '+256', 'US': '+1', 'UY': '+598',
    'UZ': '+998', 'VA': '+39', 'VC': '+1784', 'VE': '+58', 'VG': '+1284',
    'VI': '+1340', 'VN': '+84', 'VU': '+678', 'WF': '+681', 'WS': '+685',
    'YE': '+967', 'ZA': '+27', 'ZM': '+260', 'ZW': '+263'
  };
  return codes[code] || '+' + Math.floor(Math.random() * 90 + 10); // Placeholder for demo
}

const formatPhoneNumber = (value, countryCode) => {
  if (!value) return '';
  const number = value.replace(/\D/g, '');
  
  const formats = {
    'US': {
      pattern: [3, 3, 4],
      format: (chunks) => `(${chunks[0]}) ${chunks[1]}-${chunks[2]}`
    },
    'GB': {
      pattern: [2, 4, 4],
      format: (chunks) => `${chunks[0]} ${chunks[1]} ${chunks[2]}`
    },
    'FR': {
      pattern: [2, 2, 2, 2, 2],
      format: (chunks) => chunks.join(' ')
    },
    'DE': {
      pattern: [3, 4, 4],
      format: (chunks) => `${chunks[0]} ${chunks[1]} ${chunks[2]}`
    },
    'CN': {
      pattern: [3, 4, 4],
      format: (chunks) => chunks.join(' ')
    },
    'JP': {
      pattern: [2, 4, 4],
      format: (chunks) => chunks.join('-')
    },
    'BR': {
      pattern: [2, 4, 4],
      format: (chunks) => `(${chunks[0]}) ${chunks[1]}-${chunks[2]}`
    },
    'IN': {
      pattern: [3, 3, 4],
      format: (chunks) => chunks.join(' ')
    },
    'RU': {
      pattern: [3, 3, 2, 2],
      format: (chunks) => `(${chunks[0]}) ${chunks[1]}-${chunks[2]}-${chunks[3]}`
    }
  };

  const format = formats[countryCode] || {
    pattern: [3, 3, 4],
    format: (chunks) => chunks.join(' ')
  };

  const chunks = [];
  let start = 0;
  
  for (const length of format.pattern) {
    if (start >= number.length) break;
    chunks.push(number.slice(start, start + length));
    start += length;
  }

  if (chunks.length === 0) return number;
  if (chunks.length === 1) return chunks[0];
  
  return format.format(chunks);
};

const FlagIcon = ({ country }) => {
  const Flag = country.Flag;
  return <Flag style={{ width: '24px', height: '16px' }} />;
};

const PhoneInputWithFlags = ({ value, onChange, onBlur }) => {
  const [selectedCountry, setSelectedCountry] = useState(countriesWithDialCodes[0]);
  const [phone, setPhone] = useState(value || '');

  const handleCountryChange = (event, child) => {
    const countryName = child.props.value;
    const newCountry = countriesWithDialCodes.find(c => c.name === countryName);
    setSelectedCountry(newCountry);
    const fullNumber = `${newCountry.dialCode} ${phone}`;
    onChange?.(fullNumber);
  };
  
  const handlePhoneChange = (event) => {
    const formatted = formatPhoneNumber(event.target.value, selectedCountry.code);
    setPhone(formatted);
    const fullNumber = `${selectedCountry.dialCode} ${formatted}`;
    onChange?.(fullNumber);
  };

  const handleBlur = (event) => {
    const fullNumber = `${selectedCountry.dialCode} ${phone}`;
    onBlur?.(event, fullNumber);
  };

  useEffect(() => {
    if (value) {
      const dialCode = value.split(' ')[0];
      const country = countriesWithDialCodes.find(c => c.dialCode === dialCode);
      if (country) {
        setSelectedCountry(country);
        setPhone(value.slice(dialCode.length).trim());
      }
    }
  }, [value]);

  return (
    <Box sx={{ display: 'flex', gap: 2, alignItems: 'flex-start' }}>
      <Select
        value={selectedCountry.name}
        onChange={handleCountryChange}
        onBlur={handleBlur}
        sx={{ minWidth: 100 }}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <FlagIcon country={selectedCountry} />
            <Typography>{selectedCountry.dialCode}</Typography>
          </Box>
        )}
      >
        {countriesWithDialCodes.map((country) => (
          <MenuItem key={country.code} value={country.name}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <FlagIcon country={country} />
              <Typography>{country.name} ({country.dialCode})</Typography>
            </Box>
          </MenuItem>
        ))}
      </Select>
      
      <TextField
        fullWidth
        value={phone}
        onChange={handlePhoneChange}
        onBlur={handleBlur}
        placeholder="Phone number"
        variant="outlined"
        InputProps={{
          startAdornment: (
            <Box sx={{ mr: 1, color: 'text.secondary' }}>
              {selectedCountry.dialCode}
            </Box>
          ),
        }}
      />
    </Box>
  );
};

export default PhoneInputWithFlags;