const CustomFrameworkMapping = {
  "DR-SR-06": {
      "title": "Oracle-DM-SR-06: Aantoonbare afweging wel/niet goedkeuring data-eigenaar inzake wijzigingen Storage en Retention",
      "description": "Voor alle systeemwijzigingen is de afweging gemaakt of er gevolgen zijn voor Storage en Retention en daarom goedkeuring nodig is van de data-eigenaar voordat de wijziging in productie is genomen. Indien de rol dataeigenaar nog niet is belegd t.a.v. betreffende data vervult de systeem-eigenaar die rol en vraagt deze goedkeuring aan een directielid.",
      "testSteps": "Teststappen:\n1. Stel vast welke wijzigingen impact hebben gehad op vernietigen data aan de hand van de kolom 'goedkeuring data-eigenaar/ business eigenaar'.\n2. Indien ticket geen betrekking heeft op vernietigen van data, dient er NVT geselecteerd te zijn. Daarmee is de control voor die tickets effectief want er is dan bewust de afweging gemaakt of er goedkeuring nodig is.\n3. Indien er een NO is ingevuld bij goedkeuring data-/ business eigenaar én het ticket betreft 'vernietigen van data' dan geldt als teststap: 'toon van een desbetreffend ticket aan dat deze NIET in productie is genomen'. Als dat het geval is, is de control voor die tickets effectief. Als het WEL in productie is genomen dan is de control niet effectief.\n4. Indien er een None is ingevuld (cel is leeg) bij goedkeuring data-/ business eigenaar herstel dat, want kolom moet gevuld zijn. Indien - na al dan niet herstel - er nog sprake is van een NONE, dan is uitkomst van de control niet effectief.\n5. Indien er een Yes is ingevuld, dan effectief.",
      "frequency": "Continuous"
  },
  "IAM-05": {
      "title": "Jira-IAM-05: Toekennen rechten",
      "description": "Toekennen van rechten van een applicatie aan gebruikers of beheerders worden alleen toegewezen na een aanvraag via e-mail of Topdesk.\nPersoonsgebonden accounts vereisen een aanvraag van de medewerker of manager van de medewerker, terwijl niet-persoonsgebonden (NPG) accounts alleen worden geautoriseerd na een aanvraag van de systeemeigenaar, zoals de product owner van de applicatie. Alle aanvragen worden beoordeeld en goedgekeurd door een daartoe geautoriseerd persoon, zoals de manager van de persoon van kwestie. Het IT Risk en Compliance selecteert elk kwartaal steekproefsgewijs uit het HR-systeem een representatief sample van nieuwe accounts om de naleving en effectiviteit van dit proces te toetsen.",
      "testSteps": "Uit te voeren teststappen:\n1) Stel een overzicht op van alle aanvragen van rechten in het systeem voor de geldende testperiode.\n2) Stuur het bestand naar CoE IT Risk & Compliance voor het maken van de deelwaarneming.\n3) Verzamel de documentatie met betrekking tot de geselecteerde deelwaarneming en controleer of de geselecteerde deelwaarneming aantoont dat aanvraag is geaccordeerd door een afdelingsmanager/leidinggevende/systeemeigenaar en dat de rechten zijn aangevraagd conform de autorisatiematrix. Indien dit niet het geval is, achterhaal waarom dit niet is gedocumenteerd en leg dit alsnog vast.\n4) Voeg de documentatie (inclusief populatie en deelwaarneming) toe aan Highbond en leg de hierboven beschreven werkzaamheden vast.",
      "frequency": "Continuous"
  },
  "KTSA-05": {
      "title": "HVVA-KTSA-05: Kennismanagement (per applicatie)",
      "description": "ANWB heeft voor iedere kritieke applicatie aantoonbare actuele werkinstructies opgesteld.",
      "testSteps": "De tester stelt vast dat:\n1) werkinstructies aanwezig zijn voor de applicatie voor kennis management\n2) De tester stelt vast dat de werkinstructies actueel zijn door middel van het bepalen van de datum laatste wijziging. De werkinstructies mogen niet langer dan 3 jaar oud zijn.\n3) De tester legt de hyperlinks naar de werkinstructies vast in de daartoe ontwikkelde template. Eén gedownloade werkinstructie per elk van de 6 applicaties wordt toegevoegd aan het template als bewijs in Highbond.",
      "frequency": "Continuous"
  },
  "ITSCM-09": {
      "title": "Sherpa-ITSCM-09: Monitoring back-ups (ANWB-Cloudomgeving)",
      "description": "Dagelijks monitort het beheerteam de back-ups in de cloudomgeving van de ANWB. Foutmeldingen worden gedocumenteerd en opgeslagen in een centraal beheersysteem. Wanneer een foutmelding zich langer dan een dag voordoet, wordt er een ticket aangemaakt om het probleem op te lossen.",
      "testSteps": "Maak twee maal per kwartaal een overzicht met de resultaten van de back-ups zodat het hele kwartaal afgedekt wordt.\nMaak voor elk overzicht screenshots met tijd en datum in beeld van de export en de query die daarvoor gebruikt is, zodat het totaal aantal resultaten te zien is. Verklaar hoe te zien is dat de overzichten compleet zijn.\nCombineer de overzichten tot één Excel lijst. Voeg daar een kolom aan toe en vergelijk de start en einddatum van de back-ups en laat zien of er gevallen waren die langer dan een dag duurden.\nAls die afwijkingen er zijn, bekijk dan of hiervoor een ticket in Topdesk was aangemaakt en of het probleem adequaat is opgelost.\nIndien de back-ups dagelijks gedraaid hebben, OF indien er afwijkingen waren die goed zijn opgevolgd, dan is de control effectief.",
      "frequency": "Continuous"
  },
  "CM-03": {
      "title": "Profit-CM-03: Testen met anonieme data",
      "description": "Voor tests wordt uitsluitend gebruik gemaakt van data die zowel representatief is als geanonimiseerd. Elk kwartaal verifieert een beheerteamlid dat de testdata voldoet aan deze criteria door middel van evaluatie van documentatie. De controle omvat het evalueren van anonimiserings technieken en het documenteren van bevindingen en bewijsvoering. De resultaten van deze controle worden gedocumenteerd en eventuele afwijkingen worden opgevolgd.",
      "testSteps": "Stappen:\n1) Stel vast of de ontwikkel-, test- en acceptatieomgevingen zijn voorzien van geanonimiseerde data.\n2) Licht toe in de beschrijving op welke wijze invulling is gegeven aan het anonimiseren van data en welke data geanonimiseerd is.\n3) Zoek uit waarom er geen geanonimiseerde testdata beschikbaar is en wat de te ondernemen stappen zijn om dit wel te kunnen doen, indien er geen anonieme testdata aanwezig is.\n4) Voeg de documentatie toe aan Highbond en leg de hierboven beschreven werkzaamheden vast.",
      "frequency": "Continuous"
  },
  "ConfM-03": {
      "title": "ConfM-ConfM-03: Wijzigingen CMDB",
      "description": "Bij het afronden van de wijziging van het configuratie-item wordt dit bijgewerkt in de CMDB met een relatie naar het JIRA ticketnummer als evidence.",
      "testSteps": "Teststappen\n1) Op basis van het totaal aantal records in de CMDB wordt er door de process lead gecontroleerd of er in voorkomende gevallen bij het uitvoeren van een wijziging van een CI in de CMDB ook een relatie naar het JIRA ticketnummer is opgenomen als bewijs.\n2) De bevindingen t.a.v. het bewijs worden vervolgens duidelijk beargumenteerd om zo een conclusie te kunnen trekken over de effectiviteit van de control.",
      "frequency": "Continuous"
  },
  "DM-03": {
      "title": "DM-DM-03: SB Bitlocker",
      "description": "De product owner voert elk half jaar een controle uit om te bevestigen dat alle laptops zo zijn geconfigureerd dat er niet naar USB-sticks kan worden geschreven tenzij deze zijn versleuteld met BitLocker. Tijdens de controle wordt gebruik gemaakt van een validatie van de leverancier omtrent Bitlocker configuratie voor deze endpoints. Eventuele afwijkingen worden opgevolgd.",
      "testSteps": "Uit te voeren stappen::\n1. Licht toe hoe Bitlocker ingesteld kan worden voor laptops.\n2. Stel vast dat de Microsoft Policy voor Bitlocker is ingesteld via Intune voor het actief zijn van de BitLocker configuratie van USB-sticks op laptops van ANWB.\n3. Voeg de documentatie toe aan Highbond en leg de hierboven beschreven werkzaamheden vast. Geef een oordeel over de uitgevoerde werkzaamheden.",
      "frequency": "Continuous"
  },
  "C-02": {
      "title": "C-C-02: Privacybeleid",
      "description": "ANWB heeft privacy beleid vastgesteld.",
      "testSteps": "Uit te voeren stappen:\n1) Zoek op sharepoint de laatste versie het beleid op.\n2) Ga na of er significante wijzigingen zijn geweest in de afgelopen 12 maanden volgens opgave van de compliance officer\n3) Indien er significante wijzigingen zijn geweest, ga na of het beleid hierop is aangepast.\n4) Ga na of het beleid maximaal 36 maanden oud is, indien er geen significante wijzigingen zijn geweest.",
      "frequency": "Continuous"
  },
  "CKCM-04": {
      "title": "CKCM-CKCM-04: Volledigheid Certificaten Scope",
      "description": "Het certificaat overzicht word gereviewed in zijn volledigheid naar aanleiding van de DNB applicatie scope door de product owners en of gedelegeerden. De proces lead verzameld alle confirmaties van de product owners om de volledigheid te waarborgen.",
      "testSteps": "Test Steps/Test Attributes\n1. Vind het laaste certificaat overzicht\n2. Stuur dit overzicht op naar de product owners van de applicaties en vraag om confirmatie: of dit alle certificaten zijn die in hun scope zijn / dat dat de ANWB de eigenaar is van het certificaat/sleutel en dat het certificaat in de target omgeving is opgeslagen (intern: PKI / extern: Xolphin / Hosted diensten: op een voor de dienst logische wijze)\n3. Upload de emails als evidence van de product owners in deze control.",
      "frequency": "Continuous"
  },
  "ConfM-04": {
      "title": "ConfM-ConfM-04: Integriteitscontrole CMDB",
      "description": "Om ervoor te zorgen dat de integriteit van de CMDB gewaarborgd is, worden er minimaal ieder half jaar steekproeven uitgevoerd om te controleren of het updaten van configuratie-items in de CMDB correct is uitgevoerd. Dit is een data delta check, minimaal voor 5 CI’s.",
      "testSteps": "Test Steps/Test Attributes\n1. Om deze controle uit te voeren wordt er aan R&C gevraagd om aselect minimaal 5 records uit de CMDB te selecteren, rekening houdend met het totaal aantal records.\n2. Nadat de specifieke records zijn geïdentificeerd wordt er per e-mail een uitvraag gedaan door de proces lead aan de teams die verantwoordelijk zijn voor de betreffende CI’s. In de e-mail wordt gevraagd om bewijs te leveren door een duidelijk screenshot te maken van de versie van OS / Database in combinatie met datum en tijd van het besturingssysteem.\n3. Nadat alle reacties binnen zijn, worden de uitgevraagde records, het geleverde bewijs en een uitdraai van het totaal aantal records geüpload. De bevindingen t.a.v. het bewijs worden vervolgens duidelijk beargumenteerd om zo een conclusie te kunnen trekken over de effectiviteit van de control.",
      "frequency": "Continuous"
  }
};
  
export default CustomFrameworkMapping;