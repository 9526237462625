import React, { useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Avatar,
  List,
  Divider,
  Chip,
  IconButton
} from '@mui/material';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { styled } from '@mui/material/styles';

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.background.paper,
  '&:hover': {
    boxShadow: theme.shadows[6],
    transition: 'box-shadow 0.3s ease-in-out',
  },
  cursor: 'pointer'
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.warning.main,
  color: theme.palette.warning.contrastText,
  width: 56,
  height: 56
}));

const ControlsThisWeek = ({ controlsDueThisWeek, controlStats }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  // Group controls by framework
  const groupedControls = controlsDueThisWeek.reduce((acc, control) => {
    if (!acc[control.frameworkTitle]) {
      acc[control.frameworkTitle] = [];
    }
    acc[control.frameworkTitle].push(control);
    return acc;
  }, {});

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('default', {
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    });
  };

  return (
    <StyledCard onClick={() => setIsExpanded(!isExpanded)}>
      <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box>
            <Typography color="textSecondary" gutterBottom>
              Controls This Week
            </Typography>
            <Typography variant="h4">
              {controlsDueThisWeek.length}
            </Typography>
          </Box>
          <StyledAvatar>
            <AssignmentIcon />
          </StyledAvatar>
        </Box>

        {isExpanded && (
            <Box sx={{ mt: 3 }}>
            <Divider sx={{ my: 2 }} />
            
            {Object.entries(groupedControls).map(([framework, controls]) => (
              <Box key={framework} sx={{ mb: 3 }}>
                <Box sx={{ 
                    display: 'flex', 
                    justifyContent: 'space-between', 
                    alignItems: 'center',
                    mb: 1 
                }}>
                    <Typography variant="subtitle1" color="text.primary">
                    {framework}
                    </Typography>
                    <Chip
                    size="small"
                    label={controls.length}
                    color="primary"
                    variant="outlined"
                    />
                </Box>

                <List dense sx={{ mt: 1 }}>
                    {controls.map((control) => (
                      <Box sx={{ 
                          display: 'flex', 
                          justifyContent: 'space-between', 
                          alignItems: 'center',
                          mb: 1 
                      }}>
                        <Typography key={control.controlId} variant="body2" color="text.secondary">
                            {control.controlId}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {Object.keys(controlStats).map(key => {
                                const stat = controlStats[key].find(s => s.controlId === control.controlId);
                                return stat ? formatDate(stat.dueDate) : null;
                            }).filter(d => d).join(' - ')}
                        </Typography>
                      </Box>
                    ))}
                </List>
              </Box>
            ))}
            </Box>
        )}
        
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          <IconButton size="small" sx={{ color: 'text.secondary' }}>
            {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </Box>
      </CardContent>
    </StyledCard>
  );
};

export default ControlsThisWeek;