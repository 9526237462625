import React, { useEffect, useState } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import {
  Box,
  CircularProgress,
  Typography,
  Paper,
  Alert,
  Container,
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';

const theme = createTheme();

const JwtAuthForm = () => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const processJwtToken = () => {
      try {
        // Get JWT from URL query parameters
        const queryParams = new URLSearchParams(location.search);
        const token = queryParams.get('jwt');

        if (!token) {
          setError('No JWT token provided');
          setIsLoading(false);
          return;
        }

        // Store the token
        localStorage.setItem('auth_token', token);
        
        // Navigate to the frameworks page after successful token storage
        navigate('/dashboard', { replace: true });
      } catch (err) {
        setError('Failed to process JWT token');
        console.error('JWT processing error:', err);
        setIsLoading(false);
      }
    };

    processJwtToken();
  }, [location, navigate]);

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="sm">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Paper
            elevation={3}
            sx={{
              p: 4,
              marginTop: 16,
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
              <img 
                src="logo.png" 
                alt="Logo"
                style={{ maxWidth: '370px', height: 'auto' }}
              />
            </Box>

            {isLoading ? (
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 3 }}>
                <CircularProgress />
                <Typography variant="body1" sx={{ mt: 2 }}>
                  Processing authentication...
                </Typography>
              </Box>
            ) : error ? (
              <Alert severity="error" sx={{ width: '100%', mt: 3 }}>
                {error}
              </Alert>
            ) : null}
          </Paper>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default JwtAuthForm;