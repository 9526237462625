import React, { useState, useEffect } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
  CircularProgress,
  Alert,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Snackbar,
  Menu,
  Checkbox,
  Chip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Switch,
  FormControlLabel,
  useTheme
} from '@mui/material';

import FilterListIcon from '@mui/icons-material/FilterList';
import { Download } from '@mui/icons-material';
import { saveAs } from 'file-saver';

import RefreshIcon from '@mui/icons-material/Refresh';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import PersonIcon from '@mui/icons-material/Person';

import UserService from '../services/user_service';
import AuthService from '../services/auth_service';
import UserTabs from './UserTabs';
import MenuButtons from './MenuButtons';

const UserPage = () => {
  const theme = useTheme();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedRole, setSelectedRole] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [editingUser, setEditingUser] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [formData, setFormData] = useState({
    email: '',
    full_name: '',
    phone_number: '',
    state_region: '',
    address: '',
    country: '',
    city: '',
    zip_code: '',
    profile_picture: null,
    is_active: false,
    role: 'user',
    peers: [],
    pbc_jwt: null
  });
  const [searchQuery, setSearchQuery] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [selectedTab, setSelectedTab] = useState('all');
  const [columnVisibility, setColumnVisibility] = useState({
    email: true,
    full_name: true,
    phone_number: true,
    role: true,
    status: true,
    created_at: false,
  });
  const [filters, setFilters] = useState({
    column: '',
    operator: '',
    value: '',
  });

  const columns = [
    { id: 'full_name', label: 'Full Name' },
    { id: 'phone_number', label: 'Phone Number' },
    { id: 'email', label: 'Email' },
    { id: 'role', label: 'Role' },
    { id: 'status', label: 'Status' },
    { id: 'created_at', label: 'Created At' },
  ];

  const operators = ['Contains', 'Equals', 'Starts With', 'Ends With'];

  const ROLES = ['admin', 'user'];

  const filteredUsers = users.filter((user) => {
    const searchLower = searchQuery.toLowerCase();
  
    // Tab filter for active/inactive status
    if (selectedTab === 'active' && !user.is_active) return false;
    if (selectedTab === 'inactive' && user.is_active) return false;
  
    const matchesSearch = user.email.toLowerCase().includes(searchLower) ||
      user.full_name?.toLowerCase().includes(searchLower) ||
      user.phone_number?.toLowerCase().includes(searchLower) ||
      user.role?.toLowerCase().includes(searchLower) ||
      (user.is_active ? 'active' : 'inactive').includes(searchLower);
    
    let matchesType = !selectedRole || user.role?.toLowerCase() === selectedRole;
    if (selectedRole === 'user') {
      matchesType = !user.role || user.role?.toLowerCase() === selectedRole;
    }
  
    return matchesSearch && matchesType;
  });

  const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);
  const filterMenuOpen = (event) => setFilterAnchorEl(event.currentTarget);
  const handleFilterMenuClose = () => setFilterAnchorEl(null);

  const toggleColumnVisibility = (column) => {
    setColumnVisibility(prev => ({
      ...prev,
      [column]: !prev[column]
    }));
  };

  const handleFilterChange = (column, value) => {
    setFilters(prev => ({
      ...prev,
      [column]: value
    }));
  };

  const applyFilter = () => {
    setFilterAnchorEl(null);
    const { column, operator, value } = filters;

    if (column && operator && value) {
      const filteredData = users.filter((user) => {
        const cellValue = column === 'status' ? 
          (user.is_active ? 'active' : 'inactive') : 
          user[column]?.toString().toLowerCase();
        const filterValue = value.toLowerCase();

        switch (operator) {
          case 'Contains': return cellValue?.includes(filterValue);
          case 'Equals': return cellValue === filterValue;
          case 'Starts With': return cellValue?.startsWith(filterValue);
          case 'Ends With': return cellValue?.endsWith(filterValue);
          default: return true;
        }
      });

      setUsers(filteredData);
    }
  };

  const downloadCSV = () => {
    const csvHeaders = columns
      .filter(column => columnVisibility[column.id])
      .map(column => column.label)
      .join(',');
  
    const visibleUsers = users.filter((user) => {
      if (selectedTab === 'active' && !user.is_active) return false;
      if (selectedTab === 'inactive' && user.is_active) return false;
      return true;
    });
  
    const csvRows = visibleUsers.map(user => {
      return columns
        .filter(column => columnVisibility[column.id])
        .map(column => {
          if (column.id === 'status') return user.is_active ? 'Active' : 'Inactive';
          return user[column.id] || '';
        })
        .join(',');
    });
  
    const csvContent = [csvHeaders, ...csvRows].join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, `users_${selectedTab}.csv`);
  };

  const fetchUsers = async () => {
    try {
      setLoading(true);
      const response = await UserService.getUsers();
      setUsers(response.users);
      setError(null);
    } catch (err) {
      setError(err.message || 'Failed to fetch users');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleOpenDialog = (user = null) => {
    if (user) {
      setEditingUser(user);
      setFormData({
        email: user.email,
        fullName: user.full_name,
        phone_number: user.phone_number,
        state_region: user.state_region,
        address: user.address,
        country: user.country,
        city: user.city,
        zip_code: user.zip_code,
        profile_picture: user.profile_picture,
        pbc_jwt: user.pbc_jwt,
        is_active: user.is_active,
        role: user.role || 'user',
        peers: user.peers || []
      });
    } else {
      setEditingUser(null);
      setFormData({
        email: '',
        full_name: '',
        phone_number: '',
        state_region: '',
        address: '',
        country: '',
        city: '',
        zip_code: '',
        profile_picture: null,
        is_active: false,
        role: 'user',
        peers: []
      });
    }
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setEditingUser(null);
    setFormData({
      email: '',
      full_name: '',
      phone_number: '',
      state_region: '',
      address: '',
      country: '',
      city: '',
      zip_code: '',
      profile_picture: null,
      is_active: false,
      role: 'user',
      peers: []
    });
  };

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      if (editingUser) {
        await UserService.updateUser(editingUser.email, formData);
        setSnackbar({
          open: true,
          message: 'User updated successfully',
          severity: 'success'
        });
      } else {
        const response = await UserService.addUser(formData.email);
        if (response.success) {
          setSnackbar({
            open: true,
            message: 'User created successfully',
            severity: 'success'
          });
        } else {
          setSnackbar({
            open: true,
            message: 'Operation failed',
            severity: 'error'
          });
        }
      }
      handleCloseDialog();
      fetchUsers();
    } catch (err) {
      setSnackbar({
        open: true,
        message: err.message || 'Operation failed',
        severity: 'error'
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleGeneratePublicLink = async () => {
    try {
      const response = await UserService.generatePublicLink(formData.email);
      setFormData({ ...formData, pbc_jwt: response.pbc_jwt });
    } catch (err) {
      setSnackbar({
        open: true,
        message: err.message || 'Failed to generate public link',
        severity: 'error'
      });
    }
  };

  const handleDeleteUser = async (email) => {
    if (!window.confirm('Are you sure you want to delete this user?')) return;

    try {
      await UserService.deleteUser(email);
      setSnackbar({
        open: true,
        message: 'User deleted successfully',
        severity: 'success'
      });
      fetchUsers();
    } catch (err) {
      setSnackbar({
        open: true,
        message: err.message || 'Failed to delete user',
        severity: 'error'
      });
    }
  };

  const formatDate = (gmtString) => {
    const gmtDate = new Date(gmtString);
    const offsetMinutes = new Date().getTimezoneOffset();
    const localDate = new Date(gmtDate.getTime() - (offsetMinutes * 60000));
    return localDate.toLocaleString();
  };

  if (loading && users.length === 0) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ 
      width: '100%', 
      p: 3,
      maxWidth: 1800,
      margin: '0 auto', 
      backgroundColor: theme.palette.mode === 'dark' ? 'rgb(30,30,30);' : 'rgb(255,255,255);',
      borderRadius: 2
    }}>
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        mb: 4
      }}>
        <Typography variant="h4" component="div" color="text.primary">
          Manage Users
        </Typography>
        
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button
            startIcon={<AddIcon />}
            onClick={() => handleOpenDialog()}
            variant="contained"
            color="primary"
          >
            Add User
          </Button>
        </Box>
      </Box>

      {error && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
      )}

      <UserTabs 
        users={users}
        selectedTab={selectedTab}
        onTabChange={setSelectedTab}
      />

      <Box sx={{ mb: 4, display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 2 }}>
        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel id="role-filter-label">Role</InputLabel>
          <Select
            labelId="role-filter-label"
            id="role-filter"
            value={selectedRole || ''}
            label="Role"
            onChange={(e) => setSelectedRole(e.target.value)}
          >
            <MenuItem value="">
              <em>All Roles</em>
            </MenuItem>
            {ROLES.map((type) => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          label="Search Users"
          variant="outlined"
          value={searchQuery}
          fullWidth
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button
            startIcon={<FilterListIcon />}
            onClick={handleMenuOpen}
            variant="outlined"
          >
            Columns
          </Button>
          <Button
            startIcon={<FilterListIcon />}
            onClick={filterMenuOpen}
            variant="outlined"
          >
            Filters
          </Button>
          {/* <Button
            startIcon={<Download />}
            onClick={downloadCSV}
            variant="outlined"
          >
            Export
          </Button>
          <Button
            startIcon={<RefreshIcon />}
            onClick={fetchUsers}
            variant="outlined"
          >
            Refresh
          </Button> */}
        </Box>
        
        <Box sx={{ display: 'flex', gap: 2 }}>
          <MenuButtons 
            handleMenuOpen={handleMenuOpen}
            filterMenuOpen={filterMenuOpen}
            downloadCSV={downloadCSV}
            fetchUsers={fetchUsers}
          />
        </Box>
      </Box>

      <Menu anchorEl={filterAnchorEl} open={Boolean(filterAnchorEl)} onClose={handleFilterMenuClose}>
        <Box sx={{ p: 2, width: 300 }}>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>Column</InputLabel>
            <Select
              value={filters.column}
              onChange={(e) => handleFilterChange('column', e.target.value)}
              label="Column"
            >
              {columns.map((col) => (
                <MenuItem key={col.id} value={col.id}>{col.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>Operator</InputLabel>
            <Select
              value={filters.operator}
              onChange={(e) => handleFilterChange('operator', e.target.value)}
              label="Operator"
            >
              {operators.map((op) => (
                <MenuItem key={op} value={op}>{op}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label="Value"
            value={filters.value}
            onChange={(e) => handleFilterChange('value', e.target.value)}
            fullWidth
            sx={{ mb: 2 }}
          />
          <Button variant="contained" onClick={applyFilter} fullWidth>Apply Filter</Button>
        </Box>
      </Menu>

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
        {columns.map((column) => (
          <MenuItem key={column.id}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={columnVisibility[column.id]}
                  onChange={() => toggleColumnVisibility(column.id)}
                />
              }
              label={column.label}
            />
          </MenuItem>
        ))}
      </Menu>

      <TableContainer component={Paper} sx={{
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.palette.mode === 'dark' ? 'none' : undefined,
        border: theme.palette.mode === 'dark' ? `1px solid ${theme.palette.divider}` : undefined,
      }}>
        <Table sx={{ minWidth: 650 }} aria-label="users table">
          <TableHead>
            <TableRow>
              {columns.map((column) => columnVisibility[column.id] && (
                <TableCell key={column.id} sx={{ 
                  fontWeight: 'bold',
                  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[900] : theme.palette.grey[100],
                  color: theme.palette.text.primary
                }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredUsers.map((user) => (
              <TableRow key={user.email} sx={{ 
                '&:hover': {
                  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.action.hover
                },
                '& > td': {
                  borderBottom: `1px solid ${theme.palette.divider}`,
                  color: theme.palette.text.primary
                }
              }}>
                {columnVisibility['full_name'] && (
                  <TableCell>{user.full_name}</TableCell>
                )}
                {columnVisibility['phone_number'] && (
                  <TableCell>{user.phone_number}</TableCell>
                )}
                {columnVisibility['email'] && (
                  <TableCell>
                    {user.role === 'admin' ? 
                      <AdminPanelSettingsIcon color="primary" sx={{mr:1}}/> : 
                      <PersonIcon sx={{mr:1, color: theme.palette.text.primary}}/>
                    }
                    {user.email}
                  </TableCell>
                )}
                {columnVisibility['role'] && (
                  <TableCell>
                    <Chip
                      label={user.role || 'user'}
                      color={user.role === 'admin' ? 'primary' : 'default'}
                      size="small"
                      sx={{
                        '& .MuiChip-label': {
                          color: theme.palette.mode === 'dark' ? theme.palette.text.primary : undefined
                        }
                      }}
                    />
                  </TableCell>
                )}
                {columnVisibility['status'] && (
                  <TableCell>
                    <Chip
                      label={user.is_active ? 'Active' : 'Inactive'}
                      color={user.is_active ? 'success' : 'error'}
                      size="small"
                      sx={{
                        '& .MuiChip-label': {
                          color: theme.palette.mode === 'dark' ? theme.palette.text.primary : undefined
                        }
                      }}
                    />
                  </TableCell>
                )}
                {columnVisibility['created_at'] && (
                  <TableCell>{formatDate(user.created_at)}</TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {users.length === 0 && !error && (
        <Box sx={{ 
          mt: 4, 
          textAlign: 'center',
          p: 4,
          backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[900] : theme.palette.grey[50],
          borderRadius: 1
        }}>
          <Typography color="text.secondary">No users found</Typography>
        </Box>
      )}

      {users.length === 0 && !error && (
        <Box sx={{ 
          mt: 4, 
          textAlign: 'center',
          p: 4,
          backgroundColor: theme.palette.mode === 'dark' 
            ? theme.palette.grey[900] 
            : theme.palette.grey[50],
          borderRadius: 1
        }}>
          <Typography color="text.secondary">
            No users found
          </Typography>
        </Box>
      )}

      <Dialog 
        open={openDialog} 
        onClose={handleCloseDialog}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            backgroundColor: theme.palette.background.paper,
          }
        }}
      >
        <DialogTitle sx={{ color: theme.palette.text.primary }}>
          {editingUser ? 'Edit User' : 'Create New User'}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
            <TextField
              label="Email"
              value={formData.email}
              onChange={(e) => setFormData({ ...formData, email: e.target.value })}
              disabled={!!editingUser}
              fullWidth
              InputProps={{
                sx: {
                  '& input': {
                    color: theme.palette.text.primary
                  }
                }
              }}
            />
            
            {editingUser && (
              <FormControl fullWidth>
                <InputLabel>Role</InputLabel>
                <Select
                  value={formData.role}
                  label="Role"
                  onChange={(e) => setFormData({ ...formData, role: e.target.value })}
                >
                  <MenuItem value="user">User</MenuItem>
                  <MenuItem value="admin">Admin</MenuItem>
                </Select>
              </FormControl>
            )}

            {editingUser && (
              <FormControlLabel
                control={
                  <Switch
                    checked={formData.is_active}
                    onChange={(e) => setFormData({ ...formData, is_active: e.target.checked })}
                  />
                }
                label="Active"
                sx={{ color: theme.palette.text.primary }}
              />
            )}

            {editingUser && !formData.pbc_jwt && (
              <Button 
                onClick={handleGeneratePublicLink}
                variant="contained"
                color="primary"
                disabled={isSubmitting}
              >
                Generate Public Link
              </Button>
            )}

            {editingUser && (
              <TextField
                label="Public Link"
                value={formData.pbc_jwt}
                disabled={true}
                fullWidth
                InputProps={{
                  sx: {
                    '& input': {
                      color: theme.palette.text.primary
                    }
                  }
                }}
              />
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={handleCloseDialog}
            disabled={isSubmitting}
          >
            Cancel
          </Button>
          <Button 
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <CircularProgress size={20} color="inherit" />
                {editingUser ? 'Updating...' : 'Creating...'}
              </Box>
            ) : (
              editingUser ? 'Update' : 'Create'
            )}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert 
          onClose={() => setSnackbar({ ...snackbar, open: false })} 
          severity={snackbar.severity}
          sx={{ 
            width: '100%',
            backgroundColor: theme.palette.mode === 'dark' 
              ? theme.palette.grey[900] 
              : undefined
          }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default UserPage;