import React, { useState, useRef } from 'react';
import {
  Typography,
  Box,
  CircularProgress,
  Alert,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
  Tooltip,
  Chip,
  Collapse,
  CardContent,
  Grid,
  Card,
  useTheme
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { handleClaudeSubmit } from '../assistant-forms//modules/custom_claude_sonnet_3.5_module';
import { handleOpenAISubmit, validateFiles } from '../assistant-forms//modules/custom_openai_4o_module';
import AnalysisResultsDisplay from './AnalysisResultsDisplay';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import FrameworkService from '../services/framework_service';

import ISOFrameworkMapping from '../assistant-forms/ISO_4_10_framework_mapping';
import ISOAnnexAFrameworkMapping from '../assistant-forms/ISO_annex_a_framework_mapping';
import CustomFrameworkMapping from '../assistant-forms/custom_audit_checklist';
import NISTFrameworkMapping from '../assistant-forms/nist_framework_mapping';
import HeinekenFrameworkMapping from '../assistant-forms/Heineken_framework_mapping';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ShareIcon from '@mui/icons-material/Share';
import { WarningOutlined } from '@mui/icons-material';
import PostAddIcon from '@mui/icons-material/PostAdd';

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.background.paper,
  '& .MuiCardContent-root': {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));

const CustomFileInput = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
}));

const HiddenInput = styled('input')({
  display: 'none',
});

const FileInputButton = styled(Button)(({ theme }) => ({
  width: '100%',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.background.paper,
  color: theme.palette.text.primary,
  border: `1px solid ${theme.palette.primary.main}`,
  '&:hover': {
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : theme.palette.grey[100],
  },
}));

const FileNameDisplay = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1),
  color: theme.palette.text.secondary,
}));

const ControlCard = ({
  control,
  controls,
  controlDetails,
  instance,
  onAnalysisComplete,
  selectedAI,
  userInfo,
  openShareDialog,
  fetchInstances
}) => {
  const theme = useTheme();
  const possibleResult = instance.results.find(result => result.control === control);
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [analysisResult, setAnalysisResult] = useState(possibleResult?.ai_result);
  const [isExpanded, setIsExpanded] = useState(false);
  const fileInputRef = useRef(null);
  const [groupDialogOpen, setGroupDialogOpen] = useState(false);
  const [selectedGroupResult, setSelectedGroupResult] = useState(null);
  const [groupValue, setGroupValue] = useState('');
  const [isGrouping, setIsGrouping] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  var testingStatus = instance.controlTestingStatus[control];
  var reviewStatus = instance.controlReviewStatus[control];
  if (!testingStatus) {
    testingStatus = "NOT_STARTED";
  }
  if (!reviewStatus) {
    reviewStatus = "NOT_STARTED";
  }

  const handleGroupUpdate = async (framework_uuid, control) => {
    try {
      setIsGrouping(true);
      await FrameworkService.updateControlGroup(framework_uuid, control, groupValue);
      setSnackbar({
        open: true,
        message: 'Group updated successfully',
        severity: 'success'
      });
      setGroupDialogOpen(false);
      setGroupValue('');
      setSelectedGroupResult(null);
      fetchInstances();
    } catch (err) {
      setSnackbar({
        open: true,
        message: err.message || 'Failed to update group',
        severity: 'error'
      });
    } finally {
      setIsGrouping(false);
    }
  };
  
  const openGroupDialog = (result) => {
    setSelectedGroupResult(result);
    setGroupValue(result?.group || '');
    setGroupDialogOpen(true);
  };

  const getFrameworkMapping = (type) => {
    switch (type) {
      case 'ISO 27001 Annex A':
        return ISOAnnexAFrameworkMapping;
      case 'ISO 27001 Clause 4 to 10':
        return ISOFrameworkMapping;
      case 'Custom':
        return CustomFrameworkMapping;
      case 'NIST':
        return NISTFrameworkMapping;
      case 'Heineken':
        return HeinekenFrameworkMapping;
      default:
        return {};
    }
  };

  const getStatusColor = (status) => {
    switch (status?.toUpperCase()) {
      case 'COMPLETE':
        return 'success';
      case 'IN_PROGRESS':
        return 'primary';
      case 'BLOCKED':
        return 'error';
      case 'NOT_STARTED':
      default:
        return 'default';
    }
  };

  const StatusChip = ({ status }) => {
    const isReject = status === 'REJECT';
    return (
      <Chip
        icon={isReject ? <ErrorOutlineIcon /> : status === 'WARNING' ? <WarningOutlined /> : <CheckCircleOutlineIcon />}
        label={status}
        color={isReject ? 'error' : status === 'WARNING' ? 'warning' : 'success'}
        variant="outlined"
        size="small"
      />
    );
  };

  const handleFileChange = (event) => {
    const newFiles = Array.from(event.target.files);

    if (selectedAI === 'openai') {
      const { isValid, errors } = validateFiles(newFiles);
      if (!isValid) {
        setError(errors.join('\n'));
        return;
      }
    }

    setFiles(newFiles);
    setError(null);
  };

  const setResult = async (analysisResult, filename = "No Filename") => {
    const controlsToAdd = [];
    const selectedControl = getFrameworkMapping(instance.type)[control]
    if (selectedControl) {
      const overallAcceptControls = selectedControl.overallAccept ? selectedControl.overallAccept : [];
      const overallWarningRejectControls = selectedControl.overallWarningReject ? selectedControl.overallWarningReject : [];
      if (analysisResult.overallAssessment == "ACCEPT") {
        controlsToAdd.push(...overallAcceptControls);
      }
      else if (analysisResult.overallAssessment == "WARNING") {
        controlsToAdd.push(...overallWarningRejectControls);
      }
      else if (analysisResult.overallAssessment == "REJECT"){
        controlsToAdd.push(...overallWarningRejectControls);
      }
    }
    await FrameworkService.saveAnalysisResult(analysisResult, control, instance.uuid, null, filename, controlsToAdd);
    setAnalysisResult(analysisResult);
  };

const getControlFrequencyColor = (frequency) => {
  switch (frequency?.toLowerCase()) {
    case 'continuous':
      return 'success';
    case 'monthly':
      return 'primary';
    case 'quarterly':
      return 'warning';
    case 'annually':
      return 'error';
    default:
      return 'default';
  }
};

  const handleAnalyze = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const modifiedControl = {
        ...controlDetails,
        id: control
      };

      const response = selectedAI === 'claude'
        ? await handleClaudeSubmit(files, control, { [control]: modifiedControl }, setResult)
        : await handleOpenAISubmit(files, control, { [control]: modifiedControl }, setResult);
      if (onAnalysisComplete) {
        onAnalysisComplete(control, response);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const renderFileNames = (files) => {
    if (files.length === 0) return 'No file chosen';
    return files.map(file => file.name).join(', ');
  };

  return (
    <Grid item xs={12} md={controls.length > 1 ? 6 : 12}> 
      <StyledCard>
        <CardContent className="pt-4 pb-2">
          <div className="flex items-center gap-4 mb-2">
            <Typography variant="h5" className="flex-grow">
              {control}
              <Chip
                label={controlDetails.frequency}
                color={getControlFrequencyColor(controlDetails.frequency)}
                size="small"
                sx={{ ml: 1, mr: 1 }}
              />
              {analysisResult?.overallAssessment && <StatusChip status={analysisResult?.overallAssessment} />}
              
              <Tooltip title="Testing Status">
                <Chip
                  size="small"
                  label={testingStatus?.replace('_', ' ') || 'NOT STARTED'}
                  color={getStatusColor(testingStatus)}
                  sx={{ ml: 1, mr: 1 }}
                />
              </Tooltip>
              <Tooltip title="Review Status">
                <Chip
                  size="small"
                  label={reviewStatus?.replace('_', ' ') || 'NOT STARTED'}
                  color={getStatusColor(reviewStatus)}
                  sx={{ ml: 1, mr: 1 }}
                />
              </Tooltip>
              {instance.created_by === userInfo?.email && !userInfo.public_email && (
              <>
                <IconButton
                  size="small"
                  onClick={() => openShareDialog(instance, control)}
                  title="Share Control"
                  sx={{ color: theme.palette.text.primary }}
                >
                  <ShareIcon />
                </IconButton>
                <IconButton
                  size="small"
                  onClick={() => openGroupDialog(analysisResult)}
                  title="Edit Group"
                  sx={{ color: theme.palette.text.primary }}
                >
                  <PostAddIcon />
                </IconButton>
              </>
              )}
              <IconButton
                onClick={() => setIsExpanded(!isExpanded)}
              >
                {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            </Typography>

            <Typography variant="h7" className="flex-grow">
              {controlDetails.title}
            </Typography>
          </div>

          <Collapse in={isExpanded}>
            <div>
              <Typography variant="body2" color="text.secondary" className="mb-4" sx={{ mt: 2, mb: 2 }}>
                {controlDetails.description}
              </Typography>

              {/* Analysis Results */}
              {instance.results && (
                <AnalysisResultsDisplay
                  results={
                    instance.results.filter(result => {
                      return result.control === control;
                    })
                  }
                  fetchInstances={fetchInstances}
                  instance={instance}
                  control={control}
                />
              )}

              {/* File Upload Section */}
              <Box className="mt-4">
                <CustomFileInput>
                  <HiddenInput
                    type="file"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    multiple
                  />
                  <FileInputButton
                    variant="outlined"
                    sx={{ mt: 2 }}
                    component="span"
                    onClick={() => fileInputRef.current.click()}
                  >
                    Upload Evidence
                  </FileInputButton>
                  <FileNameDisplay variant="body2">
                    {renderFileNames(files)}
                  </FileNameDisplay>
                </CustomFileInput>

                {error && (
                  <Alert severity="error" className="mt-2">
                    {error}
                  </Alert>
                )}

                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={handleAnalyze}
                  disabled={files.length === 0 || isLoading}
                  className="mt-2"
                  sx={{ mt: 2 }}
                >
                  {isLoading ? <CircularProgress size={24} /> : 'Analyze Evidence'}
                </Button>
              </Box>
            </div>
          </Collapse>
        </CardContent>
      </StyledCard>
      <Dialog
        open={groupDialogOpen}
        onClose={() => {
          setGroupDialogOpen(false);
          setGroupValue('');
          setSelectedGroupResult(null);
        }}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            backgroundColor: theme.palette.background.paper,
          }
        }}
      >
        <DialogTitle sx={{ color: theme.palette.text.primary }}>
          Edit Group
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2 }}>
            <TextField
              label="Group Name"
              fullWidth
              value={groupValue}
              onChange={(e) => setGroupValue(e.target.value)}
              disabled={isGrouping}
              helperText="Enter a group name for this control"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setGroupDialogOpen(false);
              setGroupValue('');
              setSelectedGroupResult(null);
            }}
            disabled={isGrouping}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {handleGroupUpdate(instance.uuid, control)}}
            variant="contained"
            color="primary"
            disabled={isGrouping}
          >
            {isGrouping ? (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <CircularProgress size={20} color="inherit" />
                Updating...
              </Box>
            ) : (
              'Update'
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default ControlCard;