import { v4 as uuidv4 } from 'uuid';

const ACTIVITY_TYPES = {
  FRAMEWORK_CREATED: 'FRAMEWORK_CREATED',
  FRAMEWORK_UPDATED: 'FRAMEWORK_UPDATED',
  FRAMEWORK_SHARED: 'FRAMEWORK_SHARED',
  CONTROL_ANALYZED: 'CONTROL_ANALYZED',
  EVIDENCE_UPLOADED: 'EVIDENCE_UPLOADED',
  STATUS_CHANGED: 'STATUS_CHANGED',
  USER_JOINED: 'USER_JOINED'
};

const FRAMEWORK_TYPES = ['NIST', 'ISO 27001 Annex A', 'ISO 27001 Clause 4 to 10', 'Custom', 'Heineken'];
const USERS = ['john.doe@company.com', 'alice.smith@company.com', 'bob.jones@company.com', 'emma.wilson@company.com'];
const CONTROLS = ['AC-1', 'AC-2', 'AC-3', 'SC-1', 'SC-2', 'IA-1', 'IA-2', 'CM-1', 'CM-2', 'SI-1'];

const config = {
  host: process.env.REACT_APP_API_HOST || 'https://oop8dv9jfj.execute-api.us-east-1.amazonaws.com',
};

class ActivityService {
  static getRandomElement(array) {
    return array[Math.floor(Math.random() * array.length)];
  }

  static getRandomDate(daysBack = 30) {
    const date = new Date();
    date.setDate(date.getDate() - Math.floor(Math.random() * daysBack));
    return date.toISOString();
  }

  static getActivityDescription(type, details) {
    switch (type) {
      case ACTIVITY_TYPES.FRAMEWORK_CREATED:
        return `Created new ${details.frameworkType} framework "${details.title}"`;
      case ACTIVITY_TYPES.FRAMEWORK_UPDATED:
        return `Updated framework "${details.title}"`;
      case ACTIVITY_TYPES.FRAMEWORK_SHARED:
        return `Shared framework "${details.title}" with ${details.sharedWith}`;
      case ACTIVITY_TYPES.CONTROL_ANALYZED:
        return `Analyzed control ${details.control} with ${details.result} result`;
      case ACTIVITY_TYPES.EVIDENCE_UPLOADED:
        return `Uploaded evidence for control ${details.control}`;
      case ACTIVITY_TYPES.STATUS_CHANGED:
        return `Changed status of "${details.title}" to ${details.status}`;
      case ACTIVITY_TYPES.USER_JOINED:
        return `New user ${details.user} joined the platform`;
      default:
        return 'Unknown activity';
    }
  }

  static generateMockActivity(count = 20) {
    const activities = [];

    for (let i = 0; i < count; i++) {
      const type = this.getRandomElement(Object.values(ACTIVITY_TYPES));
      const user = this.getRandomElement(USERS);
      let details;

      switch (type) {
        case ACTIVITY_TYPES.FRAMEWORK_CREATED:
          details = {
            frameworkType: this.getRandomElement(FRAMEWORK_TYPES),
            title: `${this.getRandomElement(FRAMEWORK_TYPES)} Compliance ${uuidv4().slice(0, 8)}`
          };
          break;
        case ACTIVITY_TYPES.FRAMEWORK_UPDATED:
          details = {
            title: `${this.getRandomElement(FRAMEWORK_TYPES)} Compliance ${uuidv4().slice(0, 8)}`
          };
          break;
        case ACTIVITY_TYPES.FRAMEWORK_SHARED:
          details = {
            title: `${this.getRandomElement(FRAMEWORK_TYPES)} Compliance ${uuidv4().slice(0, 8)}`,
            sharedWith: this.getRandomElement(USERS)
          };
          break;
        case ACTIVITY_TYPES.CONTROL_ANALYZED:
          details = {
            control: this.getRandomElement(CONTROLS),
            result: this.getRandomElement(['ACCEPT', 'REJECT', 'WARNING'])
          };
          break;
        case ACTIVITY_TYPES.EVIDENCE_UPLOADED:
          details = {
            control: this.getRandomElement(CONTROLS)
          };
          break;
        case ACTIVITY_TYPES.STATUS_CHANGED:
          details = {
            title: `${this.getRandomElement(FRAMEWORK_TYPES)} Compliance ${uuidv4().slice(0, 8)}`,
            status: this.getRandomElement(['IN_PROGRESS', 'COMPLETE', 'BLOCKED'])
          };
          break;
        case ACTIVITY_TYPES.USER_JOINED:
          details = {
            user: this.getRandomElement(USERS)
          };
          break;
      }

      activities.push({
        id: uuidv4(),
        type,
        user,
        timestamp: this.getRandomDate(),
        description: this.getActivityDescription(type, details),
        details
      });
    }

    // Sort by timestamp descending (most recent first)
    return activities.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
  }

  static getRecentActivity() {
    return Promise.resolve(this.generateMockActivity(20));
  }
  // static getRecentActivity() {
  //   return new Promise((resolve, reject) => {
  //     const xhr = new XMLHttpRequest();
  //     xhr.open('GET', `${config.host}/activity`, true);
  //     xhr.withCredentials = true;
  //     xhr.setRequestHeader('Content-Type', 'application/json');
  //     xhr.setRequestHeader('auth_token', localStorage.getItem('auth_token'));

  //     xhr.onload = function() {
  //       if (xhr.status >= 200 && xhr.status < 300) {
  //         try {
  //           const data = JSON.parse(xhr.responseText);
  //           resolve({
  //             activity: data.activity
  //           });
  //         } catch (error) {
  //           reject(new Error('Failed to parse response data'));
  //         }
  //       } else {
  //         try {
  //           const errorData = JSON.parse(xhr.responseText);
  //           reject(new Error(errorData.message || 'Authentication failed'));
  //         } catch (error) {
  //           reject(new Error('Authentication failed'));
  //         }
  //       }
  //     };

  //     xhr.onerror = function() {
  //       reject(new Error('Failed to authenticate'));
  //     };

  //     xhr.send();
  //   });
  // }
}

export default ActivityService;