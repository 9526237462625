const ISOAnnexAFrameworkMapping = {
    "A.5.1": {
        "title": "Information Security Policies",
        "description": "Are all necessary information security policies approved by management and published?\nAre all information security policies reviewed and updated?",
        "testSteps": "Requirement: Are all necessary information security policies approved by management and published?\nStep 1: Review the list of all necessary information security policies.\nStep 2: Verify evidence of management approval and dissemination.\nRequirement: Are all information security policies reviewed and updated?\nStep 1: Check the policy review schedule for adherence to planned updates.\nStep 2: Validate that policies reflect current risks, regulations, and business needs.",
        "frequency": "Annually"
    },
    "A.5.2": {
        "title": "Information Security Responsibilities",
        "description": "Are all information security responsibilities clearly defined through one or several documents?",
        "testSteps": "Step 1: Review organizational charts or responsibility matrices defining security roles.\nStep 2: Verify that responsibilities are documented and communicated effectively.",
        "frequency": "Annually"
    },
    "A.5.3": {
        "title": "Conflict of Interest",
        "description": "Are duties and responsibilities defined in such a way to avoid conflict of interest, particularly with the information and systems where high risks are involved?",
        "testSteps": "Step 1: Assess segregation of duties for roles with high-risk access.\nStep 2: Verify controls in place to mitigate potential conflicts of interest.",
        "frequency": "Annually"
    },
    "A.5.4": {
        "title": "Compliance with Information Security Rules",
        "description": "Is management actively requiring all employees and contractors to comply with information security rules?",
        "testSteps": "Step 1: Review evidence of management directives or communications enforcing compliance.\nStep 2: Verify training records and disciplinary actions to assess compliance enforcement.",
        "frequency": "Annually"
    },
    "A.5.5": {
        "title": "Contact with Authorities",
        "description": "Is it clearly defined who should be in contact with which authorities?",
        "testSteps": "Step 1: Review documented procedures or policies identifying contact roles.\nStep 2: Verify the existence of designated points of contact and corresponding records of communication.",
        "frequency": "Annually"
    },
    "A.5.6": {
        "title": "AContact with Special Interest Groups",
        "description": "Is it clearly defined who should be in contact with special interest groups or professional associations?",
        "testSteps": "Step 1: Request evidence of defined roles for engagement with external groups.\nStep 2: Verify records of participation or interaction with such groups.",
        "frequency": "Annually"
    },
    "A.5.7": {
        "title": "Threat Intelligence",
        "description": "Are information security threats collected and analyzed in order to produce threat intelligence?",
        "testSteps": "Step 1: Review the threat intelligence process, including data collection and analysis methods.\nStep 2: Verify evidence of ongoing threat monitoring and reporting.\nStep 3: Confirm the integration of threat intelligence into risk management and controls.",
        "frequency": "Annually"
    },
    "A.5.8": {
        "title": "Information Security in Projects",
        "description": "Are information security rules included in every project? \nAre security requirements defined for new information systems, or for any changes to them?",
        "testSteps": "Requirement: Are information security rules included in every project?\nStep 1: Review project management frameworks or guidelines to identify embedded security rules.\nStep 2: Verify that security rules are considered in project initiation documents.\nStep 3: Assess the documentation of at least three ongoing or completed projects to confirm inclusion of security rules.\nRequirement: Are security requirements defined for new information systems or changes to existing ones?\nStep 1: Request evidence of defined security requirements for new systems or system changes.\nStep 2: Verify integration of these requirements into the system development lifecycle (SDLC).\nStep 3: Confirm sign-off on security requirements during project milestones.",
        "frequency": "Annually"
    },
    "A.5.9": {
        "title": "Asset Ownership",
        "description": "Does every asset in the Inventory of assets have a designated owner?",
        "testSteps": "Step 1: Review the Inventory of Assets to ensure each asset is assigned an owner.\nStep 2: Verify the responsibilities of asset owners for maintaining and securing the assets.\nStep 3: Confirm that ownership is regularly reviewed and updated as part of asset management processes.",
        "frequency": "Annually"
    },
    "A.5.10": {
        "title": "Handling Information and Assets",
        "description": "Are the rules for the appropriate handling of information and assets documented?\nAre there procedures that define how to handle classified information?",
        "testSteps": "Requirement: Are the rules for the appropriate handling of information and assets documented?\nStep 1: Review documented procedures for handling sensitive information and assets.\nStep 2: Validate that these procedures align with organizational classification policies.\nStep 3: Confirm staff awareness through training records or employee interviews.\nRequirement: Are there procedures that define how to handle classified information?\nStep 1: Examine the organization's classification guidelines.\nStep 2: Verify alignment with regulatory or contractual requirements.\nStep 3: Assess compliance through sample classification documentation or audits.",
        "frequency": "Quarterly"
    },
    "A.5.11": {
        "title": "Asset Return",
        "description": "Did all the employees and contractors return all the company assets when their employment was terminated?",
        "testSteps": "Step 1: Review the offboarding process documentation.\nStep 2: Verify the existence of a checklist or procedure for asset return.\nStep 3: Confirm evidence of asset return for terminated employees/contractors.",
        "frequency": "Annually"
    },
    "A.5.12": {
        "title": "Information Classification",
        "description": "Is the information classified according to specified criteria?",
        "testSteps": "Step 1: Review the classification policy for criteria and applicability.\nStep 2: Verify the implementation of classification through a sample audit of classified documents.\nStep 3: Confirm that classification criteria are consistently applied across departments.",
        "frequency": "Annually"
    },
    "A.5.13": {
        "title": "Information Labeling",
        "description": "Is the classified information labeled according to the defined procedures?",
        "testSteps": "Step 1: Request labeled documents to verify compliance with labeling procedures.\nStep 2: Confirm labeling aligns with the organization's classification and security policies.\nStep 3: Review training records to ensure employees are aware of labeling standards.",
        "frequency": "Annually"
    },
    "A.5.14": {
        "title": "Protection of Information Transfer",
        "description": "Is the protection of information transfer regulated in formal policies and procedures?\nDo agreements with third parties exist that regulate the security of information transfer?\nAre the messages that are exchanged over the networks properly protected?",
        "testSteps": "Requirement: Is the protection of information transfer regulated in formal policies and procedures?\nStep 1: Review policies and procedures for protecting information during transfer.\nStep 2: Verify encryption and other protective measures for data in transit.\nRequirement: Do agreements with third parties regulate the security of information transfer?\nStep 1: Examine contracts or agreements with third parties for clauses related to information transfer security.\nStep 2: Confirm that requirements align with organizational policies.\nRequirement: Are messages exchanged over networks properly protected?\nStep 1: Request evidence of protection mechanisms, such as encryption or secure messaging tools.\nStep 2: Verify that network monitoring systems are in place to detect unauthorized access.",
        "frequency": "Annually"
    },
    "A.5.15": {
        "title": "Access Control Policy",
        "description": "Is there an Access control policy that defines business and security requirements for access control?\nDo the users have access only to those networks and services they are specifically authorized for?",
        "testSteps": "Requirement: Is there an Access Control Policy that defines business and security requirements for access control?\nStep 1: Review the Access Control Policy to ensure it includes both business and security requirements.\nStep 2: Validate implementation by auditing access permissions in IT systems.\nRequirement: Do users have access only to those networks and services they are specifically authorized for?\nStep 1: Conduct a sample audit of user access rights to confirm least privilege.\nStep 2: Verify periodic reviews of access permissions by asset or system owners.",
        "frequency": "Annually"
    },
    "A.5.16": {
        "title": "Access Rights Registration",
        "description": "Are access rights provided via a formal registration process?",
        "testSteps": "Step 1: Review the access request and approval process documentation.\nStep 2: Verify that access rights are granted only after formal approval.\nStep 3: Confirm that a log of access rights changes is maintained.",
        "frequency": "Annually"
    },
    "A.5.17": {
        "title": "Password and Authentication Information",
        "description": "Are initial passwords and other secret authentication information provided securely?\nAre there clear rules for users on how to protect passwords and other authentication information?\nAre the systems that manage passwords interactive, and enable the creation of secure passwords?",
        "testSteps": "Requirement: Are initial passwords and other secret authentication information provided securely?\nStep 1: Review the procedures for distributing initial passwords or authentication credentials.\nStep 2: Verify the use of secure channels for communication (e.g., encrypted emails, secure portals).\nRequirement: Are there clear rules for users on how to protect passwords and other authentication information?\nStep 1: Review the organization's password policy for guidance on password protection.\nStep 2: Confirm that users receive regular training or reminders about password security.\nRequirement: Are the systems that manage passwords interactive and enable the creation of secure passwords?\nStep 1: Evaluate password management systems to ensure compliance with secure password standards (e.g., length, complexity).\nStep 2: Test functionality for secure password generation and validation.",
        "frequency": "Annually"
    },
    "A.5.18": {
        "title": "Access Control System",
        "description": "Is there a formal access control system when logging into information systems?\nDo asset owners periodically check all the privileged access rights?\nHave the access rights of all employees and contractors been removed upon the termination of their contracts?",
        "testSteps": "Requirement: Is there a formal access control system when logging into information systems?\nStep 1: Review the access control policies and procedures for logging into systems.\nStep 2: Verify the implementation of controls such as multi-factor authentication (MFA) or single sign-on (SSO).\nStep 3: Assess a sample of user logs to confirm compliance with the access control system.\nRequirement: Do asset owners periodically check all the privileged access rights?\nStep 1: Review records of privileged access reviews conducted by asset owners.\nStep 2: Verify frequency and completeness of these reviews.\nStep 3: Validate corrective actions for any anomalies identified during the review.\nRequirement: Have the access rights of all employees and contractors been removed upon the termination of their contracts?\nStep 1: Review the offboarding process for revoking access rights.\nStep 2: Validate that terminated employees and contractors no longer have active access.\nStep 3: Audit access logs to ensure compliance.",
        "frequency": "Annually"
    },
    "A.5.19": {
        "title": "Supplier and Partner Risk Policy",
        "description": "Is the policy on how to treat the risks related to suppliers and partners documented?",
        "testSteps": "Step 1: Review the policy governing supplier and partner risks.\nStep 2: Verify inclusion of risk management strategies, criteria, and monitoring processes.\nStep 3: Confirm that the policy is regularly reviewed and updated.",
        "frequency": "Annually"
    },
    "A.5.20": {
        "title": "Security Requirements in Supplier Agreements",
        "description": "Are all the relevant security requirements included in the agreements with the suppliers and partners?",
        "testSteps": "Step 1: Examine contracts or SLAs with suppliers and partners.\nStep 2: Verify that security requirements align with organizational and regulatory standards.\nStep 3: Ensure evidence of review and approval for these agreements.",
        "frequency": "Annually"
    },
    "A.5.21": {
        "title": "Cloud Provider Agreements",
        "description": "Do the agreements with cloud providers and other suppliers include security requirements for ensuring the reliable delivery of services?",
        "testSteps": "Step 1: Review agreements with cloud providers for security clauses.\nStep 2: Confirm alignment with organizational risk and security policies.\nStep 3: Validate compliance through monitoring or third-party audits.",
        "frequency": "Monthly"
    },
    "A.5.22": {
        "title": "Supplier Compliance Monitoring",
        "description": "Are suppliers regularly monitored for compliance with the security requirements, and audited if appropriate?\nWhen making changes to arrangements and contracts with suppliers and partners, are risks and existing processes taken into account?",
        "testSteps": "Requirement: Are suppliers regularly monitored for compliance with security requirements, and audited if appropriate?\nStep 1: Review the monitoring and audit schedule for suppliers.\nStep 2: Verify evidence of completed supplier audits or compliance checks.\nStep 3: Confirm corrective actions for non-compliance issues.\nRequirement: When making changes to arrangements and contracts with suppliers and partners, are risks and existing processes taken into account?\nStep 1: Request documentation of changes made to supplier contracts.\nStep 2: Verify the assessment of risks related to these changes.\nStep 3: Confirm that processes were updated to reflect any new security requirements.",
        "frequency": "Annually"
    },
    "A.5.23": {
        "title": "Cloud Services Security",
        "description": "Are processes for acquisition, use, management, and exit from cloud services compliant with identified security requirements?",
        "testSteps": "Step 1: Review processes for managing cloud services at each stage (acquisition to exit).\nStep 2: Validate alignment of these processes with identified security requirements.\nStep 3: Confirm evidence of audits or reviews of cloud service usage and compliance.",
        "frequency": "Annually"
    },
    "A.5.24": {
        "title": "Incident Management",
        "description": "Are procedures and responsibilities for managing incidents clearly defined?",
        "testSteps": "Step 1: Review the incident management policy and associated procedures.\nStep 2: Verify the roles and responsibilities assigned for incident handling.\nStep 3: Assess training records to confirm awareness of incident management procedures.",
        "frequency": "Annually"
    },
    "A.5.25": {
        "title": "Security Event Classification",
        "description": "Are all security events assessed and classified?",
        "testSteps": "Step 1: Review the process for assessing and classifying security events.\nStep 2: Verify that classifications align with organizational policies and risk levels.\nStep 3: Validate evidence of classifications applied to recent events.",
        "frequency": "Annually"
    },
    "A.5.26": {
        "title": "Incident Response Procedures",
        "description": "Are procedures on how to respond to incidents documented?",
        "testSteps": "Step 1: Review documented procedures for incident response.\nStep 2: Verify the inclusion of escalation paths, communication protocols, and recovery steps.\nStep 3: Confirm compliance through incident response drills or reports.",
        "frequency": "Annually"
    },
    "A.5.27": {
        "title": "Incident Analysis",
        "description": "Are security incidents analyzed in order to gain knowledge on how to prevent them?",
        "testSteps": "Step 1: Request analysis reports for past security incidents.\nStep 2: Verify that lessons learned are documented and shared with relevant teams.\nStep 3: Assess evidence of corrective actions or improvements implemented post-analysis.",
        "frequency": "Annually"
    },
    "A.5.28": {
        "title": "Evidence Collection for Legal Process",
        "description": "Do procedures exist that define how to collect incident evidence that will be acceptable during the legal process?",
        "testSteps": "Step 1: Review the procedures for collecting and preserving incident evidence.\nStep 2: Verify compliance with legal standards and admissibility criteria.\nStep 3: Confirm that evidence collection is documented in incident handling records.",
        "frequency": "Annually"
    },
    "A.5.29": {
        "title": "Continuity of Information Security",
        "description": "Are requirements for continuity of information security defined?\nDo procedures exist that ensure the continuity of information security during a crisis or a disaster?\nIs exercising and testing performed in order to ensure effective response?",
        "testSteps": "Requirement: Are requirements for continuity of information security defined?\nStep 1: Review documentation detailing the continuity requirements for information security.\nStep 2: Verify alignment with organizational objectives and risk assessments.\nStep 3: Validate stakeholder involvement in defining these requirements.\nRequirement: Do procedures exist that ensure the continuity of information security during a crisis or a disaster?\nStep 1: Review business continuity and disaster recovery plans.\nStep 2: Confirm procedures for maintaining information security during crises.\nStep 3: Assess a sample of incidents to verify procedural compliance.\nRequirement: Is exercising and testing performed to ensure an effective response?\nStep 1: Request records of continuity exercises or tests.\nStep 2: Verify the scope and realism of these exercises.\nStep 3: Confirm post-exercise reviews and improvements.",
        "frequency": "Annually"
    },
    "A.5.30": {
        "title": "ICT Readiness",
        "description": "Is ICT readiness planned, implemented, maintained, and tested based on business and ICT continuity requirements?",
        "testSteps": "Step 1: Review the ICT continuity plan for alignment with business requirements.\nStep 2: Verify maintenance logs for ICT infrastructure readiness.\nStep 3: Confirm the execution of readiness tests and associated findings.",
        "frequency": "Monthly"
    },
    "A.5.31": {
        "title": "Security Requirements Documentation",
        "description": "Are all legislative, regulatory, contractual, and other security requirements listed and documented?",
        "testSteps": "Step 1: Review the inventory of security requirements (e.g., legal, regulatory).\nStep 2: Verify periodic updates to this inventory.\nStep 3: Confirm compliance through internal or external audits.",
        "frequency": "Annually"
    },
    "A.5.32": {
        "title": "Intellectual Property Rights",
        "description": "Do procedures exist that ensure the enforcement of intellectual property rights, including the use of licensed software?",
        "testSteps": "Step 1: Review procedures for managing intellectual property and software licenses.\nStep 2: Verify that compliance checks for software licenses are regularly performed.\nStep 3: Confirm the enforcement of intellectual property rights through logs or audit records.",
        "frequency": "Annually"
    },
    "A.5.33": {
        "title": "Record Protection",
        "description": "Are all the records protected according to identified regulatory, contractual, and other requirements?",
        "testSteps": "Step 1: Review policies and procedures for record protection.\nStep 2: Verify access controls and storage measures for sensitive records.\nStep 3: Confirm compliance through sampling or audits.",
        "frequency": "Annually"
    },
    "A.5.34": {
        "title": "Personally Identifiable Information (PII)",
        "description": "Is personally identifiable information protected as required in laws and regulations?",
        "testSteps": "Step 1: Review data protection policies and procedures.\nStep 2: Verify implementation of encryption, access controls, and other PII safeguards.\nStep 3: Assess compliance through data protection audits.",
        "frequency": "Quarterly"
    },
    "A.5.35": {
        "title": "Independent Audits",
        "description": "Is information security regularly reviewed by an independent auditor?",
        "testSteps": "Step 1: Review the schedule and scope of independent security audits.\nStep 2: Confirm audit completion and review findings or recommendations.\nStep 3: Verify the implementation of corrective actions.",
        "frequency": "Annually"
    },
    "A.5.36": {
        "title": "Managerial and System Reviews",
        "description": "Do the managers regularly review if the security policies and procedures are performed properly in their areas of responsibility?\nAre information systems regularly reviewed to check their compliance with the information security policies and standards?",
        "testSteps": "Requirement: Do managers regularly review if the security policies and procedures are performed properly in their areas of responsibility?\nStep 1: Review evidence of managerial oversight, such as meeting minutes or review logs.\nStep 2: Verify corrective actions or updates arising from these reviews.\nRequirement: Are information systems regularly reviewed to check their compliance with the information security policies and standards?\nStep 1: Examine system audit logs and compliance check reports.\nStep 2: Verify follow-up actions for non-compliance issues.",
        "frequency": "Annually"
    },
    "A.5.37": {
        "title": "Operating Procedures",
        "description": "Have the operating procedures for IT processes been documented?",
        "testSteps": "Step 1: Review documented operating procedures for IT processes.\nStep 2: Verify that the procedures align with security and operational requirements.\nStep 3: Confirm employee awareness and training on these procedures.",
        "frequency": "Annually"
    },
    "A.6.1": {
        "title": "Background Checks",
        "description": "Are background checks performed on candidates for employment or for contractors?",
        "testSteps": "Step 1: Review the organization’s background check policy.\nStep 2: Verify records of background checks conducted for employees and contractors.\nStep 3: Confirm compliance with local legal and regulatory requirements.",
        "frequency": "Annually"
    },
    "A.6.2": {
        "title": "Information Security Responsibilities in Agreements",
        "description": "Do the agreements with employees and contractors specify the information security responsibilities?",
        "testSteps": "Step 1: Review employment and contractor agreements for clauses on information security responsibilities.\nStep 2: Verify that these responsibilities align with the organization's policies.\nStep 3: Confirm employee and contractor acknowledgment of these terms.",
        "frequency": "Annually"
    },
    "A.6.3": {
        "title": "Security Training and Awareness",
        "description": "Are all relevant employees and contractors being trained to perform their security duties, and do the awareness programs exist?",
        "testSteps": "Step 1: Review the training plan and schedule for security awareness programs.\nStep 2: Verify attendance records and training completion certificates for employees and contractors.\nStep 3: Confirm the effectiveness of training through feedback surveys or follow-up quizzes.",
        "frequency": "Annually"
    },
    "A.6.4": {
        "title": "Disciplinary Process for Security Breaches",
        "description": "Have all employees who have committed a security breach been subject to a formal disciplinary process?",
        "testSteps": "Step 1: Review records of reported security breaches and the corresponding disciplinary actions.\nStep 2: Verify that actions taken align with the organization's disciplinary policy.\nStep 3: Confirm that breaches and outcomes are documented and communicated where appropriate.",
        "frequency": "Annually"
    },
    "A.6.5": {
        "title": "Post-Employment Security Responsibilities",
        "description": "Are information security responsibilities that remain valid after the termination of employment defined in the agreement?",
        "testSteps": "Step 1: Examine employment termination agreements for clauses on post-employment responsibilities.\nStep 2: Verify that terminated employees are informed of their ongoing security obligations.\nStep 3: Assess compliance through legal acknowledgments or signed agreements.",
        "frequency": "Annually"
    },
    "A.6.6": {
        "title": "Confidentiality Clauses in Third-Party Agreements",
        "description": "Did the company list all the confidentiality clauses that need to be included in agreements with third parties?",
        "testSteps": "Step 1: Review third-party agreements for the presence of confidentiality clauses.\nStep 2: Confirm alignment with organizational policies and applicable legal standards.\nStep 3: Validate the periodic review of third-party agreements for updates.",
        "frequency": "Annually"
    },
    "A.6.7": {
        "title": "Remote Work Information Protection",
        "description": "Are there rules defining how the company information is protected while working remotely?",
        "testSteps": "Step 1: Review the policy or guidelines for remote work security.\nStep 2: Verify the implementation of protective measures such as VPN usage, device encryption, and access controls.\nStep 3: Confirm staff compliance through training records or remote work audits.",
        "frequency": "Annually"
    },
    "A.6.8": {
        "title": "Reporting Security Weaknesses and Events",
        "description": "Are employees and contractors reporting on security weaknesses and events?",
        "testSteps": "Step 1: Review the process for reporting security weaknesses and incidents.\nStep 2: Verify records of reported events and the actions taken.\nStep 3: Assess awareness and ease of use of the reporting process among employees and contractors.",
        "frequency": "Annually"
    },
    "A.7.1": {
        "title": "Secure Areas",
        "description": "Do secure areas that protect sensitive information exist?",
        "testSteps": "Step 1: Inspect secure areas for physical and environmental controls.\nStep 2: Verify alignment with the organization's physical security policy.\nStep 3: Confirm the proper designation and monitoring of secure areas",
        "frequency": "Annually"
    },
    "A.7.2.1": {
        "title": "Access Control to Secure Areas",
        "description": "Is the entrance to secure areas protected with controls that allow only the authorized persons to enter?",
        "testSteps": "Requirement: Is the entrance to secure areas protected with controls that allow only authorized persons to enter?\nStep 1: Review access control logs and systems for secure areas.\nStep 2: Verify the list of authorized personnel and access rights.\nStep 3: Test access control mechanisms, such as badge scanners or biometric systems.\n\nRequirement: Are delivery and loading areas controlled to prevent unauthorized access to company premises?\nStep 1: Inspect the layout and controls in place for delivery and loading areas.\nStep 2: Verify records of monitored deliveries and access logs.\nStep 3: Confirm compliance through spot checks or audits.",
        "frequency": "Annually"
    },
    "A.7.2.2": {
        "title": "Access Control to Secure Areas",
        "description": "Are delivery and loading areas controlled in such a way that unauthorized persons cannot enter the company premises?",
        "testSteps": "Step 1: Inspect the physical location of secure areas for visibility and accessibility.\nStep 2: Verify the implementation of protective barriers, fencing, or other measures.\nStep 3: Confirm compliance through environmental risk assessments.",
        "frequency": "Annually"
    },
    "A.7.3": {
        "title": "Location and Design of Secure Areas",
        "description": "Are secure areas located in such a way that they are not visible to outsiders, and not easily reached from the outside?",
        "testSteps": "Step 1: Review monitoring systems such as CCTV and alarm systems.\nStep 2: Verify retention periods and the accessibility of monitoring records.\nStep 3: Test the responsiveness of security personnel to unauthorized access scenarios.",
        "frequency": "Annually"
    },
    "A.7.4": {
        "title": "Monitoring for Unauthorized Access",
        "description": "Are premises monitored for unauthorized access?",
        "testSteps": "Step 1: Inspect the installation and functionality of alarm and fire protection systems.\nStep 2: Verify compliance with safety and regulatory standards.\nStep 3: Confirm periodic maintenance and testing of these systems.",
        "frequency": "Monthly"
    },
    "A.7.5": {
        "title": "Alarms and Fire Protection Systems",
        "description": "Are the alarms, fire protection, and other systems installed?",
        "testSteps": "Step 1: Review procedures for secure area access and usage.\nStep 2: Verify that employees are aware of and comply with these procedures.\nStep 3: Assess adherence through spot checks or audits.",
        "frequency": "Annually"
    },
    "A.7.6": {
        "title": "Procedures for Secure Areas",
        "description": "Are working procedures for secure areas defined and complied with?",
        "testSteps": "Step 1: Review the organization's policy on clearing desks and locking screens.\nStep 2: Verify compliance through spot checks or employee interviews.\nStep 3: Confirm training and awareness efforts related to these rules.",
        "frequency": "Annually"
    },
    "A.7.7": {
        "title": "Protection of Information in Secure Areas",
        "description": "Is there a rule that forces users to remove papers and media when not present, and lock their screens?",
        "testSteps": "Step 1: Review the organization's policy on clearing desks and locking screens.\nStep 2: Verify compliance through spot checks or employee interviews.\nStep 3: Confirm training and awareness efforts related to these rules.",
        "frequency": "Annually"
    },
    "A.7.8": {
        "title": "Equipment Protection",
        "description": "Is the equipment sited in such a way to protect it from unauthorized access, and from environmental threats?",
        "testSteps": "Step 1: Inspect the physical placement of equipment for unauthorized access prevention.\nStep 2: Verify measures to mitigate environmental threats (e.g., heat, humidity, flooding).\nStep 3: Confirm alignment with organizational policies on equipment protection.",
        "frequency": "Annually"
    },
    "A.7.9": {
        "title": "Asset Protection Outside Premises",
        "description": "Are the company assets adequately protected when they are not located at the company premises?",
        "testSteps": "Step 1: Review procedures for managing assets off-premises.\nStep 2: Verify safeguards such as encryption, secure transport, and access restrictions.\nStep 3: Validate compliance through a sample audit of assets used outside the premises.",
        "frequency": "Annually"
    },
    "A.7.10": {
        "title": "Handling and Disposal of Removable Media",
        "description": "Are the procedures that define how to handle removable media in line with the classification rules?\nAre there formal procedures for disposing of the media?",
        "testSteps": "Requirement: Are the procedures that define how to handle removable media in line with the classification rules?\nStep 1: Review policies governing the use and handling of removable media.\nStep 2: Verify that classification levels are consistently applied to media handling.\nStep 3: Confirm staff awareness through training records.\n\nRequirement: Are there formal procedures for disposing of the media?\nStep 1: Review disposal procedures for compliance with security and environmental policies.\nStep 2: Verify records of disposed media and methods used (e.g., shredding, degaussing).",
        "frequency": "Annually"
    },
    "A.7.11": {
        "title": "Uninterruptible Power Supply",
        "description": "Does the equipment have an uninterruptible power supply?",
        "testSteps": "Step 1: Inspect the availability and functionality of UPS systems for critical equipment.\nStep 2: Verify maintenance logs and testing schedules for UPS systems.\nStep 3: Confirm capacity adequacy to support critical operations during power outages.",
        "frequency": "Annually"
    },
    "A.7.12": {
        "title": "Cable Protection",
        "description": "Are the power and telecommunication cables adequately protected?",
        "testSteps": "Step 1: Inspect physical cable layouts for protective measures against physical damage.\nStep 2: Verify adherence to regulatory and manufacturer specifications for cable installation.\nStep 3: Confirm monitoring systems are in place for detecting unauthorized cable access.",
        "frequency": "Annually"
    },
    "A.7.13": {
        "title": "Equipment Maintenance",
        "description": "Is the equipment maintained regularly according to manufacturers’ specifications and good practice?",
        "testSteps": "Step 1: Review the maintenance schedules and records for critical equipment.\nStep 2: Verify compliance with manufacturer guidelines.\nStep 3: Confirm that maintenance logs are reviewed and updated regularly.",
        "frequency": "Annually"
    },
    "A.7.14": {
        "title": "Data and Software Removal",
        "description": "Are all the sensitive data and licensed software removed from media or equipment when disposed of?",
        "testSteps": "Step 1: Review procedures for sanitizing equipment before disposal.\nStep 2: Verify records of data wiping or destruction.\nStep 3: Confirm compliance through spot checks or third-party certifications.",
        "frequency": "Annually"
    },
    "A.8.1": {
        "title": "Secure Handling of Mobile Devices",
        "description": "Are there rules for secure handling of mobile devices?\nAre users protecting their equipment when not in physical possession of it?",
        "testSteps": "Requirement: Are there rules for secure handling of mobile devices?\nStep 1: Review policies governing the use of mobile devices.\nStep 2: Verify enforcement of rules such as password protection and device encryption.\nStep 3: Assess compliance through training logs or periodic checks.\n\nRequirement: Are users protecting their equipment when not in physical possession of it?\nStep 1: Verify user compliance with policies on securing unattended devices.\nStep 2: Confirm the use of lock screens and secure storage options.\nStep 3: Assess awareness through user interviews or incident reports.",
        "frequency": "Annually"
    },
    "A.8.2": {
        "title": "Management of Privileged Access Rights",
        "description": "Are privileged access rights managed with special care?",
        "testSteps": "Step 1: Review procedures for granting and reviewing privileged access.\nStep 2: Verify that privileged accounts are periodically audited.\nStep 3: Confirm corrective actions for identified issues.",
        "frequency": "Annually"
    },
    "A.8.3": {
        "title": "Access Control Policy Implementation",
        "description": "Is the access to information, software, and systems restricted according to the Access control policy?",
        "testSteps": "Step 1: Review the Access Control Policy for compliance requirements.\nStep 2: Verify access permissions on a sample of systems.\nStep 3: Confirm periodic review and updates to access control settings.",
        "frequency": "Annually"
    },
    "A.8.4": {
        "title": "Source Code Access Restriction",
        "description": "Is the access to source code restricted to authorized persons?",
        "testSteps": "Step 1: Review procedures for managing source code access.\nStep 2: Verify the list of authorized personnel and their justification for access.\nStep 3: Confirm implementation of monitoring or logging of source code access.",
        "frequency": "Annually"
    },
    "A.8.5": {
        "title": "Secure Log-On",
        "description": "Is secure log-on required on systems according to the Access control policy?",
        "testSteps": "Step 1: Review log-on mechanisms for compliance with the Access Control Policy.\nStep 2: Verify implementation of secure authentication methods (e.g., MFA, strong passwords).\nStep 3: Test secure log-on functionality on sample systems.",
        "frequency": "Annually"
    },
    "A.8.6": {
        "title": "Resource Monitoring and Capacity Forecasting",
        "description": "Does someone monitor the use of resources and forecast the required capacity?",
        "testSteps": "Step 1: Review logs and reports of resource usage.\nStep 2: Verify that capacity planning aligns with projected needs.\nStep 3: Confirm adjustments made based on monitoring results.",
        "frequency": "Annually"
    },
    "A.8.7": {
        "title": "Malware Protection",
        "description": "Is anti-virus software, and other software for malware protection, installed and updated?",
        "testSteps": "Step 1: Verify deployment of malware protection software across the organization.\nStep 2: Review update schedules and logs for the software.\nStep 3: Confirm that periodic scans and threat mitigation are conducted.",
        "frequency": "Annually"
    },
    "A.8.8": {
        "title": "Vulnerability Management",
        "description": "Is there someone in charge of collecting information about vulnerabilities, and are those vulnerabilities promptly resolved?\nAre information systems regularly reviewed to check their compliance with the information security policies and standards?",
        "testSteps": "Requirement: Is there someone in charge of collecting information about vulnerabilities, and are those vulnerabilities promptly resolved?\nStep 1: Review the vulnerability management process and assigned roles.\nStep 2: Verify records of identified vulnerabilities and their resolution timelines.\nStep 3: Confirm alignment with industry best practices for vulnerability remediation.\n\nRequirement: Are information systems regularly reviewed to check their compliance with the information security policies and standards?\nStep 1: Request system audit logs and compliance check reports.\nStep 2: Verify regularity and scope of system reviews.",
        "frequency": "Annually"
    },
    "A.8.9": {
        "title": "Configuration Management",
        "description": "Are configurations of hardware, software, services, and networks established, documented, implemented, monitored, and reviewed?",
        "testSteps": "Step 1: Review configuration management policies and documentation.\nStep 2: Verify implemented configurations against documented standards.\nStep 3: Confirm periodic review and monitoring of configurations.",
        "frequency": "Annually"
    },
    "A.8.10": {
        "title": "Data Deletion",
        "description": "Is information stored in systems, devices, and media deleted when no longer required?",
        "testSteps": "Step 1: Review data retention policies and procedures for deletion.\nStep 2: Verify records of deleted data and associated approvals.\nStep 3: Confirm use of secure deletion methods for sensitive data.",
        "frequency": "Annually"
    },
    "A.8.11": {
        "title": "Data Masking",
        "description": "Is data masked according to applicable policies, and business and legal requirements?",
        "testSteps": "Step 1: Review data masking policies and implementation procedures.\nStep 2: Verify evidence of data masking in sample datasets.\nStep 3: Confirm compliance with regulatory and business requirements.",
        "frequency": "Annually"
    },
    "A.8.12": {
        "title": "Data Leakage Prevention",
        "description": "Are data leakage prevention controls applied to assets that process, store, or transmit sensitive information?",
        "testSteps": "Step 1: Review the DLP strategy and implementation plan.\nStep 2: Verify deployment and monitoring of DLP tools.\nStep 3: Assess effectiveness through incident records or testing.",
        "frequency": "Annually"
    },
    "A.8.13": {
        "title": "Backup Policy",
        "description": "Is the backup policy developed; is the backup performed according to this policy?",
        "testSteps": "Step 1: Review the documented backup policy and associated schedules.\nStep 2: Verify backup logs for consistency with policy requirements.\nStep 3: Confirm testing of backup restoration processes.",
        "frequency": "Annually"
    },
    "A.8.14": {
        "title": "IT Infrastructure Redundancy",
        "description": "Is IT infrastructure implemented with redundancy to fulfill the expectations during disasters?",
        "testSteps": "Step 1: Review redundancy measures for critical IT infrastructure.\nStep 2: Verify evidence of redundancy tests and their outcomes.\nStep 3: Confirm alignment with disaster recovery requirements.",
        "frequency": "Annually"
    },
    "A.8.15": {
        "title": "Activity Logging",
        "description": "Are all user activities, faults, and other events from IT systems logged, and does someone check them?\nAre logs (including administrator logs) protected in such a way that unauthorized persons cannot change them?",
        "testSteps": "Requirement: Are all user activities, faults, and other events from IT systems logged, and does someone check them?\nStep 1: Review logging policies and procedures.\nStep 2: Verify the presence and completeness of log entries for selected events.\nStep 3: Confirm monitoring and analysis of logs by authorized personnel.\n\nRequirement: Are logs (including administrator logs) protected so unauthorized persons cannot change them?\nStep 1: Verify access controls and encryption for log files.\nStep 2: Confirm use of tamper-evident mechanisms for log integrity.\nStep 3: Review audit trails for attempted log changes.",
        "frequency": "Annually"
    },
    "A.8.16": {
        "title": "Monitoring and Incident Response",
        "description": "Are networks, systems, and applications monitored, with appropriated actions taken to evaluate potential information security incidents?",
        "testSteps": "Step 1: Review monitoring tools and processes for security incidents.\nStep 2: Verify incident records and corresponding responses.\nStep 3: Confirm regular updates to monitoring configurations based on evolving threats.",
        "frequency": "Monthly"
    },
    "A.8.17": {
        "title": "System Clock Synchronization",
        "description": "Are clocks on all IT systems synchronized with a single source of correct time?",
        "testSteps": "Step 1: Review time synchronization policies and configuration logs.\nStep 2: Verify that systems synchronize with an approved time source.\nStep 3: Confirm periodic checks to ensure accurate synchronization.",
        "frequency": "Annually"
    },
    "A.8.18": {
        "title": "Utility Tools Management",
        "description": "Is the use of utility tools that can override the security controls of applications and systems strictly controlled and limited to a narrow circle of employees?",
        "testSteps": "Step 1: Review policies for managing utility tools and administrative access.\nStep 2: Verify access logs and user permissions for utility tools.\nStep 3: Confirm periodic audits of tool usage.",
        "frequency": "Annually"
    },
    "A.8.19": {
        "title": "Software Installation Control",
        "description": "Is installation of software strictly controlled; do procedures exist for that purpose?",
        "testSteps": "Step 1: Review the policy for software installation and approval processes.\nStep 2: Verify access controls and logs for software installation actions.\nStep 3: Confirm regular audits of installed software against approved lists.",
        "frequency": "Annually"
    },
    "A.8.20": {
        "title": "Network Controls",
        "description": "Are the networks controlled in such a way that they protect information in systems and applications?",
        "testSteps": "Step 1: Review network security policies and configurations.\nStep 2: Verify evidence of segmentation, firewalls, and intrusion detection/prevention systems.\nStep 3: Confirm regular testing and updates to network controls.",
        "frequency": "Annually"
    },
    "A.8.21": {
        "title": "Network Service Security Requirements",
        "description": "Are security requirements for in-house and external network services defined, and included in agreements?",
        "testSteps": "Step 1: Review contracts and SLAs for in-house and external network services.\nStep 2: Verify inclusion of defined security requirements.\nStep 3: Confirm regular compliance checks and updates to agreements.",
        "frequency": "Annually"
    },
    "A.8.22": {
        "title": "Network Segregation",
        "description": "Are groups of users, services, and systems segregated in different networks?",
        "testSteps": "Step 1: Review network architecture documentation to confirm segregation.\nStep 2: Verify implementation of VLANs, firewalls, and other segregation controls.\nStep 3: Test network access to ensure unauthorized connections are blocked.",
        "frequency": "Annually"
    },
    "A.8.23": {
        "title": "Access to External Websites",
        "description": "Is access to external websites managed to reduce exposure to risks?",
        "testSteps": "Step 1: Review policies for managing web access and restrictions.\nStep 2: Verify deployment of web filtering or proxy solutions.\nStep 3: Confirm regular updates to access rules and risk assessments.",
        "frequency": "Monthly"
    },
    "A.8.24": {
        "title": "Encryption and Cryptographic Controls",
        "description": "Does the policy that regulates encryption and other cryptographic controls exist?\nAre the cryptographic keys properly protected?",
        "testSteps": "Requirement: Does the policy that regulates encryption and other cryptographic controls exist?\nStep 1: Review the organization's encryption policy.\nStep 2: Verify that the policy aligns with regulatory and organizational standards.\n\nRequirement: Are the cryptographic keys properly protected?\nStep 1: Review procedures for cryptographic key management.\nStep 2: Verify use of hardware security modules (HSMs) or secure key storage.\nStep 3: Confirm that access to keys is restricted and monitored.",
        "frequency": "Quarterly"
    },
    "A.8.25": {
        "title": "Secure Development Rules",
        "description": "Are the rules for the secure development of software and systems defined?",
        "testSteps": "Step 1: Review the organization's secure development guidelines.\nStep 2: Verify inclusion of security requirements in development lifecycle phases.\nStep 3: Confirm training of developers on secure development practices.",
        "frequency": "Annually"
    },
    "A.8.26": {
        "title": "Security Requirements in Applications",
        "description": "Are information security requirements defined and approved when developing or acquiring applications? ",
        "testSteps": "Step 1: Review documented security requirements for application development/acquisition.\nStep 2: Verify sign-off processes for security requirements.\nStep 3: Confirm alignment of requirements with organizational and legal standards.",
        "frequency": "Annually"
    },
    "A.8.27": {
        "title": "Secure System Engineering",
        "description": "Are the principles for engineering secure systems documented and implemented?",
        "testSteps": "Step 1: Review documentation of secure system engineering principles.\nStep 2: Verify implementation of these principles in system designs.\nStep 3: Confirm compliance through audits or testing.",
        "frequency": "Annually"
    },
    "A.8.28": {
        "title": "Secure Coding Principles",
        "description": "Are secure coding principles applied into software development?",
        "testSteps": "Step 1: Review secure coding standards and guidelines used by the organization.\nStep 2: Verify developer training records on secure coding practices.\nStep 3: Assess compliance through code reviews or penetration tests.",
        "frequency": "Annually"
    },
    "A.8.29": {
        "title": "Security Testing in Development",
        "description": "Is testing for proper implementation of security requirements performed during the software development?\nAre the criteria for accepting the systems defined?",
        "testSteps": "Requirement: Is testing for proper implementation of security requirements performed during software development?\nStep 1: Review the testing framework and methodologies for security validation.\nStep 2: Verify evidence of security tests performed (e.g., static/dynamic analysis).\nStep 3: Confirm remediation of identified issues before deployment.\n\nRequirement: Are the criteria for accepting the systems defined?\nStep 1: Review system acceptance criteria documentation.\nStep 2: Verify alignment with security and functional requirements.\nStep 3: Confirm sign-off on system acceptance tests.",
        "frequency": "Annually"
    },
    "A.8.30": {
        "title": "Outsourced Development",
        "description": "Is the outsourced development of systems monitored?",
        "testSteps": "Step 1: Review agreements with outsourced development vendors.\nStep 2: Verify monitoring records, such as audits or progress reports.\nStep 3: Confirm compliance with security requirements.",
        "frequency": "Annually"
    },
    "A.8.31": {
        "title": "Environment Segregation",
        "description": "Are development, testing, and production environments strictly separated?",
        "testSteps": "Step 1: Review environment configurations and access controls.\nStep 2: Verify logs and records for evidence of environment segregation.\nStep 3: Test access permissions to ensure no cross-environment access.",
        "frequency": "Annually"
    },
    "A.8.32": {
        "title": "Change Control",
        "description": "Are all the changes to IT systems, but also to other processes that could affect information security, strictly controlled through change procedures?",
        "testSteps": "Step 1: Review the change management policy and procedures.\nStep 2: Verify change approval records and implementation logs.\nStep 3: Confirm audits or post-change reviews to ensure security was maintained.",
        "frequency": "Annually"
    },
    "A.8.33": {
        "title": "Test Data Protection",
        "description": "Are the test data carefully selected and protected?",
        "testSteps": "Step 1: Review policies for selecting and handling test data.\nStep 2: Verify that sensitive data is anonymized or masked in test environments.\nStep 3: Confirm secure storage and access controls for test data.",
        "frequency": "Annually"
    },
    "A.8.34": {
        "title": "Audits of Production Systems",
        "description": "Are audits of production systems planned and executed in such a way that they minimize the risk of disruption?",
        "testSteps": "Step 1: Review the audit plan for production systems.\nStep 2: Verify audit execution records and adherence to planned schedules.\nStep 3: Confirm that mitigation strategies are in place to reduce disruption risks.",
        "frequency": "Annually"
    }
};
  
export default ISOAnnexAFrameworkMapping;