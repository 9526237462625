import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemText,
  Box,
  LinearProgress
} from '@mui/material';
import { styled } from '@mui/material/styles';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.background.paper,
  '&:hover': {
    boxShadow: theme.shadows[6],
  },
}));

const ControlCoverageCard = ({ frameworks }) => {
  const calculateCoverage = () => {
    const coverage = {
      total: 0,
      implemented: 0,
      categories: {}
    };
    
    frameworks.forEach(framework => {
      framework.controls?.forEach(controlId => {
        coverage.total++;
        const result = framework.results?.find(r => r.control === controlId);
        if (result?.ai_result?.overallAssessment === 'ACCEPT') {
          coverage.implemented++;
        }
        
        // Track by control category if available
        const category = controlId;
        coverage.categories[category] = coverage.categories[category] || { total: 0, implemented: 0 };
        coverage.categories[category].total++;
        if (result?.ai_result?.overallAssessment === 'ACCEPT') {
          coverage.categories[category].implemented++;
        }
      });
    });
    
    return coverage;
  };

  const coverage = calculateCoverage();

  return (
    <StyledCard>
      <CardContent>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <AssignmentTurnedInIcon sx={{ fontSize: 40, mr: 2, color: 'success.main' }} />
          <Typography variant="h6">Control Coverage Analysis</Typography>
        </Box>
        <Box sx={{ mb: 3 }}>
          <Typography variant="subtitle2" color="textSecondary" gutterBottom>
            Overall Implementation Rate
          </Typography>
          <LinearProgress 
            variant="determinate" 
            value={(coverage.implemented / coverage.total) * 100}
            sx={{ height: 10, borderRadius: 5 }}
            color="success"
          />
          <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
            {coverage.implemented} of {coverage.total} controls implemented
          </Typography>
        </Box>
        <Typography variant="subtitle2" color="textSecondary" gutterBottom>
          Coverage by Control
        </Typography>
        <List>
          {Object.entries(coverage.categories).map(([category, stats], index) => (
            <ListItem key={index}>
              <ListItemText 
                primary={category}
                secondary={`${stats.implemented}/${stats.total} controls`}
              />
              <LinearProgress 
                variant="determinate" 
                value={(stats.implemented / stats.total) * 100}
                sx={{ width: '100px', ml: 2 }}
                color="primary"
              />
            </ListItem>
          ))}
        </List>
      </CardContent>
    </StyledCard>
  );
};

export default ControlCoverageCard;