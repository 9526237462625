import OpenAI from 'openai';

export const handleOpenAISubmit = async (files, selectedSubcategory, frameworkData, setResponseData) => {
  const openai = new OpenAI({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    dangerouslyAllowBrowser: true
  });

  // Upload files
  console.log(`Uploading ${files.length} files...`);
  const fileUploads = await Promise.all(
    files.map(file => 
      openai.files.create({ 
        file, 
        purpose: 'assistants' 
      }).then(response => {
        console.log(`File uploaded: ${file.name}, ID: ${response.id}`);
        return response;
      })
    )
  );
  console.log(`${fileUploads.length} files uploaded successfully.`);

  try {
    // Create thread
    console.log("Creating new thread...");
    const thread = await openai.beta.threads.create();
    console.log("Thread created:", thread.id);

    // Prepare message content with NIST framework details
    const messageContent = `Here are my documents for analysis against NIST CSF Subcategory "${selectedSubcategory}".
    
Framework Details:
- Category: ${frameworkData.category}
- Subcategory: ${frameworkData.subcategory}
- Title: ${frameworkData.details.title}
- Implementation Examples:
${frameworkData.details.examples.map(example => `  • ${example}`).join('\n')}

Please analyze the provided documents with respect to this NIST CSF subcategory. 
Consider:
1. How well the evidence aligns with the subcategory requirements
2. Which implementation examples are demonstrated
3. Areas for potential improvement
4. Overall maturity level for this subcategory

Keep in mind this is a single request, I cannot respond after your response. Always post_result when done!`;

    console.log(`Prompt: ${messageContent}`);

    // Determine file types and create appropriate message content
    const messageContentArray = [{ type: "text", text: messageContent }];

    // Create attachments array for non-image files
    var attachments = fileUploads.map(file => {
      const fileType = file.filename.split('.').pop().toLowerCase();
      if (['jpg', 'jpeg', 'png', 'gif'].includes(fileType)) {
        messageContentArray.push({
          type: "image_file",
          image_file: { file_id: file.id }
        });
        return null;
      }
      return {
        file_id: file.id,
        tools: [{ type: "file_search" }]
      };
    });
    attachments = attachments.filter(att => att != null);

    // Create message parameters based on whether there are attachments
    var messageParams = null;
    if (attachments.length > 0) {
      messageParams = {
        role: "user",
        content: messageContentArray,
        attachments
      };
    } else {
      messageParams = {
        role: "user",
        content: messageContentArray
      };
    }

    // Add message to thread
    console.log("Adding message to thread...");
    try {
      const message = await openai.beta.threads.messages.create(thread.id, messageParams);
      console.log("Message added to thread:", message.id);
    } catch (error) {
      console.error("Error adding message to thread:", error);
      throw error;
    }

    // Run assistant
    console.log("Running assistant...");
    const run = await openai.beta.threads.runs.create(thread.id, {
      assistant_id: "asst_QuEokVVMgYvmyADv7jioYjDQ", // Ensure this is the correct assistant ID for NIST analysis
    });

    // Poll for completion
    let response = null;
    while (true) {
      const completedRun = await openai.beta.threads.runs.retrieve(thread.id, run.id);
      console.log("Run status:", completedRun.status);

      if (completedRun.status === 'completed') {
        break;
      } else if (completedRun.status === 'failed') {
        throw new Error("Run failed: " + completedRun.last_error?.message);
      } else if (completedRun.status === 'requires_action') {
        await handleRequiredAction(completedRun, thread.id, run.id, openai, setResponseData, files);
      }
      await new Promise(resolve => setTimeout(resolve, 1000));
    }

    // Get response
    const messages = await openai.beta.threads.messages.list(thread.id);
    const lastAssistantMessage = messages.data
      .filter(message => message.role === 'assistant')
      .pop();

    response = lastAssistantMessage?.content[0]?.text?.value || "No response from the assistant.";

    return response;
  } catch (error) {
    throw error;
  } finally {
    // Cleanup: delete uploaded files
    console.log("Cleaning up...");
    await Promise.all(fileUploads.map(file => openai.files.del(file.id)));
    console.log("Cleanup completed");
  }
};

const handleRequiredAction = async (completedRun, threadId, runId, openai, setResponseData, files) => {
  const requiredAction = completedRun.required_action;
  if (requiredAction.type === 'submit_tool_outputs') {
    for (const toolCall of requiredAction.submit_tool_outputs.tool_calls) {
      if (toolCall.function.name === 'post_result') {
        const argument_json = JSON.parse(toolCall.function.arguments);
        var filename = "No Filename";
        if (files.length > 0) {
          filename = files[0].name;
        }
        setResponseData(argument_json, filename);
        
        await openai.beta.threads.runs.submitToolOutputs(threadId, runId, {
          tool_outputs: [{
            tool_call_id: toolCall.id,
            output: JSON.stringify({ success: "true" })
          }]
        });
        
        console.log("Handled post_result action");
      }
    }
  }
};

// File validation utilities remain the same
export const getSupportedFileTypes = () => ({
  documents: ['.txt', '.pdf', '.doc', '.docx', '.csv', '.json', '.xlsx', '.xls'],
  images: ['.jpg', '.jpeg', '.png', '.gif'],
  code: ['.py', '.js', '.java', '.cpp', '.html', '.css']
});

export const isFileTypeSupported = (file) => {
  const fileExtension = '.' + file.name.split('.').pop().toLowerCase();
  const supportedTypes = getSupportedFileTypes();
  return Object.values(supportedTypes).flat().includes(fileExtension);
};

export const isFileSizeValid = (file, maxSizeMB = 20) => {
  return file.size <= maxSizeMB * 1024 * 1024;
};

export const validateFiles = (files) => {
  const errors = [];
  
  files.forEach(file => {
    if (!isFileTypeSupported(file)) {
      errors.push(`File type not supported for ${file.name}`);
    }
    if (!isFileSizeValid(file)) {
      errors.push(`File size exceeds limit for ${file.name}`);
    }
  });
  
  return {
    isValid: errors.length === 0,
    errors
  };
};