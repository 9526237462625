import React, { useState, useEffect, useRef } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Alert,
  Box,
  Typography,
  CircularProgress,
  useTheme,
  Stack,
  Avatar,
  styled
} from '@mui/material';
import { Edit } from '@mui/icons-material';
import UserService from '../services/user_service';
import AuthService from '../services/auth_service';
import CountrySelect from './CountrySelect';
import PhoneInput from './PhoneInput';

// Styled components
const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: 16,
    [theme.breakpoints.up('sm')]: {
      minWidth: 600
    }
  }
}));

const ProfileImageContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: 200,
  height: 200,
  margin: '0 auto 32px',
  borderRadius: '50%',
  overflow: 'hidden',
  boxShadow: theme.shadows[4],
  transition: 'transform 0.3s ease',
  '&:hover': {
    transform: 'scale(1.02)'
  }
}));

const FormSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: theme.palette.background.default,
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(3)
}));

const AccountDialog = ({ open, onClose, userEmail }) => {
  const theme = useTheme();
  const [changePasswordMode, setChangePasswordMode] = useState(false);
  const fileInputRef = useRef(null);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: userEmail,
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
    profile_picture: '',
    full_name: '',
    phone_number: '',
    state_region: '',
    address: '',
    country: '',
    city: '',
    zip_code: ''
  });

  useEffect(() => {
    const loadUserData = async () => {
      if (open) {
        try {
          setIsLoading(true);
          setError(null);
          const response = await UserService.getUser();
          const { user } = response;
          
          setFormData(prevData => ({
            ...prevData,
            full_name: user.full_name || '',
            phone_number: user.phone_number || '',
            profile_picture: user.profile_picture || '',
            state_region: user.state_region || '',
            address: user.address || '',
            country: user.country || '',
            city: user.city || '',
            zip_code: user.zip_code || ''
          }));
        } catch (err) {
          setError('Failed to load user data: ' + (err.message || 'Unknown error'));
        } finally {
          setIsLoading(false);
        }
      }
    };

    loadUserData();
  }, [open]);

  const handleImageSelect = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    try {
      setIsSubmitting(true);
      setError(null);
      
      const reader = new FileReader();
      reader.onload = async (e) => {
        const base64String = e.target.result.split(',')[1];
        setFormData(prev => ({
          ...prev,
          profile_picture: base64String
        }));
        
        try {
          await UserService.saveUser({
            ...formData,
            profile_picture: base64String,
            email: userEmail
          });
        } catch (err) {
          setError(err.message || 'Failed to save profile picture');
        }
      };
      
      reader.readAsDataURL(file);
    } catch (err) {
      setError(err.message || 'Failed to process image');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleResetPasswordRequest = async () => {
    try {
      setIsSubmitting(true);
      setError(null);
      
      await AuthService.resetPasswordRequest(userEmail);
      
      setSuccess('Password reset email has been sent. Please check your inbox.');
      setChangePasswordMode(false);
    } catch (err) {
      setError(err.message || 'Failed to request password reset');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handlePasswordChange = async () => {
    try {
      setIsSubmitting(true);
      setError(null);

      if (formData.newPassword !== formData.confirmPassword) {
        throw new Error('New passwords do not match');
      }

      if (formData.newPassword.length < 8) {
        throw new Error('Password must be at least 8 characters long');
      }

      await handleResetPasswordRequest();
      
    } catch (err) {
      setError(err.message || 'Failed to change password');
      setIsSubmitting(false);
    }
  };

  const handleFieldChange = (field) => (e) => {
    if (!e.target) {
      setFormData({ ...formData, [field]: e });
    }
    else{
      setFormData({ ...formData, [field]: e.target.value });
    }
  };

  const handleFieldBlur = async (field) => {
    try {
      setError(null);
      if (formData[field] !== '') {
        await UserService.saveUser({
          ...formData,
          email: userEmail
        });
      }
    } catch (err) {
      setError(err.message || `Failed to save ${field}`);
    }
  };

  const handleClose = () => {
    setChangePasswordMode(false);
    setError(null);
    setSuccess(null);
    setFormData({
      email: userEmail,
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      full_name: '',
      phone_number: '',
      state_region: '',
      address: '',
      country: '',
      city: '',
      zip_code: ''
    });
    onClose();
  };

  return (
    <StyledDialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle sx={{ 
        borderBottom: 1, 
        borderColor: 'divider',
        px: 3,
        py: 2,
        typography: 'h5',
        fontWeight: 'medium'
      }}>
        Account Settings
      </DialogTitle>
      
      <DialogContent sx={{ p: 3 }}>
        {isLoading ? (
          <Box display="flex" justifyContent="center" my={4}>
            <CircularProgress />
          </Box>
        ) : (
          <Stack spacing={4}>
            {/* Profile Section */}
            <FormSection sx={{backgroundColor:'rgba(237, 125, 49, 0.08)'}}>
              <ProfileImageContainer>
                <Avatar
                  src={formData.profile_picture ? `data:image/jpeg;base64,${formData.profile_picture}` : '/default-avatar.jpg'}
                  alt={formData.full_name}
                  sx={{ width: '100%', height: '100%', cursor: 'pointer' }}
                  onClick={() => !isSubmitting ? fileInputRef.current?.click() : () => {}}
                />
                <input
                  type="file"
                  ref={fileInputRef}
                  hidden
                  accept="image/*"
                  onChange={handleImageSelect}
                />
              </ProfileImageContainer>

              <Typography variant="h6" gutterBottom align="center">
                {formData.full_name || 'Your Name'}
              </Typography>
              <Typography color="text.secondary" align="center" gutterBottom>
                {userEmail}
              </Typography>
            </FormSection>

            {/* Personal Information Section */}
            <FormSection sx={{backgroundColor:'rgba(237, 125, 49, 0.08)'}}>
              <Typography variant="h6" gutterBottom>
                Personal Information
              </Typography>
              <Stack spacing={3}>
                <TextField
                  fullWidth
                  label="Full Name"
                  value={formData.full_name}
                  onChange={handleFieldChange('full_name')}
                  onBlur={() => handleFieldBlur('full_name')}
                  variant="outlined"
                />

                <PhoneInput
                  label="Phone Number"
                  value={formData.phone_number}
                  onChange={handleFieldChange('phone_number')}
                  onBlur={() => handleFieldBlur('phone_number')}
                />
              </Stack>
            </FormSection>

            {/* Address Section */}
            <FormSection sx={{backgroundColor:'rgba(237, 125, 49, 0.08)'}}>
              <Typography variant="h6" gutterBottom>
                Address Information
              </Typography>
              <Stack spacing={3}>
                <TextField
                  fullWidth
                  label="Address"
                  value={formData.address}
                  onChange={handleFieldChange('address')}
                  onBlur={() => handleFieldBlur('address')}
                  variant="outlined"
                />

                <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 2 }}>
                  <TextField
                    fullWidth
                    label="City"
                    value={formData.city}
                    onChange={handleFieldChange('city')}
                    onBlur={() => handleFieldBlur('city')}
                    variant="outlined"
                  />
                  
                  <TextField
                    fullWidth
                    label="State/Region"
                    value={formData.state_region}
                    onChange={handleFieldChange('state_region')}
                    onBlur={() => handleFieldBlur('state_region')}
                    variant="outlined"
                  />
                </Box>

                <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 2 }}>
                  <CountrySelect
                    value={formData.country}
                    onChange={handleFieldChange('country')}
                    onBlur={() => handleFieldBlur('country')}
                  />
                  
                  <TextField
                    fullWidth
                    label="ZIP/Postal Code"
                    value={formData.zip_code}
                    onChange={handleFieldChange('zip_code')}
                    onBlur={() => handleFieldBlur('zip_code')}
                    variant="outlined"
                  />
                </Box>
              </Stack>
            </FormSection>

            {/* Password Section */}
            <FormSection sx={{backgroundColor:'rgba(237, 125, 49, 0.08)'}}>
              <Typography variant="h6" gutterBottom>
                Password Settings
              </Typography>
              {!changePasswordMode ? (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleResetPasswordRequest}
                  disabled={isSubmitting}
                  startIcon={<Edit />}
                >
                  Request Password Change
                </Button>
              ) : null}
            </FormSection>

            {error && (
              <Alert severity="error" variant="filled">
                {error}
              </Alert>
            )}

            {success && (
              <Alert severity="success" variant="filled">
                {success}
              </Alert>
            )}
          </Stack>
        )}
      </DialogContent>

      <DialogActions sx={{ 
        borderTop: 1, 
        borderColor: 'divider',
        px: 3,
        py: 2
      }}>
        {changePasswordMode && (
          <Button 
            onClick={() => setChangePasswordMode(false)}
            disabled={isSubmitting}
          >
            Cancel
          </Button>
        )}
        <Button 
          onClick={handleClose}
          disabled={isSubmitting}
          variant="outlined"
        >
          Close
        </Button>
        {changePasswordMode && (
          <Button
            onClick={handlePasswordChange}
            variant="contained"
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <CircularProgress size={20} />
            ) : (
              'Change Password'
            )}
          </Button>
        )}
      </DialogActions>
    </StyledDialog>
  );
};

export default AccountDialog;