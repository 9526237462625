import React, { useState } from 'react';
import {
  Paper,
  Typography,
  Box,
  CircularProgress,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  TextField,
  IconButton,
  Chip,
  Collapse,
  Card,
  CardContent,
  Grid
} from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { WarningOutlined } from '@mui/icons-material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import FrameworkService from '../services/framework_service';
import { useTheme } from '@mui/material/styles';
import PostAddIcon from '@mui/icons-material/PostAdd';

const StatusChip = ({ status }) => {
  const isReject = status === 'REJECT';
  return (
    <Chip
      icon={isReject ? <ErrorOutlineIcon /> : status === 'WARNING' ? <WarningOutlined /> : <CheckCircleOutlineIcon />}
      label={status}
      color={isReject ? 'error' : status === 'WARNING' ? 'warning' : 'success'}
      variant="outlined"
      size="small"
    />
  );
};

const AssessmentSection = ({ title, status, reasoning }) => (
  <Paper sx={{ p: 2, mb: 2 }}>
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
      <Typography variant="h6" component="div">
        {title}
      </Typography>
      <StatusChip status={status} />
    </Box>
    <Typography variant="body2" color="text.secondary">
      {reasoning}
    </Typography>
  </Paper>
);

const AnalysisResultsDisplay = ({ results, fetchInstances, instance, control }) => {
  const [expanded, setExpanded] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedGroupResult, setSelectedGroupResult] = useState(null);
  const [groupValue, setGroupValue] = useState('');
  const [isGrouping, setIsGrouping] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [groupDialogOpen, setGroupDialogOpen] = useState(false);
  const theme = useTheme();
  
  if (!results || results.length === 0) return null;

  const currentResult = results[currentIndex];
  const gmtDate = new Date(currentResult.date);
  const offsetMinutes = new Date().getTimezoneOffset();
  const localDate = new Date(gmtDate.getTime() - (offsetMinutes * 60000));
  
  const {
    overallAssessment,
    organizationIdentification,
    systemProcessIdentification,
    timestamp,
    reasoning
  } = currentResult.ai_result;

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleGroupUpdate = async (selectedGroupResult) => {
    try {
      setIsGrouping(true);
      await FrameworkService.updateResultGroup(selectedGroupResult.uuid, groupValue);
      setSnackbar({
        open: true,
        message: 'Group updated successfully',
        severity: 'success'
      });
      setGroupDialogOpen(false);
      setGroupValue('');
      setSelectedGroupResult(null);
      fetchInstances();
    } catch (err) {
      setSnackbar({
        open: true,
        message: err.message || 'Failed to update group',
        severity: 'error'
      });
    } finally {
      setIsGrouping(false);
    }
  };
  
  const openGroupDialog = (result) => {
    setSelectedGroupResult(result);
    setGroupValue(result?.group || '');
    setGroupDialogOpen(true);
  };

  const goToPrevious = () => {
    setCurrentIndex(prev => Math.max(0, prev - 1));
  };
  
  const goToNext = () => {
    setCurrentIndex(prev => Math.min(results.length - 1, prev + 1));
  };

  return (
    <Box sx={{ mt: 2 }}>
      <Card>
        <CardContent>
          <Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Typography variant="h5">
                  Assessment Results
                </Typography>
                <IconButton
                  size="small"
                  onClick={() => openGroupDialog(currentResult)}
                  title="Edit Group"
                  sx={{ color: theme.palette.text.primary }}
                >
                  <PostAddIcon />
                </IconButton>
                <IconButton
                  onClick={handleExpandClick}
                  aria-expanded={expanded}
                  aria-label="show more"
                  size="small"
                >
                  {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <IconButton 
                    onClick={goToPrevious}
                    disabled={currentIndex === 0}
                    size="small"
                  >
                    <ChevronLeftIcon />
                  </IconButton>
                  <Typography variant="body2" color="text.secondary">
                    {currentIndex + 1} of {results.length}
                  </Typography>
                  <IconButton 
                    onClick={goToNext}
                    disabled={currentIndex === results.length - 1}
                    size="small"
                  >
                    <ChevronRightIcon />
                  </IconButton>
                </Box>
                <StatusChip status={overallAssessment} />
              </Box>
            </Box>
            <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
              Date: {localDate.toLocaleString()}
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
              Uploaded By: {currentResult.created_by}
            </Typography>
            {currentResult.file && (
              <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                File: {currentResult.file}
              </Typography>
            )}
          </Box>

          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <Divider sx={{ my: 2 }} />

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <AssessmentSection
                  title="Overall Assessment"
                  status={overallAssessment}
                  reasoning={reasoning?.overall}
                />
              </Grid>
              <Grid item xs={12}>
                <AssessmentSection
                  title="Organization Identification"
                  status={organizationIdentification}
                  reasoning={reasoning?.organization}
                />
              </Grid>
              <Grid item xs={12}>
                <AssessmentSection
                  title="System Process Identification"
                  status={systemProcessIdentification}
                  reasoning={reasoning?.systemProcess}
                />
              </Grid>
              <Grid item xs={12}>
                <AssessmentSection
                  title="Timestamp Verification"
                  status={timestamp}
                  reasoning={reasoning?.timestamp}
                />
              </Grid>
            </Grid>
          </Collapse>
        </CardContent>
      </Card>
      
      <Dialog
        open={groupDialogOpen}
        onClose={() => {
          setGroupDialogOpen(false);
          setGroupValue('');
          setSelectedGroupResult(null);
        }}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            backgroundColor: theme.palette.background.paper,
          }
        }}
      >
        <DialogTitle sx={{ color: theme.palette.text.primary }}>
          Edit Group
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2 }}>
            <TextField
              label="Group Name"
              fullWidth
              value={groupValue}
              onChange={(e) => setGroupValue(e.target.value)}
              disabled={isGrouping}
              helperText="Enter a group name for this result"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setGroupDialogOpen(false);
              setGroupValue('');
              setSelectedGroupResult(null);
            }}
            disabled={isGrouping}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {handleGroupUpdate(currentResult)}}
            variant="contained"
            color="primary"
            disabled={isGrouping}
          >
            {isGrouping ? (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <CircularProgress size={20} color="inherit" />
                Updating...
              </Box>
            ) : (
              'Update'
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AnalysisResultsDisplay;