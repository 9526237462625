import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  CircularProgress,
  useTheme,
  LinearProgress,
  Alert,
  Chip
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

// Services
import UserService from '../services/user_service';
import FrameworkService from '../services/framework_service';
import ActivityService from '../services/activity_service';

// Icons
import UpdateIcon from '@mui/icons-material/Update';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DeadlinesCard from './DeadlineCard';
import AccountDialog from './AccountDialog';

import StatusCards from './StatusCards';
import RiskMetricsCard from './RiskMetricsCard';
import ComplianceTimelineCard from './ComplianceTimelineCard';
import ControlCoverageCard from './ControlCoverageCard';


// Styled components (keep your existing styled components)
const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.background.paper,
  '&:hover': {
    boxShadow: theme.shadows[6],
  },
}));

const WelcomeCard = styled(Card)(({ theme }) => ({
  backgroundImage: 'linear-gradient(to right, rgb(63, 157, 181), #2196f3)',
  color: theme.palette.common.white,
  height: '100%',
}));

const LoadingOverlay = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  minHeight: 100,
}));

const HomePage = () => {
  const token = localStorage.getItem('auth_token');  
  // Decode the JWT token (split by dots and decode middle section)
  const [, payloadBase64] = token.split('.');
  const payload = JSON.parse(atob(payloadBase64));
  const isAdmin = payload['role'] === 'admin';
  const theme = useTheme();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedFramework, setSelectedFramework] = useState(null);
  const [accountDialogOpen, setAccountDialogOpen] = useState(false);
  const [dashboardData, setDashboardData] = useState({
    users: [],
    frameworks: [],
    activity: [],
    controlsDueThisWeek: [],
    overdueControls: []
  });

  const isControlAccepted = (framework, controlId) => {
    const result = framework.results?.find(r => r.control === controlId)?.ai_result;
    return result?.overallAssessment === 'ACCEPT';
  };

  const fetchDashboardData = async () => {
    try {
      setLoading(true);
      const [users, frameworks, activity] = await Promise.all([ 
        isAdmin ? UserService.getUsers() : new Promise((resolve, reject) => resolve({ users: [] })),
        FrameworkService.getFrameworkInstances(),
        ActivityService.getRecentActivity()
      ]);

      const today = new Date();
      const endOfWeek = new Date(today);
      endOfWeek.setDate(today.getDate() + 6);

      const controlsDueThisWeek = frameworks.instances.reduce((acc, framework) => {
        const dueThisWeek = Object.entries(framework.dueDates || {})
          .filter(([controlId, date]) => {
            const dueDate = new Date(date);
            return dueDate >= today && 
                  dueDate <= endOfWeek && 
                  !isControlAccepted(framework, controlId);
          })
          .map(([controlId]) => ({
            frameworkTitle: framework.title,
            controlId
          }));
        return [...acc, ...dueThisWeek];
      }, []);

      const overdueControls = frameworks.instances.reduce((acc, framework) => {
        const overdue = Object.entries(framework.dueDates || {})
          .filter(([controlId, date]) => {
            return new Date(date) < today && !isControlAccepted(framework, controlId);
          })
          .map(([controlId]) => ({
            frameworkTitle: framework.title,
            controlId
          }));
        return [...acc, ...overdue];
      }, []);

      setDashboardData({
        users: users.users,
        frameworks: frameworks.instances,
        activity: activity,
        controlsDueThisWeek,
        overdueControls
      });
      setError(null);
    } catch (err) {
      setError(err.message || 'Failed to fetch dashboard data');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDashboardData();
  }, []);

  const getFrameworkStats = () => {
    const stats = {};
    dashboardData.frameworks.forEach(framework => {
      // Initialize counters for all possible statuses
      const statusCounts = {
        accept: 0,
        warning: 0,
        reject: 0,
        blocked: 0,
        notStarted: 0,
        inProgress: 0,
        completed: 0
      };
  
      // Count control statuses based on results
      framework.controls.forEach(controlId => {
        const result = framework.results?.find(r => r.control === controlId)?.ai_result;
        if (result) {
          switch (result.overallAssessment) {
            case 'ACCEPT':
              statusCounts.accept++;
              break;
            case 'WARNING':
              statusCounts.warning++;
              break;
            case 'REJECT':
              statusCounts.reject++;
              break;
            default:
              statusCounts.notStarted++;
          }
        } else {
          statusCounts.notStarted++;
        }
      });
  
      // Handle framework-level statuses
      switch (framework.testingStatus) {
        case 'COMPLETE':
          statusCounts.completed++;
          break;
        case 'IN_PROGRESS':
          statusCounts.inProgress++;
          break;
        case 'BLOCKED':
          statusCounts.blocked++;
          break;
      }
  
      stats[framework.title] = {
        ...statusCounts,
        totalControls: framework.controls.length
      };
    });
    return stats;
  };

  const renderFrameworkStatus = () => {
    const frameworkStats = getFrameworkStats();
    
    if (selectedFramework) {
      const stats = frameworkStats[selectedFramework];
      const total = stats.totalControls;
      
      return (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <IconButton size="small" onClick={() => setSelectedFramework(null)}>
              <ChevronRightIcon />
            </IconButton>
            <Typography variant="h6">{selectedFramework}</Typography>
          </Box>
          <List>
            {/* Framework Level Status */}
            <Box sx={{ mb: 2, p: 2, bgcolor: 'background.paper', borderRadius: 1 }}>
              <Typography variant="subtitle2" color="primary" gutterBottom>
                Framework Status
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography variant="body2" color="textSecondary">
                    Testing Status
                  </Typography>
                  <Chip
                    size="small"
                    label={dashboardData.frameworks.find(f => f.title === selectedFramework)?.testingStatus || 'NOT_STARTED'}
                    color={getStatusChipColor(dashboardData.frameworks.find(f => f.title === selectedFramework)?.testingStatus)}
                    sx={{ mt: 0.5 }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2" color="textSecondary">
                    Review Status
                  </Typography>
                  <Chip
                    size="small"
                    label={dashboardData.frameworks.find(f => f.title === selectedFramework)?.reviewStatus || 'NOT_STARTED'}
                    color={getStatusChipColor(dashboardData.frameworks.find(f => f.title === selectedFramework)?.reviewStatus)}
                    sx={{ mt: 0.5 }}
                  />
                </Grid>
              </Grid>
            </Box>
  
            {/* Control Results */}
            <Typography variant="subtitle2" color="primary" gutterBottom sx={{ pl: 2 }}>
              Control Results
            </Typography>
            
            {stats.accept > 0 && (
              <ListItem>
                <ListItemText 
                  primary="Accepted" 
                  secondary={`${stats.accept} controls`}
                />
                <LinearProgress 
                  variant="determinate" 
                  value={(stats.accept / total) * 100}
                  sx={{ width: '100px', ml: 2 }}
                  color="success"
                />
              </ListItem>
            )}
            
            {stats.warning > 0 && (
              <ListItem>
                <ListItemText 
                  primary="Warnings" 
                  secondary={`${stats.warning} controls`}
                />
                <LinearProgress 
                  variant="determinate" 
                  value={(stats.warning / total) * 100}
                  sx={{ width: '100px', ml: 2 }}
                  color="warning"
                />
              </ListItem>
            )}
            
            {stats.reject > 0 && (
              <ListItem>
                <ListItemText 
                  primary="Rejected" 
                  secondary={`${stats.reject} controls`}
                />
                <LinearProgress 
                  variant="determinate" 
                  value={(stats.reject / total) * 100}
                  sx={{ width: '100px', ml: 2 }}
                  color="error"
                />
              </ListItem>
            )}
            
            {stats.notStarted > 0 && (
              <ListItem>
                <ListItemText 
                  primary="Not Started" 
                  secondary={`${stats.notStarted} controls`}
                />
                <LinearProgress 
                  variant="determinate" 
                  value={(stats.notStarted / total) * 100}
                  sx={{ width: '100px', ml: 2 }}
                  color="primary"
                />
              </ListItem>
            )}
  
            {/* Summary */}
            <Box sx={{ mt: 2, p: 2, bgcolor: 'background.paper', borderRadius: 1 }}>
              <Typography variant="subtitle2" gutterBottom>
                Summary
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Total Controls: {total}
              </Typography>
            </Box>
          </List>
        </>
      );
    }
  
    return (
      <List>
        {Object.entries(frameworkStats).map(([framework, stats]) => {
          const frameworkData = dashboardData.frameworks.find(f => f.title === framework);
          return (
            <ListItem 
              key={framework} 
              button 
              onClick={() => setSelectedFramework(framework)}
            >
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText 
                primary={
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    {framework}
                    {frameworkData && (
                      <Chip 
                        size="small"
                        label={frameworkData.testingStatus}
                        color={getStatusChipColor(frameworkData.testingStatus)}
                      />
                    )}
                  </Box>
                }
                secondary={
                  <Typography variant="body2" color="textSecondary">
                    {stats.totalControls} controls • {stats.accept} accepted • {stats.warning} warnings • {stats.reject} rejected
                  </Typography>
                }
              />
              <ChevronRightIcon />
            </ListItem>
          );
        })}
      </List>
    );
  };
  
  const getStatusChipColor = (status) => {
    switch (status) {
      case 'COMPLETE':
        return 'success';
      case 'IN_PROGRESS':
        return 'warning';
      case 'BLOCKED':
        return 'error';
      case 'NOT_STARTED':
      default:
        return 'default';
    }
  };

  const getUserInfo = () => {
    try {
      const token = localStorage.getItem('auth_token');
      if (!token) return null;
      const [, payloadBase64] = token.split('.');
      return JSON.parse(atob(payloadBase64));
    } catch (error) {
      return null;
    }
  };

  if (loading) {
    return (
      <LoadingOverlay>
        <CircularProgress />
      </LoadingOverlay>
    );
  }

  const userInfo = getUserInfo();
  const isNewUser = !userInfo?.name; // Check if user hasn't set their name

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      {error && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
      )}

      <Grid container spacing={3}>
        {/* Welcome Card - only show if new user */}
        {isNewUser && (
          <Grid item xs={12} md={6}>
            <WelcomeCard>
              <CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <AccountCircleIcon sx={{ fontSize: 40, mr: 2 }} />
                  <Typography variant="h5" component="div">
                    Welcome to Audit Agent
                  </Typography>
                </Box>
                <Typography variant="body1" sx={{ mb: 2 }}>
                  If this is your first time using the system, update your user profile here.
                </Typography>
                <Button
                  variant="contained"
                  color="secondary"
                  endIcon={<ArrowForwardIcon />}
                onClick={() => setAccountDialogOpen(true)}
                >
                  Go Now
                </Button>
              </CardContent>
            </WelcomeCard>
          </Grid>
        )}

        {/* App Updates Card */}
        <Grid item xs={12} md={isNewUser ? 6 : 12}>
          <StyledCard>
            <CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <NewReleasesIcon sx={{ fontSize: 40, mr: 2, color: theme.palette.primary.main }} />
                <Typography variant="h5" component="div">
                  App Updates
                </Typography>
              </Box>
              <List>
                {dashboardData.activity.slice(0, 3).map((activity, index) => (
                  <ListItem key={index}>
                    <ListItemIcon>
                      <UpdateIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText 
                      primary={activity.description}
                      secondary={new Date(activity.timestamp).toLocaleDateString()}
                    />
                  </ListItem>
                ))}
              </List>
            </CardContent>
          </StyledCard>
        </Grid>

        {/* Stats Cards */}
        <StatusCards dashboardData={dashboardData} isAdmin={isAdmin} />

        {/* Framework Status Card */}
        <Grid item xs={12} md={6}>
          <StyledCard>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Framework Status Summary
              </Typography>
              {renderFrameworkStatus()}
            </CardContent>
          </StyledCard>
        </Grid>

        {/* Monthly Deadlines Card */}
        <DeadlinesCard frameworks={dashboardData.frameworks} />

        <>
          <Grid item xs={12} md={4}>
            <RiskMetricsCard frameworks={dashboardData.frameworks} />
          </Grid>
          <Grid item xs={12} md={4}>
            <ComplianceTimelineCard frameworks={dashboardData.frameworks} />
          </Grid>
          <Grid item xs={12} md={4}>
            <ControlCoverageCard frameworks={dashboardData.frameworks} />
          </Grid>
        </>
      </Grid>
      <AccountDialog 
        open={accountDialogOpen}
        onClose={() => setAccountDialogOpen(false)}
        userEmail={userInfo?.email}
        />
    </Box>
  );
};

export default HomePage;