import React, { useState } from 'react';
import { Box, Typography, Grid, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useTheme } from '@mui/material/styles';

const CollapsibleGroup = ({ 
  group, 
  controls, 
  instance, 
  getFrameworkMapping, 
  userInfo,
  onAnalysisComplete,
  openShareDialog,
  selectedAI,
  fetchInstances,
  ControlCard 
}) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const theme = useTheme();

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Box key={group} sx={{ mb: 4 }}>
      <Box 
        sx={{ 
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          mb: 2,
          pb: 1,
          borderBottom: `1px solid ${theme.palette.divider}`,
        }}
        onClick={handleToggle}
      >
        <Typography 
          variant="h6" 
          sx={{ 
            flexGrow: 1,
            color: theme.palette.text.secondary
          }}
        >
          {group || ''}
        </Typography>
        <IconButton 
          size="small"
          sx={{ color: theme.palette.text.secondary }}
        >
          {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </Box>
      
      <Box sx={{ 
        height: isExpanded ? 'auto' : 0,
        overflow: 'hidden',
        transition: 'height 0.3s ease-in-out'
      }}>
        <Grid container spacing={2} sx={{ width: '100%' }}>
          {controls.map((control) => {
            const controlDetails = getFrameworkMapping(instance.type)[control];
            return controlDetails ? (
              <ControlCard
                key={control}
                controls={controls}
                control={control}
                controlDetails={controlDetails}
                instance={instance}
                userInfo={userInfo}
                onAnalysisComplete={onAnalysisComplete}
                openShareDialog={openShareDialog}
                selectedAI={selectedAI}
                fetchInstances={fetchInstances}
              />
            ) : null;
          })}
        </Grid>
      </Box>
    </Box>
  );
};

export default CollapsibleGroup;