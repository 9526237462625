const HeinekenFrameworkMapping = {
  "RTR-C-11": {
    "title": "Re-open Closed Period Approval",
    "description": "It may be necessary to re-open a closed period to perform corrections. When this happens, there is a risk that errors may remain undetected. To prevent this, it is important to have all activities performed in a re-opened period reviewed and approved by an authorised approver. Check if any postings were made after closing by selecting the date range of the closing date up to the current date.",
    "testSteps": "Identify any re-opening of the previous month by doing the following:\n    1. The Posting Date is from the first day of the month to the last day of the month.\n    2. Creation Date is from working day +5 up to the day you perform the control (current date)\n    3. There should be no data entries in the table",
    "frequency": "Monthly",
    "overallAccept": ["RTR-C-11:1.1"],
    "overallWarningReject": ["RTR-C-11:1.2"]
  },
  "RTR-C-11:1.1": {
    "title": "Re-open Closed Period Approval of older periods",
    "description": "Check if any postings were made to earlier periods. This can be done by selecting the time frame prior to the previous period (indefinitely into the past), and then by looking for any postings made since the last check was performed. This is why the date range has to begin with the last date used in the previous version of the control",
    "testSteps": "Identify any re-opening of the previous month by doing the following:\n    1. The Posting Date is from the last day of the month.\n    2. There should be no data entries in the table",
    "frequency": "Monthly",
    "overallWarningReject": ["RTR-C-11:1.2"]
  },
  "RTR-C-11:1.2": {
    "title": "Check for substantiating evidence AND approval by an authorized approver",
    "description": "Check if the postings have substantiating evidence and check if they are approved by an authorized approver. If so, it is ok. If not, corrective action has to be taken.",
    "testSteps": "Analyse the posting made and validate they are substantiated by proper evidence AND validate that these postings are approved by an authorized approver (according to the authorization matrix).\n    If substantiated and approved, it’s ok. Provide the substantiating document and the approval as evidence.\n    Check the posting dates on which these postings were made, to make sure they align with the agreed procedures.\n    If the dates align with the procedure, it’s ok.\n    If the dates do not align with the procedure, correct the posting and take action to prevent this from happening again.",
    "frequency": "Monthly"
    }
  };
  
  export default HeinekenFrameworkMapping;