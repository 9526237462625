import React, { useState, useEffect } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
  CircularProgress,
  Alert,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  Snackbar,
  IconButton,
  Tooltip,
  Chip,
  Collapse,
  Card,
  Autocomplete,
  InputLabel,
  FormControl,
  Select,
  Grid,
  Switch,
  FormControlLabel,
  Menu,
  Checkbox,
  useTheme
} from '@mui/material';
import { styled } from '@mui/material/styles';

import FilterListIcon from '@mui/icons-material/Visibility';
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import FrameworkService from '../services/framework_service';

import ISOFrameworkMapping from '../assistant-forms/ISO_4_10_framework_mapping';
import ISOAnnexAFrameworkMapping from '../assistant-forms/ISO_annex_a_framework_mapping';
import CustomFrameworkMapping from '../assistant-forms/custom_audit_checklist';
import NISTFrameworkMapping from '../assistant-forms/nist_framework_mapping';
import HeinekenFrameworkMapping from '../assistant-forms/Heineken_framework_mapping';

import ShareIcon from '@mui/icons-material/Share';
import { FilterList } from '@mui/icons-material';
import { saveAs } from 'file-saver';
import ControlCard from './ControlCard';

import MenuButtons from '../users/MenuButtons';
import CollapsibleGroup from './CollapsibleGroup';

const FRAMEWORK_TYPES = ['NIST', 'ISO 27001 Annex A', 'ISO 27001 Clause 4 to 10', 'Custom', 'Heineken'];

const STATUS_OPTIONS = ['NOT_STARTED', 'IN_PROGRESS', 'COMPLETE', 'BLOCKED'];

const AI_TYPES = {
  'claude': 'Claude 3.5 Sonet',
  'openai': 'OpenAI 4o'
};

const getControlFrequencyColor = (frequency) => {
  switch (frequency?.toLowerCase()) {
    case 'continuous':
      return 'success';
    case 'monthly':
      return 'primary';
    case 'quarterly':
      return 'warning';
    case 'annually':
      return 'error';
    default:
      return 'default';
  }
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[900] : theme.palette.grey[100],
  color: theme.palette.text.primary,
  fontWeight: 'bold',
}));

const FrameworksPage = () => {
  const theme = useTheme();
  const [selectedAI, setSelectedAI] = useState('openai');
  const [analysisResults, setAnalysisResults] = useState({});
  const [instances, setInstances] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [error, setError] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [columnVisibility, setColumnVisibility] = useState({
    title: true,
    type: true,
    controls: true,
    testingStatus: true,
    reviewStatus: true,
    tags: true,
    created_by: true,
    created_at: true,
    actions: true,
  });
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [formData, setFormData] = useState({
    type: 'NIST',
    controls: ''
  });
  const [expandedRow, setExpandedRow] = useState(null);
  const [selectedControl, setSelectedControl] = useState(null);
  const [selectedShareControl, setSelectedShareControl] = useState(null);
  
  const [availableControls, setAvailableControls] = useState([]);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [shareControlDialogOpen , setShareControlDialogOpen] = useState(false);
  const [selectedFramework, setSelectedFramework] = useState(null);
  const [shareEmail, setShareEmail] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [isSharing, setIsSharing] = useState(false);
  const [selectedFrameworkType, setSelectedFrameworkType] = useState('');
  const [filters, setFilters] = useState({
    title: '',
    type: '',
    controls: '',
    testingStatus: '',
    reviewStatus: '',
    tags: '',
    created_by: '',
    created_at: '',
  });

  const groupControls = (instance) => {
    // Create a map of groups to controls
    const groupMap = {};
    
    instance.controls.forEach(control => {
      const group = instance.controlGroups && instance.controlGroups[control] || '';
      
      if (!groupMap[group]) {
        groupMap[group] = [];
      }
      groupMap[group].push(control);
    });
    
    return groupMap;
  };

  const filteredInstances = instances.filter((instance) => {
    const matchesSearch =
      instance.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      instance.tags.some((tag) => tag.toLowerCase().includes(searchQuery.toLowerCase())) ||
      instance.created_by.toLowerCase().includes(searchQuery.toLowerCase()) ||
      instance.testingStatus.toLowerCase().includes(searchQuery.toLowerCase()) ||
      instance.reviewStatus.toLowerCase().includes(searchQuery.toLowerCase()) ||
      instance.created_at.toLowerCase().includes(searchQuery.toLowerCase()) ||
      instance.type.toLowerCase().includes(searchQuery.toLowerCase()) ||
      instance.controls.some((control) => control.toLowerCase().includes(searchQuery.toLowerCase()));

    const matchesType = !selectedFrameworkType || instance.type === selectedFrameworkType;

    return matchesSearch && matchesType;
  });

  const getStatusColor = (status) => {
    switch (status?.toUpperCase()) {
      case 'COMPLETE':
        return 'success';
      case 'IN_PROGRESS':
        return 'primary';
      case 'BLOCKED':
        return 'error';
      case 'NOT_STARTED':
      default:
        return 'default';
    }
  };

  const toggleColumnVisibility = (column) => {
    setColumnVisibility((prev) => ({
      ...prev,
      [column]: !prev[column],
    }));
  };

  const columns = [
    { id: 'title', label: 'Title' },
    { id: 'type', label: 'Type' },
    { id: 'controls', label: 'Controls' },
    { id: 'testingStatus', label: 'Testing Status' },
    { id: 'reviewStatus', label: 'Review Status' },
    { id: 'tags', label: 'Tags' },
    { id: 'created_by', label: 'Owner' },
    { id: 'created_at', label: 'Created At' },
    { id: 'actions', label: 'Actions' },
  ];

  const operators = ['Contains', 'Equals', 'Starts With', 'Ends With'];

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const applyFilter = () => {
    setFilterAnchorEl(null);
    const { column, operator, value } = filters;

    if (column && operator && value) {
      const filteredData = instances.filter((instance) => {
        const cellValue = instance[column]?.toString().toLowerCase();
        const filterValue = value.toLowerCase();

        switch (operator) {
          case 'Contains':
            return cellValue.includes(filterValue);
          case 'Equals':
            return cellValue === filterValue;
          case 'Starts With':
            return cellValue.startsWith(filterValue);
          case 'Ends With':
            return cellValue.endsWith(filterValue);
          default:
            return true;
        }
      });

      setInstances(filteredData);
    }
  };

  const filterMenuOpen = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const handleFilterMenuClose = () => {
    setFilterAnchorEl(null);
  };

  const downloadCSV = () => {
    const csvHeaders = columns
      .filter(column => columnVisibility[column.id])
      .map(column => column.label)
      .join(',');

    const csvRows = filteredInstances.map(instance => {
      return columns
        .filter(column => columnVisibility[column.id])
        .map(column => {
          if (column.id === 'controls') {
            return instance.controls.join(';'); // Join control names with a semicolon
          }
          else if (column.id === 'tags') {
            return instance.tags.join(';');
          }
          return instance[column.id] || ''; // Use instance data or empty string for missing data
        })
        .join(',');
    });

    const csvContent = [csvHeaders, ...csvRows].join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'framework_data.csv');
  };

  const getUserInfo = () => {
    try {
      const token = localStorage.getItem('auth_token');
      if (!token) return null;
      const [, payloadBase64] = token.split('.');
      return JSON.parse(atob(payloadBase64));
    } catch (error) {
      return null;
    }
  };
  const userInfo = getUserInfo();

  const handleShare = async () => {
    try {
      setIsSharing(true);
      await FrameworkService.shareFramework(selectedFramework.uuid, shareEmail);
      setSnackbar({
        open: true,
        message: 'Framework shared successfully',
        severity: 'success'
      });
      setShareDialogOpen(false);
      setShareEmail('');
      setSelectedFramework(null);
    } catch (err) {
      setSnackbar({
        open: true,
        message: err.message || 'Failed to share framework',
        severity: 'error'
      });
    } finally {
      setIsSharing(false);
    }
  };

  
  const handleControlShare = async () => {
    try {
      setIsSharing(true);
      await FrameworkService.shareControl(selectedFramework.uuid, selectedShareControl, shareEmail);
      setSnackbar({
        open: true,
        message: 'Control shared successfully',
        severity: 'success'
      });
      setShareControlDialogOpen(false);
      setShareEmail('');
    } catch (err) {
      setSnackbar({
        open: true,
        message: err.message || 'Failed to share framework',
        severity: 'error'
      });
    } finally {
      setIsSharing(false);
    }
  };

  const openShareDialog = (framework) => {
    setSelectedFramework(framework);
    setShareDialogOpen(true);
  };

  const openShareControlDialog = (framework, control) => {
    setSelectedFramework(framework);
    setSelectedShareControl(control);
    setShareControlDialogOpen(true);
  };

  const handleAnalysisComplete = (control, result) => {
    setAnalysisResults(prev => ({
      ...prev,
      [control]: result
    }));
  };

  const OnAnalysisComplete = (control, result) => {
    handleAnalysisComplete(control, result);
    fetchInstances();
  };

  const getFrameworkMapping = (type) => {
    switch (type) {
      case 'ISO 27001 Annex A':
        return ISOAnnexAFrameworkMapping;
      case 'ISO 27001 Clause 4 to 10':
        return ISOFrameworkMapping;
      case 'Custom':
        return CustomFrameworkMapping;
      case 'NIST':
        return NISTFrameworkMapping;
      case 'Heineken':
        return HeinekenFrameworkMapping;
      default:
        return {};
    }
  };

  const handleFilterChange = (column, value) => {
    setFilters((prev) => ({
      ...prev,
      [column]: value,
    }));
  };

  useEffect(() => {
    const mapping = getFrameworkMapping(formData.type);
    const controls = Object.keys(mapping).map(key => ({
      id: key,
      ...mapping[key]
    }));
    setAvailableControls(controls);
  }, [formData.type]);

  const fetchInstances = async () => {
    try {
      setLoading(true);
      const response = await FrameworkService.getFrameworkInstances();
      setInstances(response['instances']);
      for (let instance of response['instances']) {
        for (let result of instance.results) {
          handleAnalysisComplete(result.control, result.ai_result);
        }
      }
      setError(null);
    } catch (err) {
      setError(err.message || 'Failed to fetch framework instances');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchInstances();
  }, []);

  const validateControls = (controls) => {
    const mapping = getFrameworkMapping(formData.type);
    const invalidControls = controls.filter(control => !mapping[control]);
    if (invalidControls.length > 0) {
      throw new Error(`Invalid controls for ${formData.type} framework: ${invalidControls.join(', ')}`);
    }
    return true;
  };

  const handleCreateTemplate = async () => {
    try {
      setIsCreating(true);
      const controls = formData.controls.split(',').map(control => control.trim());
      validateControls(controls);
      const response = await FrameworkService.createFrameworkTemplate(formData.type, controls);
      const uuid = response.uuid;
      await FrameworkService.createFrameworkInstance(uuid, formData.title, formData.tags, formData.testingStatus, formData.reviewStatus, formData.startDate, formData.dueDate);
      setSnackbar({
        open: true,
        message: 'Framework created successfully',
        severity: 'success'
      });
      setOpenDialog(false);
      fetchInstances();
    } catch (err) {
      setSnackbar({
        open: true,
        message: err.message || 'Failed to create framework',
        severity: 'error'
      });
    } finally {
      setIsCreating(false);
    }
  };

  const handleControlClick = (control, type) => {
    const mapping = getFrameworkMapping(type);
    setSelectedControl(mapping[control]);
  };

  const formatDate = (gmtString) => {
    const gmtDate = new Date(gmtString);
    const offsetMinutes = new Date().getTimezoneOffset();
    const localDate = new Date(gmtDate.getTime() - (offsetMinutes * 60000));
    return localDate.toLocaleString();
  };

  if (loading && instances.length === 0) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{
      width: '100%',
      p: 3,
      maxWidth: 1800,
      margin: '0 auto',
      backgroundColor: theme.palette.mode === 'dark' ? 'rgb(30,30,30);' : 'rgb(255,255,255);',
      borderRadius: 2
    }}>
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        mb: 4
      }}>

        <Box sx={{ display: 'flex', gap: 2, alignItems: 'left' }}>
          <Typography variant="h4" component="div" color="text.primary">
            Current Frameworks
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'right' }}>
          <FormControl sx={{ minWidth: 200 }}>
            <InputLabel id="ai-select-label">AI Model</InputLabel>
            <Select
              labelId="ai-select-label"
              id="ai-select"
              value={selectedAI}
              label="AI Model"
              onChange={(e) => setSelectedAI(e.target.value)}
            >
              {Object.entries(AI_TYPES).map(([value, label]) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {!userInfo.public_email && (
            <Button
              startIcon={<AddIcon />}
              onClick={() => setOpenDialog(true)}
              variant="contained"
              color="primary"
            >
              Create New Framework
            </Button>
          )}
        </Box>
      </Box>

      {error && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
      )}

      {/* Search Bar */}
      <Box sx={{ mb: 4, display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 2 }}>
        <Box sx={{ display: 'flex', gap: 2, flex: 1 }}>
          <FormControl sx={{ minWidth: 200 }}>
            <InputLabel id="framework-type-filter-label">Framework Type</InputLabel>
            <Select
              labelId="framework-type-filter-label"
              id="framework-type-filter"
              value={selectedFrameworkType || ''}
              label="Framework Type"
              onChange={(e) => setSelectedFrameworkType(e.target.value)}
            >
              <MenuItem value="">
                <em>All Types</em>
              </MenuItem>
              {FRAMEWORK_TYPES.map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label="Search Frameworks"
            variant="outlined"
            fullWidth
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </Box>
        <Box>
          <Button
            startIcon={<FilterListIcon />}
            onClick={handleMenuOpen}
            variant="outlined"
            sx={{ ml: 2 }}
          >
            Columns
          </Button>
          <Button
            startIcon={<FilterList />}
            onClick={filterMenuOpen}
            variant="outlined"
            sx={{ ml: 2 }}
          >
            Filters
          </Button>
          {/* <Button
            startIcon={<Download />} // Use an appropriate icon
            onClick={downloadCSV}
            variant="outlined"
            sx={{ ml: 2 }}
          >
            Export
          </Button>
          <Button
            startIcon={<RefreshIcon />}
            onClick={fetchInstances}
            variant="outlined"
            sx={{ ml: 2 }}
          >
            Refresh
          </Button> */}
        </Box>

        <Box sx={{ display: 'flex', gap: 2 }}>
          <MenuButtons
            handleMenuOpen={handleMenuOpen}
            filterMenuOpen={filterMenuOpen}
            downloadCSV={downloadCSV}
            fetchUsers={fetchInstances}
          />
        </Box>
      </Box>

      <Menu anchorEl={filterAnchorEl} open={Boolean(filterAnchorEl)} onClose={handleFilterMenuClose}>
        <Box sx={{ p: 2, width: 300 }}>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>Column</InputLabel>
            <Select
              value={filters.column}
              onChange={(e) => handleFilterChange('column', e.target.value)}
              label="Column"
            >
              {columns.map((col) => (
                <MenuItem key={col.id} value={col.id}>
                  {col.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>Operator</InputLabel>
            <Select
              value={filters.operator}
              onChange={(e) => handleFilterChange('operator', e.target.value)}
              label="Operator"
            >
              {operators.map((op) => (
                <MenuItem key={op} value={op}>
                  {op}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label="Value"
            value={filters.value}
            onChange={(e) => handleFilterChange('value', e.target.value)}
            fullWidth
            sx={{ mb: 2 }}
          />
          <Button variant="contained" onClick={applyFilter} fullWidth>
            Apply Filter
          </Button>
        </Box>
      </Menu>

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
        {columns.map((column) => (
          <MenuItem key={column.id}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={columnVisibility[column.id]}
                  onChange={() => toggleColumnVisibility(column.id)}
                />
              }
              label={column.label}
            />
          </MenuItem>
        ))}
      </Menu>

      <TableContainer
        component={Paper}
        sx={{
          backgroundColor: theme.palette.background.paper,
          boxShadow: theme.palette.mode === 'dark' ? 'none' : undefined,
          border: theme.palette.mode === 'dark' ? `1px solid ${theme.palette.divider}` : undefined,
        }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="framework instances table">
          <TableHead>
            <TableRow>
              {columns.map(
                (column) =>
                  columnVisibility[column.id] && (
                    <StyledTableCell key={column.id}>{column.label}</StyledTableCell>
                  )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {(filteredInstances).map((instance) => (
              <React.Fragment key={instance.uuid}>
                <TableRow
                  sx={{
                    '&:hover': {
                      backgroundColor: theme.palette.mode === 'dark'
                        ? theme.palette.grey[800]
                        : theme.palette.action.hover
                    },
                    '& > td': {
                      borderBottom: `1px solid ${theme.palette.divider}`
                    }
                  }}
                >
                  {columnVisibility['title'] && (
                    <TableCell>{instance.title}</TableCell>
                  )}
                  {columnVisibility['type'] && (
                    <TableCell>{instance.type}</TableCell>
                  )}
                  {columnVisibility['controls'] && (
                    <TableCell>
                      {instance.controls.map((control) => (
                        <Tooltip
                          key={control}
                          title={
                            <Box>
                              <div>Status: {instance.results.find(result => result.control === control)?.ai_result?.overallAssessment || 'Not Analyzed'}</div>
                              <div>Testing: {instance.automated?.[control] ? 'Automated' : 'Manual'}</div>
                              {instance.startDates?.[control] && (
                                <div>Start: {new Date(instance.startDates[control]).toLocaleDateString()}</div>
                              )}
                              {instance.dueDates?.[control] && (
                                <div>Due: {new Date(instance.dueDates[control]).toLocaleDateString()}</div>
                              )}
                            </Box>
                          }
                          placement="top"
                        >
                          <Chip
                            label={control}
                            size="small"
                            color={
                              instance.results.find(result => result.control === control)?.ai_result?.overallAssessment === 'REJECT' ? 'error' :
                                instance.results.find(result => result.control === control)?.ai_result?.overallAssessment === 'WARNING' ? 'warning' :
                                  instance.results.find(result => result.control === control)?.ai_result?.overallAssessment === 'ACCEPT' ? 'success' : 'default'
                            }
                            onClick={() => handleControlClick(control, instance.type)}
                            sx={{
                              mr: 1,
                              mb: 1,
                              '& .MuiChip-label': {
                                color: theme.palette.mode === 'dark' ? theme.palette.text.primary : undefined
                              }
                            }}
                          />
                        </Tooltip>
                      ))}
                    </TableCell>
                  )}
                  {columnVisibility['testingStatus'] && (
                    <TableCell>
                      <Box sx={{ display: 'flex', gap: 1 }}>
                        <Tooltip title="Testing Status">
                          <Chip
                            size="small"
                            label={instance.testingStatus?.replace('_', ' ') || 'NOT STARTED'}
                            color={getStatusColor(instance.testingStatus)}
                          />
                        </Tooltip>
                      </Box>
                    </TableCell>
                  )}
                  {columnVisibility['reviewStatus'] && (
                    <TableCell>
                      <Box sx={{ display: 'flex', gap: 1 }}>
                        <Tooltip title="Review Status">
                          <Chip
                            size="small"
                            label={instance.reviewStatus?.replace('_', ' ') || 'NOT STARTED'}
                            color={getStatusColor(instance.reviewStatus)}
                          />
                        </Tooltip>
                      </Box>
                    </TableCell>
                  )}
                  {columnVisibility['tags'] && (
                    <TableCell>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        {instance.tags?.length > 0 && (
                          <Box sx={{ display: 'flex', gap: 1 }}>
                            {instance.tags.map((tag) => (
                              <Chip
                                key={tag}
                                label={tag}
                                size="small"
                                variant="outlined"
                                sx={{ maxWidth: 100 }}
                              />
                            ))}
                          </Box>
                        )}
                      </Box>
                    </TableCell>
                  )}
                  {columnVisibility['created_by'] && (
                    <TableCell>{instance.created_by}</TableCell>
                  )}
                  {columnVisibility['created_at'] && (
                    <TableCell>{formatDate(instance.created_at)}</TableCell>
                  )}
                  {columnVisibility['actions'] && (
                    <TableCell>
                      <IconButton
                        size="small"
                        onClick={() => setExpandedRow(expandedRow === instance.uuid ? null : instance.uuid)}
                        sx={{ color: theme.palette.text.primary }}
                      >
                        {expandedRow === instance.uuid ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                      </IconButton>
                      {instance.created_by === userInfo?.email && !userInfo.public_email && (
                        <>
                          <IconButton
                            size="small"
                            onClick={() => openShareDialog(instance)}
                            title="Share Framework"
                            sx={{ color: theme.palette.text.primary }}
                          >
                            <ShareIcon />
                          </IconButton>
                        </>
                      )}
                    </TableCell>
                  )}
                </TableRow>
                <TableRow>
                  <TableCell colSpan={columns.filter(column => columnVisibility[column.id]).length} sx={{ p: 0 }}>
                    <Collapse in={expandedRow === instance.uuid}>
                      <Box sx={{
                        p: 3,
                        backgroundColor: theme.palette.mode === 'dark'
                          ? theme.palette.grey[900]
                          : theme.palette.grey[50]
                      }}>
                        <Typography variant="h6" gutterBottom color="text.primary">
                          Framework Details
                        </Typography>
                        {Object.entries(groupControls(instance)).map(([group, controls]) => (
                          <CollapsibleGroup
                            key={group}
                            group={group}
                            controls={controls}
                            instance={instance}
                            getFrameworkMapping={getFrameworkMapping}
                            userInfo={userInfo}
                            onAnalysisComplete={OnAnalysisComplete}
                            openShareDialog={openShareControlDialog}
                            selectedAI={selectedAI}
                            fetchInstances={fetchInstances}
                            ControlCard={ControlCard}
                          />
                        ))}
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {instances.length === 0 && !error && (
        <Box sx={{
          mt: 4,
          textAlign: 'center',
          p: 4,
          backgroundColor: theme.palette.mode === 'dark'
            ? theme.palette.grey[900]
            : theme.palette.grey[50],
          borderRadius: 1
        }}>
          <Typography color="text.secondary">
            No Frameworks found
          </Typography>
        </Box>
      )}

      <Dialog
        open={shareDialogOpen}
        onClose={() => {
          setShareDialogOpen(false);
          setShareEmail('');
          setSelectedFramework(null);
        }}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            backgroundColor: theme.palette.background.paper,
          }
        }}
      >
        <DialogTitle sx={{ color: theme.palette.text.primary }}>
          Share Framework
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2 }}>
            <TextField
              label="Email Address"
              type="email"
              fullWidth
              value={shareEmail}
              onChange={(e) => setShareEmail(e.target.value)}
              disabled={isSharing}
              helperText="Enter the email address of the person you want to share with"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setShareDialogOpen(false);
              setShareEmail('');
              setSelectedFramework(null);
            }}
            disabled={isSharing}
          >
            Cancel
          </Button>
          <Button
            onClick={handleShare}
            variant="contained"
            color="primary"
            disabled={!shareEmail || isSharing}
          >
            {isSharing ? (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <CircularProgress size={20} color="inherit" />
                Sharing...
              </Box>
            ) : (
              'Share'
            )}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={shareControlDialogOpen}
        onClose={() => {
          setShareControlDialogOpen(false);
          setShareEmail('');
        }}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            backgroundColor: theme.palette.background.paper,
          }
        }}
      >
        <DialogTitle sx={{ color: theme.palette.text.primary }}>
          Share Control: {selectedShareControl}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2 }}>
            <TextField
              label="Email Address"
              type="email"
              fullWidth
              value={shareEmail}
              onChange={(e) => setShareEmail(e.target.value)}
              disabled={isSharing}
              helperText="Enter the email address of the person you want to share with"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setShareControlDialogOpen(false);
              setShareEmail('');
            }}
            disabled={isSharing}
          >
            Cancel
          </Button>
          <Button
            onClick={handleControlShare}
            variant="contained"
            color="primary"
            disabled={!shareEmail || isSharing}
          >
            {isSharing ? (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <CircularProgress size={20} color="inherit" />
                Sharing...
              </Box>
            ) : (
              'Share'
            )}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            backgroundColor: theme.palette.background.paper,
          }
        }}
      >
        <DialogTitle sx={{ color: theme.palette.text.primary }}>
          Create New Framework
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
            <TextField
              label="Framework Title"
              value={formData.title}
              onChange={(e) => setFormData({ ...formData, title: e.target.value })}
              fullWidth
              required
            />

            <TextField
              select
              label="Framework Type"
              value={formData.type}
              onChange={(e) => setFormData({ ...formData, type: e.target.value })}
              fullWidth
            >
              {FRAMEWORK_TYPES.map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </TextField>

            <Autocomplete
              multiple
              freeSolo
              options={availableControls}
              getOptionLabel={(option) => typeof option === 'string' ? option : option.id}
              renderOption={(props, option) => (
                <Box component="li" {...props}>
                  <Box>
                    <Typography variant="subtitle2">
                      {option.id} - {option.title}
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                      Frequency: {option.frequency}
                    </Typography>
                  </Box>
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Controls"
                  placeholder="Type or select controls"
                  helperText="Select from available controls or enter custom ones"
                />
              )}
              onChange={(_, newValue) => {
                const controls = newValue
                  .map(v => typeof v === 'string' ? v : v.id)
                  .join(', ');
                setFormData({ ...formData, controls });
              }}
            />

            <Autocomplete
              multiple
              freeSolo
              options={[]}
              value={formData.tags || []}
              onChange={(_, newValue) => setFormData({ ...formData, tags: newValue })}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Tags"
                  placeholder="Add framework tags"
                />
              )}
            />

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  select
                  fullWidth
                  label="Testing Status"
                  value={formData.testingStatus || 'NOT_STARTED'}
                  onChange={(e) => setFormData({ ...formData, testingStatus: e.target.value })}
                >
                  {STATUS_OPTIONS.map((status) => (
                    <MenuItem key={status} value={status}>
                      {status.replace('_', ' ')}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  select
                  fullWidth
                  label="Review Status"
                  value={formData.reviewStatus || 'NOT_STARTED'}
                  onChange={(e) => setFormData({ ...formData, reviewStatus: e.target.value })}
                >
                  {STATUS_OPTIONS.map((status) => (
                    <MenuItem key={status} value={status}>
                      {status.replace('_', ' ')}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Start Date"
                  type="date"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    const date = e.target.value ? e.target.value : null;
                    setFormData({ ...formData, startDate: date });
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Due Date"
                  type="date"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    const date = e.target.value ? e.target.value : null;
                    setFormData({ ...formData, dueDate: date });
                  }}
                />
              </Grid>
            </Grid>

            <FormControlLabel
              control={
                <Switch
                  checked={formData.automated || false}
                  onChange={(e) => setFormData({ ...formData, automated: e.target.checked })}
                />
              }
              label="Enable Automated Testing"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenDialog(false)}
            disabled={isCreating}
          >
            Cancel
          </Button>
          <Button
            onClick={handleCreateTemplate}
            variant="contained"
            color="primary"
            disabled={isCreating || !formData.title || !formData.controls}
          >
            {isCreating ? (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <CircularProgress size={20} color="inherit" />
                Creating...
              </Box>
            ) : (
              'Create'
            )}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={!!selectedControl}
        onClose={() => setSelectedControl(null)}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            backgroundColor: theme.palette.background.paper,
          }
        }}
      >
        <DialogTitle sx={{ color: theme.palette.text.primary }}>
          Control Details
        </DialogTitle>
        <DialogContent>
          {selectedControl && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="h6" gutterBottom color="text.primary">
                {selectedControl.title}
              </Typography>
              <Chip
                label={selectedControl.frequency}
                color={getControlFrequencyColor(selectedControl.frequency)}
                sx={{ mb: 2 }}
              />
              <Typography variant="body1" paragraph color="text.primary" sx={{whiteSpace: 'pre-line'}}>
                {selectedControl.description}
              </Typography>
              <Typography variant="h6" gutterBottom color="text.primary">
                Test Steps
              </Typography>
              <Typography variant="body1" color="text.primary" sx={{whiteSpace: 'pre-line'}}>
                {selectedControl.testSteps}
              </Typography>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSelectedControl(null)}>Close</Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{
            width: '100%',
            backgroundColor: theme.palette.mode === 'dark'
              ? theme.palette.grey[900]
              : undefined
          }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default FrameworksPage;